import { apiServiceRequest } from "Utils/axiosInstance";
import { baseURL } from "Utils/baseUrl";
import { getUserToken } from "Utils/tokenProvider";

const paymentPlanService = async (
  setPaymentPlan,
  setPaymentPlanLoading,
  notify,
  id
) => {
  setPaymentPlanLoading(true);
  /* eslint-disable */
  const res = await apiServiceRequest({
    url: `${baseURL}GetBrokerPaymentPlanbyPropertyID?PropertyID=${id}`,
    headers: { Authorization: `Bearer ${await getUserToken(notify)}` },
    notify: notify,
  });

  if (res?.successStatus) {
    res?.response?.length && setPaymentPlan(res?.response);
  }
  setPaymentPlanLoading(false);
};

export default paymentPlanService;
