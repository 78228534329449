import React, { useEffect, useState } from "react";
import { Grid, Typography, Container, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import { useRedirect, useStoreContext, useStore, useTranslate } from "react-admin";
import "react-phone-input-2/lib/style.css";
import Loader from "Components/Loader";
import isRead from "Utils/ui-lock-status";
import AutoCompleteComponent from "Components/Common/AutoComplete";
import PhoneInputComponent from "Components/Common/PhoneInput";
import userDetails from "Services/userDetailsServices";
import country from "Services/countryService";
import { numberTextPattern } from "Utils/regex-patterns";
import registerService from "Services/registerService";
import InputField from "Components/InputField";
import HeaderButton from "Components/HeaderButton";

const ContactDetails = ({ notification }) => {
  const [os, setOs] = useState(null);
  const [formData, setFormData] = useStore();
  const [loading, setLoading] = useState(false);
  const [countryListLoading, setCountryListLoading] = useState();
  const [countryList, setCountryList] = useState([]);
  const [mobilePhoneNumber, setMobilePhoneNumber] = useState();
  const [countryCode, setCountryCode] = useState();
  const [alternateMobilePhoneNumber, setAlternateMobilePhoneNumber] = useState();
  const [alternateMobilePhoneNumberCountryCode, setAlternateMobilePhoneNumberCountryCode] = useState();
  const [phoneErrors, setPhoneErrors] = useState(false);
  const [countryState, setCountryState] = useState(null);
  const [ccode, setccode] = useState(null);

  const redirect = useRedirect();
  const store = useStoreContext();
  const translate = useTranslate();
  const form = useForm({
    mode: "onChange",
  });
  const {
    register,
    handleSubmit,
    formState,
    setError,
    clearErrors,
  } = form;
  const { errors } = formState;
  let user_id = store.getItem("user_id");
  let user_type = store.getItem("user_type");
  const step = 3;

  const setDetails = (resData) => {
    resData?.oracle_status ? setOs(resData?.oracle_status) : setOs("-");
    setMobilePhoneNumber(resData?.mobile_no);
    setCountryCode(resData?.country_code);
  };

  useEffect(() => {
    country(setCountryList, setCountryListLoading, notification);
    userDetails(setLoading, setFormData, setDetails, notification);
  }, []);

  const onSubmit = async (data, event) => {
    if (!user_type) {
      user_type = Number(localStorage.getItem("user_type"));
    }
    if (!user_id) {
      user_id = Number(localStorage.getItem("user_id"));
    }
    const { address, city, postal_code, po_box } = data;

    let apiRequestData = {
      step,
      user_id,
      user_type,
      country_code: countryCode,
      mobile_no: mobilePhoneNumber,
      alt_mobile_no: alternateMobilePhoneNumber,
      alt_mobile_no_country_code: alternateMobilePhoneNumberCountryCode,
      address: address?.trim(),
      city: city?.trim(),
      postal_code: postal_code?.trim(),
      po_box: po_box?.trim(),
      country: ccode,
    };

    registerService(
      isRead,
      os,
      setLoading,
      apiRequestData,
      notification,
      redirect,
      "/register-myself/bank"
    );
  };
  
  const validatePhone = (
    fieldName,
    mobileNumber,
    message = translate("ra.validation.validMobileNo"),
    idToScrollIntoView = "Mobile-No-Typography"
  ) => {
    if (!mobileNumber || mobileNumber?.length < 6) {
      setError(fieldName, { type: "required", message: message });
      idToScrollIntoView &&
        document.getElementById(idToScrollIntoView)?.scrollIntoView();
    } else {
      clearErrors(fieldName);
      setPhoneErrors(false);
    }
  };

  const handlePhoneNumberValidation = (value, data) => {
    const rawnumber = value.slice(data.dialCode.length);
    const dialCode = data.dialCode;
    validatePhone("mobile_no", rawnumber);
    setMobilePhoneNumber(rawnumber);
    setCountryCode(dialCode);
  };

  const handleAltPhoneNumberValidation = (value, data) => {
    const rawnumber = value.slice(data.dialCode.length);
    const dialCode = data.dialCode;
    setAlternateMobilePhoneNumber(rawnumber);
    setAlternateMobilePhoneNumberCountryCode(dialCode);
  };

  useEffect(() => {
    if(alternateMobilePhoneNumber && alternateMobilePhoneNumber?.length < 6) {
      validatePhone(
        "alt_mobile_no",
        alternateMobilePhoneNumber,
        "Please enter correct number.",
        "Alt-No-Typography"
      );
    } else {
      clearErrors("alt_mobile_no")
    }
  }, [alternateMobilePhoneNumber])

  useEffect(() => {
    if (phoneErrors) {
      validatePhone( "mobile_no", mobilePhoneNumber);
    }
  }, [phoneErrors]);

  useEffect(() => {
    if (formData?.country_residence) {
      const country = countryList?.find(
        (country) => country.country_code == formData?.country_residence
      );
      setCountryState(country);
      setccode(formData?.country_residence);
    }
  }, [formData?.country_residence, countryList]);

  return (
    <>
      {(loading || countryListLoading) && <Loader />}
      {formData && (
        <Container className="company-container new-fix-container">
          <Container className="company-details">
           <HeaderButton notification={notification} os={os} />
            <Grid className="form-panel" sx={{ width: "100%" }}>              
              <Typography
                sx={{
                  textAlign: "center",
                  fontStyle: "normal",
                  fontWeight: 600,
                  marginBottom: "20px",
                }}
                variant="h3"
              >
                {translate("ra.pageTitle.addresssDetails")}
              </Typography>

              {/* Mobile Number */}
              <Grid container spacing={0}>
                <PhoneInputComponent
                  id="Mobile-No-Typography"
                  title={translate("ra.fieldName.mobileNo")}
                  className="mobile_no"
                  value={[formData?.country_code, formData?.mobile_no].join()}
                  onChange={handlePhoneNumberValidation}
                  errors={errors?.mobile_no?.message}
                  os={os}
                />
              </Grid>

              {/* Alternate Mobiel Number */}
              <Grid container spacing={0}>
                <PhoneInputComponent
                  id="Alt-No-Typography"
                  title={translate("ra.fieldName.alternateNo")}
                  hide={true}
                  value={[
                    formData?.alt_mobile_no_country_code,
                    formData?.alt_mobile_no,
                  ].join()}
                  onChange={handleAltPhoneNumberValidation}
                  errors={errors?.alt_mobile_no?.message}
                  os={os}
                />
              </Grid>

              {/* Address */}
              <Grid
                className="full-section checkbox-panel"
                container
                spacing={0}
              >
                <InputField 
                  name={translate("ra.fieldName.address")} 
                  register={register} 
                  errors={errors} 
                  defaultValue={formData?.address || ""}
                  id="address"
                  className="mb15"
                  readOnly={isRead(os)}
                  state="address"
                  placeholder={translate("ra.placeholder.address")}
                  sx={{ width: "100%", marginBottom: "20px" }}
                />
                <InputField 
                  register={register} 
                  errors={errors} 
                  defaultValue={formData?.city || ""}
                  id="city"
                  className="mt0"
                  readOnly={isRead(os)}
                  state="city"
                  placeholder={translate("ra.placeholder.city")}
                  sx={{ width: "100%", marginBottom: "20px" }}
                />
              </Grid>

              {/* Postal Code */}
              <Grid container spacing={0}>
                <InputField 
                  name={translate("ra.fieldName.postalCode")}
                  register={register} 
                  errors={errors} 
                  defaultValue={formData?.postal_code || ""}
                  id="postal_code"
                  readOnly={isRead(os)}
                  state="postal_code"
                  placeholder={translate("ra.placeholder.postalCode")}
                  message={translate("ra.validation.validPostalCode")}
                  pattern={{
                    value: numberTextPattern,
                    message: translate("ra.validation.validInput")
                  }}
                />
              </Grid>

              {/* PO box */}
              <Grid container spacing={0}>
                <InputField 
                  name={translate("ra.fieldName.poBox")}
                  register={register} 
                  errors={errors} 
                  defaultValue={formData?.po_box || ""}
                  id="po_box"
                  readOnly={isRead(os)}
                  state="po_box"
                  placeholder={translate("ra.placeholder.poBox")}
                  message={translate("ra.validation.validPoBox")}
                  pattern={{
                    value: numberTextPattern,
                    message: translate("ra.validation.validInput")
                  }}
                />
              </Grid>

              {/* Country */}
              <Grid container className="new-select-class" spacing={0}>
                <AutoCompleteComponent
                  title={translate("ra.fieldName.country")}
                  value={countryState}
                  onChange={(e, newInputValue) => {
                    setCountryState(newInputValue);
                    setccode(newInputValue?.country_code);
                    clearErrors("country");
                  }}
                  readOnly={true}
                  options={countryList}
                  getOptionLabel={(option) => option?.country_name || ""}
                  name="country"
                  placeholder={translate("ra.placeholder.country")}
                  errors={errors?.country}
                  register={register}
                  os={os}
                />
              </Grid>

              {/* Buttons */}
              <Grid container className="company-details-button" spacing={0}>                
                <Button
                  variant="contained"
                  onClick={() => {
                    handleSubmit(onSubmit)();
                    setPhoneErrors(true);
                  }}
                  sx={{
                    width: "100%",
                    boxShadow: "none",
                    height: "48px",
                    marginBottom: "50px",
                  }}
                >
                  {translate("ra.button.next")}
                </Button>
              </Grid>
            </Grid>
          </Container>
        </Container>
      )}
    </>
  );
};

export default ContactDetails;
