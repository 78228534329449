import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Checkbox,
  IconButton
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import "./Popup.scss";
import { addRemoveFromArray } from "Utils/formArrayValues";
import ReactWebShare from "./ReactWebShare";
import { formatNumbers } from "Utils/thousandSeperators";
import { documentNames } from "Utils/constantValues";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslate } from "react-admin";
import gtagEventFire from "Utils/ga4EventTrigger";

export default function Popup({
  openPopup,
  setOpenPopup,
  documentArray,
  type,
  startingPrice,
  startingArea,
  projectName,
  currency,
  unit_type,
  location,
  city,
  images,
  unitNo,
  email
}) {
  const [docArray, setDocArray] = useState([]);
  const [urlvalue, seturlvalue] = useState(null);
  const [showShare, setShowShare] = useState(false);
  const [hidePopup, setHidePop] = useState(false);
  const translate = useTranslate();

  const handleClose = () => {
    setOpenPopup(false);
    setHidePop(false);
  };

// PDP page - multi doc share
const renderDocShare = () => {
// Left aligned below text based on UI shared in social network
return `
${translate("ra.content.shareContent")} ${translate("ra.content.documents")} ${translate("ra.content.on")} ${projectName}: 

${translate("ra.content.projectName")} ${projectName}
${translate("ra.content.location")} ${city}, ${location}
${translate("ra.content.startSize")} ${formatNumbers(startingArea)} ${unit_type}
${translate("ra.content.startPrice")} ${formatNumbers(startingPrice)} ${currency}
${docArray?.map((doc) => {
return `
${doc?.documentName}
${doc?.documentLink}
`
})?.join("")}
${translate("ra.content.query")}
`
}

// UDP and ULP page - multi doc with multi unit share with multi project
const renderBulkShare = () => {
// Left aligned below text based on UI shared in social network
return `
${translate("ra.content.shareContent")} ${translate("ra.content.documents")}
${documentArray?.map((list) => {
return `
${translate("ra.content.projectName")} ${list?.title}
${translate("ra.content.location")} ${list?.city}, ${list?.location}
${list?.unitDoc.map(data => {
return `
${translate("ra.content.unitNo")} ${data?.unitNo}
${translate("ra.content.size")} ${formatNumbers(data?.size)} ${data?.unit}
${translate("ra.content.price")} ${formatNumbers(data?.price)} ${data?.currency}
${data?.docArray.filter(value => docArray?.includes(value?.documentName))?.map((doc) => {
return `
${doc?.documentName}
${doc?.documentLink}
`
})?.join("")}`
})?.join("")}`
})?.join("")}
${translate("ra.content.query")}
`
}

const gatag = () => {
  if(unitNo && projectName) {
     gtagEventFire("unit_bulk_share", {
       unit_id: unitNo,
       documents: docArray.join(","),
       property_name: projectName,
       user_email: email
     })
   }
  else if(!projectName && documentArray?.length){
    gtagEventFire("multiple_unit_bulk_share", {
       unit_id: documentArray?.map(doc=>doc?.unitDoc?.map(unit=>unit?.unitNo).join(",")).join(","),
       documents: docArray?.join(","),
       property_name: documentArray?.map(doc => doc?.title).join(","),
       user_email: email
    })
  }
  else if(projectName) {
     gtagEventFire("property_bulk_share", {
       user_email: email,
       property_name: projectName,
       documents: docArray?.map((doc)=>doc?.documentName).join(",")
     })
   }
}

  const handleNext = () => {
    let url = "";
    if (type === "multiple") {
      url = renderBulkShare();
    } else {
      url = renderDocShare();
    } 

    gatag();
    seturlvalue(url);
    setTimeout(() => {
      setShowShare(true);
    }, 1000);
  };

  // getting the names of all the checked documents
  const checked = (doc) => {
    const docData = [...docArray]
    addRemoveFromArray(doc, docData, setDocArray)
  };

  const backBtnTrigger = (e) => {
    e.stopPropagation();
    setShowShare(false);
    setHidePop(false);
    seturlvalue(null);
  }

  const shareOnclick = (e) => {
    const targetValue = ["rws-header", "rws-icons", "rws-container web-share-fade-in-up"];
    // ignoring click outside of social share icons
    if(!targetValue?.includes(e?.target?.className)) {
      setHidePop(!hidePopup);
    }
  }

  return (
    <div>
      <Dialog
        className={`popup-share ${hidePopup ? "hide-button" : ""}`}
        open={openPopup}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className="share-heading" id="alert-dialog-title">
          <span>{translate("ra.content.shareDoc")}</span>
          <span>
            <IconButton
              aria-label="close"
              onClick={() => {
                handleClose()
              }}
            >
              <CloseOutlinedIcon />
            </IconButton>
          </span>
        </DialogTitle>
        <DialogContent>
          {!showShare ?
            (
              <>
                <p>{translate("ra.content.someDoc")} </p>
                <DialogContentText id="alert-dialog-description">
                  <ul className="shareList">
                    {type === "multiple" ?
                      documentNames?.map((list, index) => {
                        return (
                          <li key={index}>
                            <span>{list}</span>
                            <span>
                              <Checkbox
                                id={list}
                                onClick={() => checked(list)}
                                sx={{ "& .MuiSvgIcon-root": { fontSize: 36 } }}
                                checked={docArray?.includes(list)}
                              />
                            </span>
                          </li>
                        );
                      })
                      :
                      documentArray?.map((doc, index) => {
                        return (
                          <li key={index}>
                            <span>{doc?.documentName}</span>
                            <span>
                              <Checkbox
                                id={doc}
                                onClick={() => checked(doc)}
                                sx={{ "& .MuiSvgIcon-root": { fontSize: 36 } }}
                                checked={docArray?.includes(doc)}
                              />
                            </span>
                          </li>
                        );
                      })
                    }
                  </ul>
                </DialogContentText>
              </>
            ) : (
              <>
                <DialogContentText id="alert-dialog-description">
                  <ul className="shareList">
                    {type === "multiple" ?
                      <>
                        <li className="li_margin_bottom"><div className="">
                          {documentArray?.map((list, idx) =>
                            <>
                              <li key={idx} className="info-with-image">
                                <img src={list?.images} alt="Share Pic" />
                                <p><span>{list?.title}</span> {list?.city}, {list?.location}</p>
                              </li>
                              {list?.unitDoc?.map((data, i) =>
                                <li key={i} className="accordian-section">
                                  <Accordion
                                    defaultExpanded= {
                                      documentArray?.length === 1 && 
                                      list?.unitDoc?.length === 1 ? true : false
                                    }
                                  >
                                    <AccordionSummary
                                      expandIcon={<ExpandMoreIcon />}
                                      aria-controls="panel1a-content"
                                      id="panel1a-header"
                                    >
                                      <h3>{data?.unitNo}</h3>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      <div className="price-size-panel">
                                        <p>
                                          {translate("ra.content.price")}
                                          <span><strong>{formatNumbers(data?.price)} </strong> {data?.currency}</span>
                                        </p>
                                        <p>
                                          {translate("ra.content.size")}
                                          <span><strong>{formatNumbers(data?.size)} </strong>{data?.unit}</span>
                                        </p>
                                      </div>
                                      {data?.docArray.filter(value => docArray?.includes(value?.documentName))?.map((doc, index) =>
                                        <div className="top-heading" key={index}>
                                          <span>{doc?.documentName}</span>
                                          <a target="_blank" rel="noreferrer" href={doc?.documentLink}>{doc?.documentLink}</a>
                                        </div>
                                      )}
                                    </AccordionDetails>
                                  </Accordion>
                                </li>
                              )}
                            </>
                          )}
                        </div>
                        </li>
                      </>
                      :
                      <>
                        <li className="li_margin_bottom  pdp-share">
                          <div className="">
                            <li className="info-with-image">
                              <img src={images} alt="Share Pic" />
                              <p><span>{projectName}</span> {city}, {location} </p>
                            </li>
                            <li>{translate("ra.content.startSize")} <strong>{formatNumbers(startingArea)} </strong>{unit_type}</li>
                            <li>{translate("ra.content.startPrice")} <strong>{formatNumbers(startingPrice)} </strong>{currency}</li>
                          </div>
                        </li>
                        <li className="accordian-section pdp-accordian-section">
                          {docArray?.map((doc, index) => {
                            return (
                              <div className="top-heading" key={index}>
                                <span>{doc?.documentName}</span>
                                <a target="_blank" rel="noreferrer" href={doc?.documentLink}>{doc?.documentLink}</a>
                              </div>
                            );
                          })}
                        </li>
                      </>
                    }
                  </ul>
                </DialogContentText>
              </>
            )
          }
        </DialogContent>
        <DialogActions className="padding-0">
          {!showShare ? (
            <Button 
              className={`share-button ${urlvalue ? "button-resize" : ""} 
              ${docArray?.length === 0 ? `disabled` : ""}`} 
              onClick={handleNext} 
              disabled={docArray?.length === 0}
            >
              {!urlvalue 
                ? translate("ra.button.continue")
                : <span className="position-loader">
                    {translate("ra.content.prepareDoc")}
                    <CircularProgress />
                  </span>
              }
            </Button>
          ) : (
            <div  className="web-share-button-section" onClick={(e) => shareOnclick(e)}>
              <ReactWebShare
                handleClose={handleClose}
                title="Share Documents"
                text=""
                url={urlvalue}
              >
                <Button className="web-share-button">
                  <Button className="share-button back-button"
                    onClick={backBtnTrigger}
                  >
                    {translate("ra.button.back")}
                  </Button>
                  <Button className="share-button">
                    {translate("ra.button.share")}
                  </Button>
                </Button>
              </ReactWebShare>
            </div>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
