import { apiServiceRequest } from "Utils/axiosInstance";
import { baseURL } from "Utils/baseUrl";
import { currencyStatus } from "Utils/currencyCheck";
import { dataValueFromQuery } from "Utils/filterData";
import { getQueryFromParams } from "Utils/queryValues";
import { getUserToken } from "Utils/tokenProvider";

export const filterDetails = async (
  setLoading,
  setData,
  notify,
  params,
  setFilterList,
  setCurrencyList
) => {
  setLoading(true);
  const res = await apiServiceRequest({
    url: `${baseURL}GetLookups`,
    headers: { Authorization: `Bearer ${await getUserToken(notify)}` },
    notify: notify,
  });

  if (res?.successStatus) {
    setData && setData(res?.response);
    const currencyList = res?.response?.currencies?.map((currency) => {
      return { code: currency?.id, name: currency?.name };
    });
    setCurrencyList && setCurrencyList(currencyList);
  }
  // setting values in filter from params
  if (res?.response) {
    dataValueFromQuery(params, res?.response, setFilterList);
  }
  setLoading(false);
};

export const projectListDetails = async (
  setLoading,
  setData,
  userDetail,
  location,
  notify,
  setCount
) => {
  setLoading(true);
  let query = {};

  const currency = userDetail?.currency?.toUpperCase() || "";
  const unit_type = userDetail?.unit_type?.toUpperCase() || "";

  if (location) {
    query = getQueryFromParams(location, currency, unit_type);
  }

  /* eslint-disable */
  const res = await apiServiceRequest({
    url: `${baseURL}GetBrokerProjects?jsonParam=${btoa(
      JSON.stringify({
        Limit: 12,
        Page: parseInt(query?.page) || 1,
        Conditions: query?.conditions || [],
        Sorts: query?.sort || [],
      })
    )}`,
    headers: { Authorization: `Bearer ${await getUserToken(notify)}` },
    notify: notify,
  });

  if (res?.successStatus) {
    const resData = res?.response?.objList?.map((item, idx) => {
      return {
        project_id: item.propertY_ID,
        location: item.location,
        project_name: item.projecT_NAME,
        start_price: currencyStatus(currency)
          ? item[`starT_PRICE_${currency}`]
          : item[`starT_PRICE_${item?.currency}`],
        images: item.images,
        currency: item.currency,
      };
    });
    setData(resData || []);
    if(setCount) setCount(res?.response?.totalNoOfRecords || 0);
  }
  setLoading(false);
};

export const projectDetails = async (
  setLoading,
  setData,
  notify,
  id
) => {
  setLoading(true);
  /* eslint-disable */
  const res = await apiServiceRequest({
    url: `${baseURL}GetBrokerProjects?jsonParam=${btoa(
      JSON.stringify({
        Conditions: [
          {
            Field: "propertY_ID",
            Value: `${id}`,
            Filter: "equal",
          },
        ],
      })
    )}`,
    headers: { Authorization: `Bearer ${await getUserToken(notify)}` },
    notify: notify,
  });

  if (res?.successStatus) {
    const resData = res?.response?.objList;
    if (resData?.length) {
      setData(resData[0]);
    }
  }
  setLoading(false);
};

export const projectListCount = async (
  setLoading,
  setData,
  userDetail,
  location,
  notify
) => {
  setLoading(true);
  let query = {};

  const currency = userDetail?.currency?.toUpperCase() || "";
  const unit_type = userDetail?.unit_type?.toUpperCase() || "";

  if (location) {
    query = getQueryFromParams(location, currency, unit_type);
  }

  /* eslint-disable */
  const res = await apiServiceRequest({
    url: `${baseURL}GetBrokerProjectsCount?jsonParam=${btoa(
      JSON.stringify({
        Conditions: query?.conditions || []
      })
    )}`,
    headers: { Authorization: `Bearer ${await getUserToken(notify)}` },
    notify: notify
  });

  if (res?.successStatus) {
    const resData = res?.response?.totalNoOfRecords;
    setData(resData || 0);
  }
  setLoading(false);
};
