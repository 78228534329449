export const amenitiesMap = {
    "cutting_edge_gym" : "gym",
    "infinity_pool" : "outdoor-pool",
    "grand_entrance" : "two-entrance",
    "retail" : "retail-outlet",
    "covered_parking" : "indoor-parking",
    "modern_gym" : "gym",
    "rooftop_pool_area" : "indoor-pool",
    "separate_sauna_and_steam_rooms_for_men_and_women" : "sauna",
    "24_7_concierge_service" : "security",
    "fully_serviced_masterplan" : "full-masterplan",
    "a_variety_of_shops_and_services_grocery_stores" : "shop",
    "extensive_dining_option_and_cafes" : "terrace",
    "a_community_mosque" : "mosque-outline",
    "the_sidra_football_academy" : "football",
    "a_five_star_hotel" : "hotel",
    "pre_acquired_permits" : "approval",
    "plots_with_a_connected_grid_network" : "network-grid",
    "seamless_ownership_in_europe" : "owner",
    "clubhouse" : "clubhouse",
    "complimentary_guest_suites" : "guest-room",
    "cutting_edge_fitness_center" : "gym",
    "communications_space_with_a_private_cinema" : "cinema-room",
    "game_room_and_chillout_area" : "game",
    "offices_with_coworking_space_and_meeting_rooms" : "coworking-space",
    "infinity_outdoor_pool" : "outdoor-pool",
    "terrace_with_a_dining_area_and_a_walk_track" : "terrace",
    "platinum_elite_status_by_marriott_bonvoy_for_two_years" : "card",
    "state_of_the_art_fitness_center" : "gym",
    "infinity_swimming_pool" : "outdoor-pool",
    "landscaped_community_courtyard_with_sea_views" : "sea-view",
    "24hrs_reception_desk" : "reception",
    "4_personal_elevators_inclusive_of_a_service_elevator_to_all_floors" : "elevator",
    "lounges_and_lobby_areas_furniture_designed_and_selected_by_elie_saab" : "apartment",
    "luxury_retail" : "retail-outlet",
    "parking_for_all_units" : "indoor-parking",
    "trump_international_golf_club_oman" : "golf",
    "community_retail_centre" : "community",
    "plazas_cafes_restaurants" : "restaurants",
    "wellness_centers" : "wellness-center",
    "fitness_center" : "gym",
    "promenades" : "promenades",
    "hiking_trail" : "hiking",
    "mountain_bike" : "mountain-bike",
    "luxury_hotels" : "hotel",
    "outdoor_landscapes" : "outdoor-landscape",
    "running_trail" : "running",
    "viewing_deck" : "viewing-deck",
    "sky_garden" : "garden",
    "state_of_the_art_gym" : "gym",
    "premium_24_7_security_services" : "security",
    "each_villa_within_tierra_viva_comes_with_its_own_built_private_pool" : "indoor-pool",
    "landscaped_podium" : "landscape-podium",
    "yoga_studio" : "yoga-studio",
    "gated_community" : "gated-community",
    "6_parks_within_walking_distance" : "outdoor-landscape",
    "schools_and_nurseries" : "book-open",
    "fitness_centres" : "gym",
    "wide_variety_of_restaurants_and_cafes" : "restaurants",
    "art_galleries_theater_and_cinema" : "cinema-room",
    "local_markets" : "retail-outlet",
    "golf_clubs" : "golf",
    "shopping" : "shop",
    "10_min_walk_to_west_ealing_crossrail_station" : "train",
    "community_garden" : "garden",
    "children_swimming_pool" : "indoor-pool",
    "children_splash_area" : "kids-splash-area",
    "tennis_court" : "tennis"
}

export const replaceSpecialCharacters = (char) => {
    // Replace all special characters with "_"
    // Remove start and end "_"
    // Replace more than one "_" with single "_"

    let string = char?.replace(/[^a-zA-Z0-9]/g,'_')?.replace(/^_+|_+$/g, '')?.replace(/_{2,}/g,'_')?.toLowerCase();
    return string;
}