import { currencyStatus } from "./currencyCheck";
import { getDateFormat } from "./dateFormat";

export const getQueryFromParams = (location, currency, unit_type, page, officE_SECTION_ID) => {
    let url = [];
    let sort = [];
    let pageno = 1;

    if(location?.country) {
        url.push({"Field":"location","Value": location?.country,"Filter": `${location?.country?.split(",")?.length > 1 ? "in" : "equal"}`})
    }
    if(location?.countrysearch) {
        url.push({"Field":"location","Value": location?.countrysearch,"Filter":"equal"})
    }
    if(location?.project) {
        url.push({"Field":"project_Name","Value": location?.project,"Filter":`${location?.project?.split(",")?.length > 1 ? "in" : "equal"}`})
    }
    if(location?.projectid) {
        url.push({"Field":"projecT_ID","Value": location?.projectid,"Filter":`${location?.projectid?.split(",")?.length > 1 ? "in" : "equal"}`})
    }
    if(location?.type) {
        url.push({"Field":"uniT_TYPE","Value": location?.type,"Filter":`${location?.type?.split(",")?.length > 1 ? "in" : "equal"}`})
    }
    if(location?.bed) {
        url.push({"Field":"apartmenT_TYPE","Value": location?.bed,"Filter":`${location?.bed?.split(",")?.length > 1 ? "in" : "equal"}`})
    }
    if(page) {
        const priceData = currencyStatus(currency) ? `propertY_PRICE_${currency}` : `propertY_PRICE_USD`;

        if(location?.status) {
            url.push({"Field":"uniT_STATUS","Value": location?.status,"Filter":`${location?.status?.split(",")?.length > 1 ? "in" : "equal"}`})
        }
        if(location?.min_price >= 0 && location?.max_price) {
            url.push({"Field": priceData,"Value": [location?.min_price, location?.max_price]?.join(","),"Filter":"between"})
        }
        if(location?.min_size >= 0 && location?.max_size) {
            url.push({"Field":`saleablE_AREA_${unit_type}`,"Value": [location?.min_size, location?.max_size]?.join(","),"Filter":"between"})
        }
        if(location?.sort === "price-asc") {
            sort = [{"Field": priceData,"Order":"ASC"}]
        }
        if(location?.sort === "price-desc") {
            sort = [{"Field": priceData,"Order":"DESC"}]
        }
        if(location?.sort === "size-asc") {
            sort = [{"Field":`saleablE_AREA_${unit_type}`,"Order":"ASC"}]
        }
        if(location?.sort === "size-desc") {
            sort = [{"Field":`saleablE_AREA_${unit_type}`,"Order":"DESC"}]
        }
    } else {
        const minPrice = currencyStatus(currency) ? `starT_PRICE_${currency}` : `starT_PRICE_USD`;

        if(location?.status) {
            url.push({"Field":"propertY_STATUS","Value": location?.status,"Filter":`${location?.status?.split(",")?.length > 1 ? "in" : "equal"}`})
        }
        if(location?.min_price >= 0 && location?.max_price) {
            url.push({"Field": minPrice,"Value": [location?.min_price, location?.max_price]?.join(","),"Filter":"between"})
        }
        if(location?.min_size >= 0 && location?.max_size) {
            url.push({"Field":`starT_AREA_${unit_type}`,"Value": [location?.min_size, location?.max_size]?.join(","),"Filter":"between"})
        }
        if(location?.sort === "price-asc") {
            sort = [{"Field": minPrice,"Order":"ASC"}]
        }
        if(location?.sort === "price-desc") {
            sort = [{"Field": minPrice,"Order":"DESC"}]
        }
        if(location?.sort === "size-asc") {
            sort = [{"Field":`starT_AREA_${unit_type}`,"Order":"ASC"}]
        }
        if(location?.sort === "size-desc") {
            sort = [{"Field":`starT_AREA_${unit_type}`,"Order":"DESC"}]
        }
    }

    if(location?.page) {
        pageno = location?.page;
    }

    if(officE_SECTION_ID){
        url.push({"Field":`officE_SECTION_ID`,"Value": officE_SECTION_ID?.join(","),"Filter":"in"})
    }

    return { conditions : url, sort : sort, page : pageno };
}

export const createQueryParams = (filterList, sort, pageno, countrysearch, filter = {}, page, view) => {
    let url = "";
    let params = {};
    if(filterList?.length && !countrysearch) {
        const country = filterList?.find(list => list?.filterName === "Country")?.value;
        const project = filterList?.find(list => list?.filterName === "Project")?.value;
        const type = filterList?.find(list => list?.filterName === "Type")?.value;
        const bed = filterList?.find(list => list?.filterName === "Bedroom")?.value;
        const status = filterList?.find(list => list?.filterName === "Project Status")?.value;
        const price = filterList?.find(list => list?.filterName === "Price")?.value;
        const size = filterList?.find(list => list?.filterName === "Size")?.value;

        if(country?.length) {
            const name = country?.map(list => list?.name)?.join(",");
            url = `?country=${name}`;
            params.country = name;
        }
        if(project?.length) {
            let name = "";
            if(page) {
                name = project?.map(list => list?.propertY_ID)?.join(",");
                url = url ? `${url}&projectid=${name}` : `?projectid=${name}`;
                params.projectid = name;
            } else {
                name = project?.map(list => list?.projecT_NAME)?.join(",");
                url = url ? `${url}&project=${name}` : `?project=${name}`;
                params.project = name;
            }
        }
        if(type?.length) {
            url = url ? `${url}&type=${type?.join(",")}` : `?type=${type?.join(",")}`;
            params.type = type?.join(",");

        }
        if(bed?.length) {
            url = url ? `${url}&bed=${bed?.join(",")}` : `?bed=${bed?.join(",")}`;
            params.bed = bed?.join(",");

        }
        if(status?.length) {
            url = url ? `${url}&status=${status?.join(",")}` : `?status=${status?.join(",")}`;
            params.status = status?.join(",");
        }
        if(price?.length) {
            url = url ? `${url}&min_price=${price[0]}&max_price=${price[1]}` : `?min_price=${price[0]}&max_price=${price[1]}`;
            params.min_price = price[0];
            params.max_price = price[1];
        }
        if(size?.length) {
            url = url ? `${url}&min_size=${size[0]}&max_size=${size[1]}` : `?min_size=${size[0]}&max_size=${size[1]}`;
            params.min_size = size[0];
            params.max_size = size[1];
        }
    }
    if(Object.keys(filter)?.length > 0 && !countrysearch) {
        if(filter?.country) {
            url = `?country=${filter?.country}`;
        }
        if(filter?.project) {
            url = url ? `${url}&project=${filter?.project}` : `?project=${filter?.project}`;

        }
        if(filter?.projectid) {
            url = url ? `${url}&projectid=${filter?.projectid}` : `?projectid=${filter?.projectid}`;

        }
        if(filter?.type) {
            url = url ? `${url}&type=${filter?.type}` : `?type=${filter?.type}`;
        }
        if(filter?.bed) {
            url = url ? `${url}&bed=${filter?.bed}` : `?bed=${filter?.bed}`;

        }
        if(filter?.status) {
            url = url ? `${url}&status=${filter?.status}` : `?status=${filter?.status}`;
        }
        if(filter?.min_price && filter?.max_price) {
            url = url ? `${url}&min_price=${filter?.min_price}&max_price=${filter?.max_price}` : `?min_price=${filter?.min_price}&max_price=${filter?.max_price}`;
        }
        if(filter?.min_size && filter?.max_size) {
            url = url ? `${url}&min_size=${filter?.min_size}&max_size=${filter?.max_size}` : `?min_size=${filter?.min_size}&max_size=${filter?.max_size}`;
        }

        delete filter?.page;
        params = filter;
    }
    if(countrysearch && filterList?.length === 0) {
        const name = countrysearch?.name || countrysearch;
        url = `?countrysearch=${name}`;
        params.countrysearch = name;
    }
    if(sort) {
        url = url ? `${url}&sort=${sort}` : `?sort=${sort}`;
        params.sort = sort;
    }
    if(pageno) {
        url = url ? `${url}&page=${pageno}` : `?page=${pageno}`;
        params.page = pageno;
    }

    if(view) {
        url = url ? `${url}&view=${view}` : `?view=${view}`;
    }

    return {url : url, params : params};
}

export const createFilterQuery = (filterList, searchField, path, paramQuery) => {
    let url = "";
    let params = {};

    if(path === "booking-history") {
        url = `?id=${paramQuery?.id}`;
        params.id = paramQuery?.id;
    }

    if(filterList?.length) {
        const status = filterList?.find(list => list?.filterName === "By Booking Status")?.value || "";
        const paid = filterList?.find(list => list?.filterName === "By Percent Paid")?.value || "";
        const time = filterList?.find(list => list?.filterName === "By Time") || "";
        const person = filterList?.find(list => list?.filterName === "By Salesperson") || "";

        if(status) {
            url = url ? `${url}&status=${status}` : `?status=${status}`;
            params.status = status;
        }
        if(paid) {
            url = url ? `${url}&paid=${paid}` : `?paid=${paid}`;
            params.paid = paid;
        }
        if(time) {
            url = url ? `${url}&time=${time?.value}` : `?time=${time?.value}`;
            params.time = time?.value;
        }
        if(time?.value === "Custom Range") {
            let start = getDateFormat(time?.startDate);
            let end = getDateFormat(time?.endDate);

            url = url ? `${url}&start_date=${start}&end_date=${end}` : `?start_date=${start}&end_date=${end}`;
            params.start_date = start;
            params.end_date = end;
        }
        if(person) {
            url = url ? `${url}&salesperson_id=${person?.id}` : `?salesperson_id=${person?.id}`;
            params.salesperson_id = person?.id;
        }
    }
    if(searchField) {
        url = url ? `${url}&search=${searchField}` : `?search=${searchField}`;
        params.search = searchField;
    }
    if(paramQuery?.tab) {
        url= url ? `${url}&tab=${paramQuery?.tab}` : `?tab=${paramQuery?.tab}`
    } 

    return {url : url, params : params};
}

export const queryReviewPage = (params) => {
    return {
        id: params?.id,
        no: params?.no,
        params: params,
        project_id: params?.projectid
    }
}

export const getFilterQuery = (query, pageName) => {
    let url = "";

    if(query?.id) {
        url = `&customer_id=${query?.id}`
    }
    if(pageName) {
        if(query?.status && query?.status !== "All") {
            url = `${url}&type=${apiPayload[query?.status]}`;
        }
    } else {
        if(query?.status) {
            url = `${url}&type=${apiPayload[query?.status]}`;
        }
    }
    if(query?.paid) {
        url = `${url}&percentage_paid=${query?.paid === "All" ? "all" : apiPayload[query?.paid]}`;
    }
    if(query?.time) {
        url = `${url}&filter=${apiPayload[query?.time]}`;
    }
    if(query?.time === "Custom Range") {
        url = `${url}&start_date=${query?.start_date}&end_date=${query?.end_date}` 
    }
    if(query?.salesperson_id) {
        url = `${url}&sales_person_id=${query?.salesperson_id === "N/A" ? "" : query?.salesperson_id}`;
    }
    if(query?.search) {
        url = `${url}&filter=search&name=${query?.search}`;
    }

    return url;
}

const apiPayload = {
    "All": 1,
    "Prospect": 2,
    "Customer": 3,
    "Pending": 1,
    "Completed": 2,
    "Less than 10%": "<10",
    "10%": "10",
    "20%": "20",
    "Above 20%": ">20",
    "Recent": "recent",
    "Last 3 months": "last_3_months",
    "Last 6 months": "last_6_months",
    "Last 1 year": "last_12_months",
    "Custom Range": "custom",
}
