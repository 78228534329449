import { apiServiceRequest } from "Utils/axiosInstance";
import { baseURL } from "Utils/baseUrl";
import { getUserToken } from "Utils/tokenProvider";

const brokerSaleOfferService = async (
  setSaleOffer,
  setSaleOfferLoading,
  notify,
  id,
  unitNo,
  name,
  project_id,
  unitArray
) => {
  setSaleOfferLoading(true);

  let unitId = "";
  if (unitArray?.length) {
    unitId = unitArray?.map((list) => list?.unit_id)?.toString();
  } else {
    unitId = id;
  }

  const res = await apiServiceRequest({
    url: `${baseURL}GetBrokerSalesOffer?OfficeSectionID=${unitId}`,
    headers: { Authorization: `Bearer ${await getUserToken(notify)}` },
    notify: notify,
  });

  if (res?.successStatus) {
    if (res?.response?.length) {
      let resData = res?.response?.map((value) => {
        if (unitArray?.length) {
          return unitArray
            ?.filter((unit) => unit?.unit_id === value?.officeSectionID)
            ?.map((dataValue) => {
              return {
                propertyID: dataValue?.project_id || "",
                propertyName: dataValue?.title || "",
                unitNo: dataValue?.unit_no || "",
                documentName: "Sales Offer",
                documentLink: value?.salesOfferLinkTiny || value?.salesOfferLink,
              };
            });
        } else {
          return {
            propertyID: project_id || "",
            propertyName: name || "",
            unitNo: unitNo || "",
            documentName: "Sales Offer",
            documentLink: value?.salesOfferLinkTiny || value?.salesOfferLink,
          };
        }
      });

      if (setSaleOffer) setSaleOffer(resData.flat() || []);
      setSaleOfferLoading(false);
      return resData;
    }
  }
  setSaleOfferLoading(false);
};

export default brokerSaleOfferService;
