import React, { useState } from "react";
import { useRedirect, useStore } from "react-admin";
import { useForm } from "react-hook-form";
import fileUploadService from "Services/fileUploadService";
import { docForm, docListForm } from "Utils/uploadSection";
import UploadDoc from "Pages/RegisterAgency/CompanyUpload/UploadDoc";
import isInProcess from "Utils/ui-inprocess-status";
import { isinDraft } from "Utils/ui-lock-status";

const Upload = ({ notification }) => {
  // eslint-disable-next-line no-unused-vars, no-undef
  const [formData, setFormData] = useStore();
  const [os, setOs] = useState(null);
  const [addtionalDocument, setAddtionalDocument] = useState([]);
  const [passport_array, setPassport_array] = useState({});
  const [residence_array, setResidence_array] = useState({});
  const [qatar_array, setQatar_array] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [additionalDocumentsFiles, setAdditionalDocumentsFiles] = useState([]);
  const [deleteId, setDeleteId] = useState([]);
  const user_id = formData?.user_id;
  const user_type = formData?.user_type;
  const nationality = formData?.nationality;
  const user_email = formData?.email;
  const country_residence = formData?.country_residence;

  const {
    register,
    handleSubmit,
    resetField,
    watch,
    setValue,
    formState: { errors },
  } = useForm();
  const redirect = useRedirect();

  const watchPassportNationalIdDocument = watch(
    "passportNationalIdDocument",
    []
  );
  const watchResidenceCivilCard = watch("residenceCivilCard", []);
  const watchQatarIdCivilCard = watch("qatarId", []);
  const watchAdditionalDocuments = watch("additionalDocuments", []);
  const watchAdditionalDocumentsName = watch("additionalDocumentName", []);

  const setDetails = (resData) => {
    resData?.oracle_status ? setOs(resData?.oracle_status) : setOs("-");
    docListForm(
      resData,
      setAddtionalDocument,
      setAdditionalDocumentsFiles,
      setValue,
      setPassport_array,
      setResidence_array,
      setQatar_array
    );
  };

  const handleBack = () => {
    redirect("/register-myself/bank");
  };

  const fileUploadData = async (data) => {
    const passportNationalIdDocument = data?.passportNationalIdDocument[0]
      ? docForm(data?.passportNationalIdDocument, "passport")
      : {};

    const residenceCardDocument = data?.residenceCardDocument[0]
      ? docForm(data?.residenceCardDocument, "residence")
      : {};

    const qatarCardDocument = data?.qatarCardDocument[0]
      ? docForm(data?.qatarCardDocument, "qatarId")
      : {};

    let formData = new FormData();

    if (passport_array && passportNationalIdDocument) {
      passportNationalIdDocument.id = passport_array.id;
    }

    if (residence_array && residenceCardDocument) {
      residenceCardDocument.id = residence_array.id;
    }

    if (qatar_array && qatarCardDocument) {
      qatarCardDocument.id = qatar_array.id;
    }

    let fileDataList;
    fileDataList = [...additionalDocumentsFiles];

    if (additionalDocumentsFiles.length === addtionalDocument.length) {
      fileDataList = fileDataList?.filter(
        (list) => list.doc_type !== "additional"
      );
    } else {
      fileDataList = fileDataList?.filter(
        (list) => list.doc_type === "additional" && !("name" in list)
      );
    }
    fileDataList.push(passportNationalIdDocument);

    if (nationality === "OM" || country_residence === "OM") {
      fileDataList.push(residenceCardDocument);
    }

    if (nationality === "QA" || country_residence === "QA") {
      fileDataList.push(qatarCardDocument);
    }

    if (passport_array === data.passportNationalIdDocument?.[0]) {
      fileDataList = fileDataList?.filter(
        (list) => list.doc_type !== "passport"
      );
    }

    const file = fileDataList.map((value) => {
      formData.append("file", value.file);
      return value.file;
    });

    formData.append(
      "user_id",
      user_id || JSON.parse(localStorage.getItem("user_id"))
    );

    formData?.append("user_type", 1);

    const additional = fileDataList?.map((doc) => {
      let data = {
        doc_name: doc?.doc_name,
        file_name: doc?.file_name,
        doc_type: doc?.doc_type
      };

      if (doc?.id) {
        data.id = doc?.id
      }

      return data;
    });

    deleteId.length !== 0 &&
      formData.append("delete_file_ids", JSON.stringify([...deleteId]));

    formData.append("data", JSON.stringify(additional));

    fileUploadService(
      formData,
      setLoading,
      notification,
      redirect,
      additionalDocumentsFiles?.length,
      addtionalDocument?.length,
      os,
      "individual",
      user_email
    );
  };

  const callApi = (data) => {
    if (!data?.passportNationalIdDocument) {
      data.passportNationalIdDocument = watchPassportNationalIdDocument;
    }
    if (!data?.residenceCardDocument) {
      data.residenceCardDocument = watchResidenceCivilCard;
    }
    if (!data?.qatarCardDocument) {
      data.qatarCardDocument = watchQatarIdCivilCard;
    }

    fileUploadData(data);
  };

  const onSubmit = async (data) => {
    if (!isInProcess(os) && !isinDraft(os)) {
      callApi(data);
    } else {
      if (openDialog || additionalDocumentsFiles?.length !== 20) {
        callApi(data);
      } else {
        if (additionalDocumentsFiles?.length === 20) {
          setOpenDialog(true);
        }
      }
    }
  };

  const onError = (errors, e) => console.log(errors, e);
  return (
    <UploadDoc
      os={os}
      user_type={user_type}
      watchPassportNationalIdDocument={watchPassportNationalIdDocument}
      watchResidenceCivilCard={watchResidenceCivilCard}
      watchQatarIdCivilCard={watchQatarIdCivilCard}
      addtionalDocument={addtionalDocument}
      setDeleteId={setDeleteId}
      additionalDocumentsFiles={additionalDocumentsFiles}
      setAdditionalDocumentsFiles={setAdditionalDocumentsFiles}
      loading={loading}
      submitHandler={handleSubmit(onSubmit, onError)}
      handleBack={handleBack}
      omanCheck={nationality === "OM" || country_residence === "OM"}
      qatarCheck={nationality === "QA" || country_residence === "QA"}
      watchAdditionalDocuments={watchAdditionalDocuments}
      watchAdditionalDocumentsName={watchAdditionalDocumentsName}
      setLoading={setLoading}
      setResponseData={setFormData}
      setDetails={setDetails}
      register={register}
      resetField={resetField}
      setValue={setValue}
      openDialog={openDialog}
      setOpenDialog={setOpenDialog}
      errors={errors}
      notification={notification}
    />
  );
};

export default Upload;
