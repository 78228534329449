import React, { useEffect, useState } from "react";
import {
  AppBar,
  Box,
  CssBaseline,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
  Button,
  Typography,
  Link,
  Container,
  Grid,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CircularProgress from "@mui/material/CircularProgress";
import logo from "../../images/new-logo.svg";
import user from "../../images/user.svg";
import Styles from "./Header.scss";
import { useRedirect } from "react-admin";
import { navItems } from "Utils/constantValues";
import ProfileSection from "Components/ProfileSection";
import ClickAwayListener from "@mui/base/ClickAwayListener"; // closes dropdown when click outside
import gtagEventFire from "Utils/ga4EventTrigger";
import { userDesignation, userProfileName } from "Utils/commonFunctions";
import { removeLocalStorage } from "Utils/removeStorage";

const drawerWidth = 310;

function Header({ path, pictureLoading, picture, page, notification, userData, releaseBlockUnits, pagePath= null }) {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [showBox, setShowBox] = useState(false);
  const formStatus = sessionStorage.getItem("formStatus") || null;
  const previous_url = localStorage.getItem("previous_url") || null;
  const redirect = useRedirect();

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const profileHandler = () => {
    setShowProfile(!showProfile);
  };

  const handleClickAway = () => {
    setShowProfile(false);
  };
  
  const handleMenuClick = (item) => {
    if (item?.role === "pay") {
      window.open(item?.link);
      gtagEventFire("pay_online", {
        user_email: userData?.email
      });
    } else if (item?.role === "download") {
      gtagEventFire("download_customer_information_form", {
        user_email: userData?.email
      });
      let link = document.createElement("a");
      link.href = item?.link;
      link.download = "Lead_Customer_Details_Form.pdf";
      link.click();
      link.remove();
    } else {
      if (pagePath === "form-booking") {
        releaseBlockUnits(item?.link);
      } else {
        redirect(item?.link);
        if(formStatus || previous_url) {
          window.location.reload();
          removeLocalStorage();
          localStorage.removeItem("page");
          localStorage.removeItem("params");
          localStorage.removeItem("page-redirect");
        }
      }
    }
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Container className="login-bdm-section-mobile ">
        {(typeof picture === "object" || !picture)
          ? <i className="profile-icon iconwasalt icon-profile-circle" />
          : <img className="profile-icon" src={picture} alt="profile" />
        }
        <Typography variant="h3">
          {userProfileName(userData)}
          <span>{userDesignation(userData)}</span>
        </Typography>
      </Container>
      <List>
        {navItems.map((item, idx) => (
          <ListItem
            key={idx}
            disablePadding
            onClick={() => handleMenuClick(item)}
            className={`${path === item?.title ? "header-menu-selected" : ""}`}
          >
            <ListItemButton sx={{ textAlign: "left" }}>
              <ListItemText primary={item?.title} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  useEffect(() => {
    if(page) {
      // closes header popup when scroll if filter is sticky
      window.addEventListener("scroll", function(){
        setShowProfile(false);
      });
    }

    // hide and show box shadow in header as per design team
    window.addEventListener("scroll", function(){
      if(document.documentElement.scrollTop > 5) {
        setShowBox(true);
      } else {
        setShowBox(false);
      }
    });
  }, [])

  const redirectLinkPath = (e, url) => {
    e.preventDefault();
    if(pagePath === "form-booking") {
      releaseBlockUnits(url);
      return;
    } 
  }

  const redirectPath = (e, url) => {
    e.preventDefault();
    redirect(url);
    if(formStatus || previous_url) {
      window.location.reload();
      removeLocalStorage();
      localStorage.removeItem("page");
      localStorage.removeItem("params");
      localStorage.removeItem("page-redirect");
    }
  }

  return (
    <Box className="top-header" sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar className={`header ${showBox ? "header-shadow" : ""}`} component="nav">
        <Toolbar className="header-inner-panel">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className="mobile-menu-icon"
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography 
            className="header-logo" 
            component="div" 
            onClick={(e) => releaseBlockUnits ? redirectLinkPath(e, "/home") : redirectPath(e, "/home")} 
          >
            <Link href={(pagePath === "form-booking") ? null : "#/home"} >
              <img src={logo} alt="Dar logo" />
            </Link>
          </Typography>
          <Box className="header-nav-section">
            <Typography className="header-nav-link" component="div">
              {navItems.map((item, idx) => (
                <Button
                  className={`header-nav ${path === item?.title ? "active" : ""}`}
                  key={idx}
                  sx={{ color: "#344054" }}
                  onClick={() => handleMenuClick(item)}
                >
                  {item?.title}
                </Button>
              ))}
            </Typography>
            <Typography className="icon-section" component="div">
              <Link 
                className="wishlist-heart" 
                onClick={(e) => releaseBlockUnits ? redirectLinkPath(e, "/wishlist") : redirectPath(e, "/wishlist")}
                href={(pagePath === "form-booking") ? null : path === "wishlist" ? null : "#/wishlist"} 
              >
              {path === "wishlist" ? 
                <i className="iconwasalt icon-heart-filled" />
              : 
                <i className="iconwasalt icon-heart" /> 
              }               
              </Link>
                <ClickAwayListener onClickAway={handleClickAway}>
                  <Grid className="profile-icon-image">
                    {pictureLoading ? (
                      <CircularProgress className="profileLoader" />
                    ) : (
                      <>
                        <img
                          className="profile-icon"
                          src={
                            (typeof picture === "object" && user) ||
                            picture ||
                            user
                          }
                          alt="profile"
                          onClick={profileHandler}
                        />

                        <ProfileSection
                          imgSrc={picture || user}
                          showProfile={showProfile}
                          notification={notification}
                          email={userData?.email}
                          redirectLinkPath={redirectLinkPath}
                          pagePath={pagePath}
                          releaseBlockUnits={releaseBlockUnits}
                        />
                      </>
                    )}
                  </Grid>
                </ClickAwayListener>
            </Typography>
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}

export default Header;
