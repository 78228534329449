import React, { useEffect, useState } from "react";
import { NotFound, useRedirect, useTranslate } from "react-admin";
import { useLocation } from "react-router-dom";
import { useMediaQuery, Typography, Button } from "@mui/material";
import Header from "Components/Header";
import Container from "@mui/material/Container";
import BackButton from "Components/BackButton";
import Loader from "Components/Loader";
import Popup from "Components/Popup/Popup";
import PropertydetailsImage from "Components/PropertydetailsImage";
import PropertydetailsAmenities from "Components/PropertydetailsAmenities";
import PropertydetailsLocation from "Components/PropertydetailsLocation";
import PropertydetailsDownloadinfo from "Components/PropertydetailsDownloadinfo";
import { projectDetails } from "Services/projectService";
import { capitalFirstLetterOfWord } from "Utils/commonFunctions";
import {
  brokerAmenities,
  brokerDocuments,
} from "Services/amenitiesAndBrochureService";
import userDetails from "Services/userDetailsServices";
import { currencyStatus } from "Utils/currencyCheck";
import { Grid } from "@material-ui/core";
import { ReactComponent as ShareIcon } from "images/share-icon2.svg";
import gtagEventFire from "Utils/ga4EventTrigger";
import Error from "Pages/Error";

const PropertyDetails = ({ notification }) => {
  const [projectDetailsLoading, setProjectDetailsLoading] = useState(true);
  const [userDetailsLoading, setuserDetailsLoading] = useState(true);
  const [amenities, setAmenities] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [formData, setFormData] = useState();
  const [amenitiesLoading, setAmenitiesLoading] = useState(false);
  const [documentsLoading, setDocumentsLoading] = useState(false);
  const [details, setDetails] = useState();
  const [documentsArray, setDocumentsArray] = useState([]);

  const redirect = useRedirect();
  const location = useLocation();
  const translate = useTranslate();
  const param = new URLSearchParams(location?.search);
  const id = parseInt(param?.get("projectid")) || "";

  const untiType = formData?.unit_type?.toUpperCase();
  const currency = formData?.currency;
  const priceValue = currencyStatus(currency)
    ? details?.[`starT_PRICE_${currency}`]
    : details?.[`starT_PRICE`];
  const sizeValue = details?.[`starT_AREA_${untiType}`];
  const currencyValue = currencyStatus(currency)
    ? currency
    : details?.currency;

  const callAmentites = () => {
    brokerAmenities(setAmenities, setAmenitiesLoading, notification, id);
    brokerDocuments(setDocumentsArray, setDocumentsLoading, notification, id);
  };

  const matches = useMediaQuery("(max-width:1023px)");

  useEffect(() => {
    userDetails(setuserDetailsLoading, setFormData, "", notification);
    if (id) {
      projectDetails(setProjectDetailsLoading, setDetails, notification, id);
      callAmentites();
    } else {
      setProjectDetailsLoading(false);
      setuserDetailsLoading(false);
    }
  }, []);

  const redirectPath = () => {
    gtagEventFire("view_units", {
      property_name: details?.projecT_NAME,
      user_email: formData?.email
    });
    localStorage.setItem("projectPage", true);
    redirect({
      pathname: "/unit-listing",
      search: `country=${details?.location}&projectid=${id}`,
      hash: "#",
    });
  };

  const handleShare = () => {
    setOpenPopup(true);
  };
  
  return (
    <>
      {(projectDetailsLoading ||
        amenitiesLoading ||
        documentsLoading ||
        userDetailsLoading) && <Loader />}
      {!projectDetailsLoading &&
        !amenitiesLoading &&
        !documentsLoading &&
        !userDetailsLoading && (
          <>
            {!details ? (
              <Error />
            ) : (
              <>
                <Header
                  picture={formData?.profile}
                  pictureLoading={userDetailsLoading}
                  notification={notification}
                  userData={formData}
                  pagePath="details_page"
                />
                <BackButton />
                <Container className="project-details mt-0">
                  <Grid
                    container
                    className="project-details-new mt-0"
                    spacing={3}
                  >
                    <Grid item xs={12} md={8}>
                      <PropertydetailsImage
                        imageType={matches ? "mobile_ImageURL" : "imageURL"}
                        images={details?.images}
                        startingPrice={priceValue}
                        currency={currencyValue}
                        startingArea={sizeValue}
                        unit_type={formData?.unit_type}
                        projectName={details?.projecT_NAME}
                        id={id}
                        location={details?.location}
                        city={details?.city}
                        documents={documentsArray}
                        handleShare={handleShare}
                        translate={translate}
                      />
                      {amenities?.length > 0 && (
                        <PropertydetailsAmenities 
                          amenities={amenities} 
                          translate={translate}
                        />
                      )}
                      {documentsArray?.length > 0 && (
                        <PropertydetailsDownloadinfo
                          documents={documentsArray}
                          startingPrice={priceValue}
                          currency={currencyValue}
                          startingArea={sizeValue}
                          unit_type={formData?.unit_type}
                          projectName={details?.projecT_NAME}
                          location={details?.location}
                          city={details?.city}
                          translate={translate}
                          formData={formData}
                        />
                      )}
                      {details?.location && (
                        <PropertydetailsLocation
                          location={details?.location}
                          city={details?.city}
                          latitude={details?.latitude}
                          longitude={details?.longitude}
                          name={details?.projecT_NAME}
                          translate={translate}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Grid className="property-fixed-info">
                        <Typography className="top-panel full-button" component="div">
                          <Button
                            variant="outlined"
                            startIcon={<ShareIcon />}
                            onClick={() => handleShare()}
                          >
                            {translate("ra.fieldName.share")}
                          </Button>
                        </Typography>
                        <Typography className="bottom-panel" component="div">
                          <Typography className="text-panel" component="div">
                            <Typography component="h3">
                              {translate("ra.fieldName.startPrice")}
                              <span>
                                {new Intl.NumberFormat("en-US").format(
                                  Math.round(priceValue))
                                }
                                <strong>
                                  {currencyValue}
                                </strong>
                              </span>
                            </Typography>
                            <Typography component="h3">
                              {translate("ra.fieldName.startArea")}
                              <span>
                                {Math.round(sizeValue)}
                                <strong>
                                  {capitalFirstLetterOfWord(
                                    formData?.unit_type
                                  )}
                                </strong>
                              </span>
                            </Typography>
                          </Typography>
                          <Button variant="contained" onClick={redirectPath}>
                            {translate("ra.button.viewUnit")}
                          </Button>
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid className="mobile-fixed-bottom-section">
                      <Button
                        className="mobile-fixed-button"
                        onClick={redirectPath}
                        variant="button"
                      >
                        {translate("ra.button.viewUnit")}
                      </Button>
                    </Grid>
                  </Grid>
                  {openPopup && (
                    <Popup
                      openPopup={openPopup}
                      setOpenPopup={setOpenPopup}
                      documentArray={documentsArray}
                      startingPrice={priceValue}
                      currency={currencyValue}
                      startingArea={sizeValue}
                      projectName={details?.projecT_NAME}
                      unit_type={untiType}
                      location={details?.location}
                      city={details?.city}
                      images={details?.images[0]?.mobile_ImageURL}
                      email={formData?.email}
                    />
                  )}
                </Container>
              </>
            )}
          </>
        )}
    </>
  );
};

export default PropertyDetails;
