import { apiServiceRequest } from "Utils/axiosInstance";
import { baseURL } from "Utils/baseUrl";
import { getUserToken } from "Utils/tokenProvider";

export const brokerUnitLayout = async (
  setDocuments,
  setLoading,
  notify,
  id,
  unitNo,
  projectName,
  projectId,
  unitArray
) => {
  setLoading(true);

  let unitId = "";
  if (unitArray?.length) {
    unitId = unitArray?.map((list) => list?.unit_id)?.toString();
  } else {
    unitId = id;
  }

  const res = await apiServiceRequest({
    url: `${baseURL}GetBrokerUnitLayout?OfficeSectionID=${unitId}`,
    headers: { Authorization: `Bearer ${await getUserToken(notify)}` },
    notify: notify,
  });
 
  if (res?.successStatus) {
    if (res?.response?.length) {
      let resData = res?.response.map((value) => {
        if (unitArray?.length) {
          return unitArray
            ?.filter((unit) => unit?.unit_id === value?.officeSectionID)
            ?.map((list) => {
              return {
                propertyID: list?.project_id || "",
                propertyName: list?.title || "",
                unitNo: list?.unit_no || "",
                documentName: "Unit Layout",
                documentLink: value?.unitLayoutLinkTiny || value?.unitLayoutLink,
              };
            });
        } else {
          return {
            documentLink: value?.unitLayoutLinkTiny || value?.unitLayoutLink,
            documentName: "Unit Layout",
            propertyID: projectId,
            propertyName: projectName,
            unitNo: unitNo || "",
          };
        }
      });
      if (setDocuments) setDocuments(resData?.flat() || []);
      setLoading(false);
      return resData;
    }
  }
  setLoading(false);
};