import React, { useEffect, useState } from "react";
import { useRedirect, useStore, useStoreContext, useTranslate } from "react-admin";
import {
  CardContent,
  Grid,
  Typography,
  Button,
  Stack,
  Container,
} from "@mui/material";
import { Box } from "@mui/system";
import { useForm } from "react-hook-form";
import registerService from "Services/registerService";
import Loader from "Components/Loader";
import { setUserToken } from "Utils/tokenProvider";
import { emailPattern, passwordPattern } from "Utils/regex-patterns";
import InputField from "Components/InputField";
import HeaderButton from "Components/HeaderButton";

const CreateAccount = ({ notification }) => {
  const [user_id, setUser_id] = useStore("user_id");
  const [user_token, setUser_Token] = useStore("user_token");
  const [formData, setFormData] = useStore("formData");
  const [loading, setLoading] = useState(false);
  const redirect = useRedirect();
  const translate = useTranslate();
  const form = useForm({
    mode: "onChange",
  });
  const { register, handleSubmit, watch, formState } = form;
  const { errors } = formState;
  const store = useStoreContext();
  const user_type = store.getItem("user_type");
  store.setItem("user_type",1)
  const step = 1;

  const setData = (resData) => {
    setUser_id(resData?.id);
    setUser_Token(resData?.token);
    setUserToken(resData?.token);
  };

  const onSubmit = async (data) => {
    const { email, password, c_password } = data;
    let apiRequestData = { step, user_type, email, password, c_password };
    setFormData(apiRequestData);
    registerService(
      "",
      "",
      setLoading,
      apiRequestData,
      notification,
      redirect,
      "/register-myself/personal",
      setData
    );
  };

  // useEffect(() => {
  //   if (!user_type || user_type != 1) {
  //     redirect("/login");
  //   }
  // }, []);

  return (
    <>
      {loading && <Loader />}
        <Grid className="company-container new-fix-container">
          <Container className="company-details">
         <HeaderButton noitification={notification} classname={"only-back"}/>
            <Grid className="form-panel" item xs={12}>              
              <Box width="100%">
                <CardContent className="ui_form">
                  <Typography variant="h3">{translate("ra.pageTitle.createAccount")}</Typography>
                  <Typography variant="h4">{translate("ra.pageTitle.PersonalDetails")}</Typography>
                  {/* Email */}
                  <Grid container spacing={0}>
                    <InputField 
                      name={translate("ra.fieldName.email")}
                      register={register} 
                      errors={errors} 
                      type="email"
                      state="email"
                      message={translate("ra.validation.emailError")}
                      pattern={{
                        value: emailPattern,
                        message: translate("ra.validation.validEmail")
                      }}
                      placeholder={translate("ra.placeholder.email")}
                    />
                  </Grid>
                  <Typography variant="h4">Password Details</Typography>
                  {/* Password */}
                  <Grid container spacing={0}>
                    <InputField 
                      name={translate("ra.fieldName.password")}
                      register={register} 
                      errors={errors} 
                      type="password"
                      state="password"
                      message={translate("ra.validation.passwordError")}
                      placeholder={translate("ra.placeholder.password")}
                      pattern={{
                        value: passwordPattern,
                        message: translate("ra.validation.passwordCheck")
                      }}
                    />
                  </Grid>

                  {/* Confirm Password */}
                  <Grid container spacing={0}>
                    <InputField 
                      name={translate("ra.fieldName.confirmPassword")}
                      register={register} 
                      errors={errors} 
                      type="password"
                      state="c_password"
                      message={translate("ra.validation.confirmError")}
                      placeholder={translate("ra.placeholder.confirmPassword")}
                      validate={(val) => {
                        if (watch("password") !== val) {
                          return translate("ra.validation.passwordMatch");
                        }
                      }}
                    />
                  </Grid>

                  <Grid
                    container
                    className="company-details-button ss"
                    spacing={0}
                  >
                    <Button
                      onClick={handleSubmit(onSubmit)}
                      variant="contained"
                    >
                      {translate("ra.button.createAccount")}
                    </Button>
                  </Grid>
                </CardContent>
              </Box>
            </Grid>
          </Container>
        </Grid>
      
    </>
  );
};

export default CreateAccount;
