import React, { useState, useEffect } from "react";
import {
  Grid,
  Container,
  Typography,
  Button,
  useMediaQuery
} from "@mui/material";
import Styles from "./SearchFilter.scss";
import Filter from "./Filter";
import SortOptions from "./SortOptions";
import ClickAwayListener from "@mui/base/ClickAwayListener"; // closes dropdown when click outside
import { useNavigate } from "react-router-dom"
import { createQueryParams } from "Utils/queryValues";
import SearchIconAutoCompleteComponent from "Components/Common/SearchIconAutoComplete";
import GridIcon from "images/grid-view.svg";
import DropdownArrow from "images/dropdown-arrow.svg";
import MultiRangeSlider from "Components/Common/MultiRangeSlider/MultiRangeSlider";
import DoubleRangeSlider from "Components/Common/RangeSlider/DoubleRangeSlider";
import { filterDisplayValues } from "Utils/filterData";
import gtagEventFire from "Utils/ga4EventTrigger";

const SearchFilter = ({ filterData, userData, projectListApi, params, page, filterList,
  setFilterList, changeViewHandler, changeView, path, getDataCount, count, translate }) => {
  const [showFilters, setShowFilters] = useState(false)
  const [showSortOptions, setShowSortOptions] = useState(false)
  const [showFilterCapsules, setShowFilterCapsules] = useState(false)
  const [capsuleData, setCapsuleData] = useState(null)
  const [sortValue, setSortValue] = useState(null)
  const [searchField, setSearchField] = useState(null)
  const [openDrop, setOpenDrop] = useState(false);
  const [countrySearch, setCountrySearch] = useState([]);

  const navigate = useNavigate();
  const matches = useMediaQuery("(min-width:800px)");

  const filterHandler = () => {
    setShowFilters(!showFilters)
    setShowSortOptions(false)
    setShowFilterCapsules(false)
    setCapsuleData(null)
  }

  const sortHandler = () => {
    setShowSortOptions(!showSortOptions)
    setShowFilters(false)
    setShowFilterCapsules(false)
    setCapsuleData(null)
  }

  const filterCapsuleHandler = (data, idx) => {
    if (capsuleData?.filterName === data?.filterName) {
      setShowFilterCapsules(false)
      setCapsuleData(null)
    } else {
      setShowFilterCapsules(true)
      // making deep copy to differentiate from filterlist copy
      setCapsuleData(JSON.parse(JSON.stringify(data)))
      if(matches) getCapsulePosition(idx)
    }
    setShowFilters(false)
    setShowSortOptions(false)
  }

  const handleClickAway = () => {
    if (!openDrop) {
      setShowFilters(false)
      setShowFilterCapsules(false)
      setCapsuleData(null)
    }
    setShowSortOptions(false)
  };

  const mobileSort = () => {
    setShowSortOptions(false)
  };

  const mobileCapsule = () => {
    setShowFilterCapsules(false)
    setCapsuleData(null)
  };

  useEffect(() => {
    setCountrySearch(filterData?.locations || [])

    if (params?.countrysearch) {
      const filterValue = filterData?.locations?.find(data => data?.name?.includes(params?.countrysearch))
      setSearchField(filterValue)
    }
  }, [filterData, params])

  const searchHandler = (value) => {
    gtagEventFire(page === "unitlist" ? "unit_search" : "home_search", {
      user_email: userData?.email,
      country_value: value?.name
   })
    setSearchField(value)
    localStorage.removeItem("projectPage")
    setFilterList([])
    // lose focus on search while calling api
    document.activeElement.blur();
    callApiwithQuery([], value);
  }

  // when filter is opened in mobile, background scrolling is blocked
  useEffect(() => {
    if (!matches) {
      if (!showFilters && !showFilterCapsules) {
        document.body.style.overflow = "auto"
      } else {
        document.body.style.overflow = "hidden"
      }
    }
  }, [showFilters, showFilterCapsules])

  // price and size component
  const priceValueSelect = (listName, value) => {
    const scaleAmount = listName === "Price" ? 500000 : 10000;
    const minDistance = listName === "Price" ? 100000 : 10000;
    const maxLabel = listName === "Price" ? 50000000 : 1000000;
    return (
      <Grid className="filter-price-info">
        <MultiRangeSlider
          priceRange={value}
          setOpenDrop={setOpenDrop}
          name={listName}
          setValue={filterValuechange}
          translate={translate}
        />
        <DoubleRangeSlider
          priceRange={value}
          scaleAmount={scaleAmount}
          minDistance={minDistance}
          maxLabel={maxLabel}
          setValue={filterValuechange}
        />
      </Grid>
    )
  }

  // grid and list view button
  const gridListButton = (textView) => {
    return (
      <Grid className="view-button-unitpage">
        <Button variant="text" onClick={changeViewHandler} >
          {changeView
            ? <><img src={GridIcon} alt="grid icon" /> {textView ? translate("ra.button.gridView") : ""}</>
            : <><i className="iconwasalt icon-list" /> {textView ? translate("ra.button.listView") : ""}</>
          }
        </Button>
      </Grid>
    )
  }

  // price and size value change in capsule
  const filterValuechange = (list) => {
    const data = { ...capsuleData };
    data.value = list;
    setCapsuleData(data)
  }

  // remove applied filter section in capsule
  const filterRemoveHandler = (idx, e) => {
    e.stopPropagation();
    const data = [...filterList];
    data?.splice(idx, 1);
    setFilterList(data);
    callApiwithQuery(data, null);
  }

  // remove applied filter values inside section in capsule
  const filterValueRemoveHandler = (index) => {
    const data = { ...capsuleData };
    const dataValue = data?.value
    dataValue?.splice(index, 1)
    setCapsuleData(data)
    if (data?.value?.length === 0) {
      filterApplyHandler()
    }
  }

  const filterApplyHandler = () => {
    const data = [...filterList];
    const capsule = { ...capsuleData }
    const idx = data?.findIndex(list => list?.filterName === capsule?.filterName)
    data[idx] = capsule;
    const filterValue = data?.filter(list => list?.value?.length > 0)
    setFilterList(filterValue);
    mobileCapsule();
    callApiwithQuery(filterValue, null)
  }

  const callApiwithQuery = (filterData, countrySearch) => {
    const filterUrl = createQueryParams(filterData, params?.sort, null, countrySearch, {}, page, params?.view);
    navigate({
      pathname: page ? path : "/home",
      search: filterUrl?.url,
      hash: "#"
    }, {replace: true});
    projectListApi(userData, filterUrl?.params);
  }

  // setting filter section sticky to top when scroll
  useEffect(() => {
    let capsuleId = document.getElementById("filter-capsule-id");
    let topValue = path ? 50 : 20;

    let top = capsuleId.getBoundingClientRect().top - document.body.getBoundingClientRect().top - topValue;
    
    window.addEventListener("scroll", function () {
      let className = !path ? "home-sticky" : "unit-sticky";
      if (document.documentElement.scrollTop >= top) {
        capsuleId.classList.add("filter-position-sticky");
        capsuleId.classList.add(className);
      } else {
        capsuleId.classList.remove("filter-position-sticky");
        capsuleId.classList.remove(className);
      }
      setShowFilterCapsules(false);
      setCapsuleData(null);
    });
  }, [])

  // setting capsule dropdown position above horizontal scrollbar
  const getCapsulePosition = (idx) => {
      const capsuleDropdown = document?.getElementById(`filter-capsule-grid-${idx}`)
      const capsuleName = document?.getElementById(`filter-capsule-value-${idx}`)
      const arrow = document?.getElementsByClassName("dropdown-arrow")
  
      document.addEventListener("click", function(e){
        let leftValue;
        let leftArrow;
        if(capsuleName.getBoundingClientRect()?.left + 200 >= document.documentElement?.clientWidth) {
          leftValue = document.documentElement?.clientWidth - 475;
          if(capsuleName.getBoundingClientRect()?.left + 55 > document.documentElement?.clientWidth) {
            leftArrow = 85;
          } else {
            leftArrow = 56;
          }
        } else {
          leftValue = capsuleName.getBoundingClientRect()?.left - 250;
          leftArrow = 56;
        }
        capsuleDropdown?.style?.setProperty("top", capsuleName.getBoundingClientRect()?.top + 45 + "px");
        capsuleDropdown?.style?.setProperty("left", leftValue + "px");
        capsuleDropdown?.style?.setProperty("position", "fixed");
        arrow[0]?.style?.setProperty("left", leftArrow + "%");
      });
  }

  return (
    <>
      {matches ?
        <Container className="filter-panel">
          <Grid id="filter-capsule-id" className={`form-section`}>
            <Typography variant="div" className="filter-search-result">
              <SearchIconAutoCompleteComponent
                id="search-field"
                name="search"
                className="filter-autocomplete"
                value={searchField}
                onChange={(newInputValue) => {
                  searchHandler(newInputValue);
                }}
                options={countrySearch}
                getOptionLabel={(option) => option?.name || ""}
                placeholder={translate("ra.fieldName.searchCounty")}
              />
            </Typography>
            <Grid className="filters-section">
              <Grid className="filters-total-section">
                <Typography className="filters" component="div" onClick={filterHandler}>
                  <Button variant="text" ><i className="clickble-icon iconwasalt icon-filter-new" /> {translate("ra.fieldName.filter")}
                    <i className={`clickble-icon iconwasalt icon-arrow-down v-arrow ${showFilters ? "rotate-filter-icon" : ""}`} />
                  </Button>
                  {filterList?.length > 0 && <span className="notification">{filterList?.length}</span>}
                </Typography>
                {showFilters &&
                  <ClickAwayListener onClickAway={handleClickAway}>
                    <Grid>
                      <Filter
                        filterList={filterList}
                        filterData={filterData}
                        userData={userData}
                        setFilterList={setFilterList}
                        setShowFilters={setShowFilters}
                        params={params}
                        page={page}
                        setSearchField={setSearchField}
                        setOpenDrop={setOpenDrop}
                        getDataCount={getDataCount}
                        count={count}
                        translate={translate}
                        callApiwithQuery={callApiwithQuery}
                      />
                    </Grid>
                  </ClickAwayListener>
                }
              </Grid>
              <Grid className="filters-total-section">
                <Typography className="filters" component="div" onClick={sortHandler}>
                  <Button variant="text"><i className="clickble-icon iconwasalt icon-sort" ></i> {translate("ra.fieldName.sort")}
                    <i className={`clickble-icon iconwasalt icon-arrow-down v-arrow ${showSortOptions ? "rotate-filter-icon" : ""}`} />
                  </Button>
                </Typography>
                {showSortOptions &&
                  <ClickAwayListener onClickAway={handleClickAway}>
                    <Grid>
                      <SortOptions
                        sortValue={sortValue}
                        setSortValue={setSortValue}
                        setShowSortOptions={setShowSortOptions}
                        projectListApi={projectListApi}
                        params={params}
                        userData={userData}
                        page={page}
                        path={path}
                      />
                    </Grid>
                  </ClickAwayListener>
                }
              </Grid>
            </Grid>
            {page && gridListButton(true)}
          </Grid>
          {filterList?.length > 0 &&
            <Grid className={`filters-section selected-filter-panel`}>
              <>
                <Typography variant="subtitle1">{translate("ra.fieldName.appliedFilter")}</Typography>
                <Grid className="search-scroll-desktop overflow-scroll">
                  {filterList.map((list, idx) =>
                    <Grid className="capsule-list-section" key={idx} >
                      <Typography onClick={() => filterCapsuleHandler(list, idx)} className="filters filter-capsule" component="Grid">
                        <Button variant="text" >
                        <Typography className="capsule-country">{list?.filterName}:</Typography>
                          <Typography className="capsule-name">{filterDisplayValues(list, userData)}</Typography>
                          {list?.filterName !== "Price" && list?.filterName !== "Size" && list?.value?.length > 1 &&
                            <Typography className="capsule-more">{`, +${list?.value?.length - 1} more`}</Typography>
                          }
                          <i id={`filter-capsule-value-${idx}`} onClick={(e) => filterRemoveHandler(idx, e)} className="iconwasalt icon-close-filled" />
                        </Button>
                      </Typography>
                      <Grid id={`filter-capsule-grid-${idx}`} className="capsule-dropdown">
                        {showFilterCapsules &&
                          (capsuleData?.filterName === list?.filterName) &&
                          <ClickAwayListener onClickAway={handleClickAway}>
                            <Grid>
                              <img id={`capsule-dropdown-arrow-${idx}`} className="dropdown-arrow" src={DropdownArrow} alt="Dropdown arrow" />
                              <Typography className="filtername" variant="subtitle1">{list?.filterName} </Typography>
                              <Grid className="capsule-select-list">
                                {list?.filterName === "Price" || list?.filterName === "Size"
                                  ?
                                  priceValueSelect(list?.filterName, capsuleData?.value)
                                  :
                                  capsuleData?.value?.map((data, index) =>
                                    <Button
                                      key={index}
                                      variant="contained"
                                      className="capsule-select"
                                      sx={{
                                        textTransform: "none",
                                      }}
                                    >
                                      {data?.name || data?.projecT_NAME || data}
                                      <i onClick={() => filterValueRemoveHandler(index)} className="iconwasalt icon-close-filled" />
                                    </Button>
                                  )}
                              </Grid>
                              <Grid className="company-details-button align-right">
                                <Button onClick={filterApplyHandler} className="new-color-button" variant="button">{translate("ra.button.apply")}</Button>
                              </Grid>
                            </Grid>
                          </ClickAwayListener>
                        }
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </>
            </Grid>
          }
        </Container>
        :
        <Container className="filter-panel for-mobile">
          <Grid id="filter-capsule-id" className={`form-section top-form-panel`}>
            <Typography variant="div" className="filter-search-result">
              <SearchIconAutoCompleteComponent
                id="search-field"
                name="search"
                className="filter-autocomplete"
                value={searchField}
                onChange={(newInputValue) => {
                  searchHandler(newInputValue);
                }}
                options={countrySearch}
                getOptionLabel={(option) => option?.name || ""}
                placeholder={translate("ra.fieldName.searchCounty")}
              />
            </Typography>
            <Grid className="filters-section">
              <Typography className="filters" component="div" onClick={filterHandler} >
                <Button variant="text"><i className="clickble-icon iconwasalt icon-filter-new" ></i>
                </Button>
                {filterList?.length > 0 && <span className="notification">{filterList?.length}</span>}
              </Typography>
              {showFilters &&
                <Filter
                  filterList={filterList}
                  filterData={filterData}
                  userData={userData}
                  setFilterList={setFilterList}
                  setShowFilters={setShowFilters}
                  params={params}
                  page={page}
                  setSearchField={setSearchField}
                  getDataCount={getDataCount}
                  count={count}
                  translate={translate}
                  callApiwithQuery={callApiwithQuery}
                />
              }
              <ClickAwayListener onClickAway={mobileSort}>
                <Grid>
                  <Typography className="filters short-icon" component="div" onClick={sortHandler}>
                    <Button variant="text"><i className="clickble-icon iconwasalt icon-sort" ></i>
                    </Button>
                    {showSortOptions &&
                      <SortOptions
                        sortValue={sortValue}
                        setSortValue={setSortValue}
                        setShowSortOptions={setShowSortOptions}
                        projectListApi={projectListApi}
                        params={params}
                        userData={userData}
                        page={page}
                        path={path}
                      />
                    }
                  </Typography>
                </Grid>
              </ClickAwayListener>
              {page && gridListButton(false)}
            </Grid>
          </Grid>
          {filterList?.length > 0 &&
            <Grid className={`form-section bottom-filter-section`}>
              <>
                <Typography variant="subtitle1">{translate("ra.fieldName.appliedFilter")}</Typography>
                <Grid className={`filters-section selected-filter-panel ${showFilterCapsules && "search-scroll"}`}>
                  {filterList.map((list, idx) =>
                    <>
                      <Grid className="capsule-list-section" key={idx} >
                        <Typography onClick={() => filterCapsuleHandler(list)} className="filters filter-capsule" component="div" >
                          <Button variant="text">
                          <Typography className="capsule-country">{list?.filterName}:</Typography>
                            <Typography className="capsule-name">{filterDisplayValues(list, userData)}</Typography>
                            {list?.filterName !== "Price" && list?.filterName !== "Size" && list?.value?.length > 1 &&
                              <Typography className="capsule-more">{`, +${list?.value?.length - 1} more`}</Typography>
                            }
                            <i onClick={(e) => filterRemoveHandler(idx, e)} className="iconwasalt icon-close-filled" />
                          </Button>
                        </Typography>
                        <Grid className="capsule-dropdown">
                          {showFilterCapsules &&
                            (capsuleData?.filterName === list?.filterName) &&
                            <>
                              <Typography className="filtername" variant="subtitle1">
                                {list?.filterName}
                                <i className="iconwasalt icon-close" onClick={mobileCapsule} />
                              </Typography>
                              {list?.filterName === "Price" || list?.filterName === "Size"
                                ?
                                priceValueSelect(list?.filterName, capsuleData?.value)
                                :
                                <Typography className="capsule-select-list" component="div" >
                                  {capsuleData?.value?.map((data, index) =>
                                    <Button
                                      key={index}
                                      variant="contained"
                                      className="capsule-select"
                                    >
                                      {data?.name || data?.projecT_NAME || data}
                                      <i onClick={() => filterValueRemoveHandler(index)} className="iconwasalt icon-close-filled" />
                                    </Button>
                                  )}
                                </Typography>
                              }
                              <Grid className="company-details-button">
                                <Button onClick={filterApplyHandler} className="new-color-button" variant="button">{translate("ra.button.apply")}</Button>
                              </Grid>
                            </>
                          }
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Grid>
              </>
            </Grid>
          }
        </Container>
      }
    </>
  );
};

export default SearchFilter;
