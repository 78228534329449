import React from "react";
import {
  Typography
} from "@mui/material";
import PhoneInput from "react-phone-input-2";
import isRead from "Utils/ui-lock-status";
import ErrorComponent from "../ErrorComponent";

const PhoneInputComponent = ({
  id,
  title,
  hide = false,
  className,
  value,
  onChange,
  errors,
  readOnly = true,
  errorClass,
  os,
  dropdownClass
}) => {

  return (
    <>
      <Typography id={id} variant="subtitle1">
        {title}
        {!hide && <span style={{ color: "#EF4349" }}>*</span>}
      </Typography>

      <PhoneInput
        className={`phone-field ${className}`}
        country={"gb"} // for uk --> gb as default country code
        value={value}
        inputProps={{
          readOnly: readOnly && isRead(os),
        }}
        countryCodeEditable={false}
        onChange={onChange}
        disableDropdown={isRead(os)}
        dropdownClass={dropdownClass}
      />
      {errors && (
        <ErrorComponent errors={errors} className={errorClass} />
      )}
    </>
  );
};

export default PhoneInputComponent;
