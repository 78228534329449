import React from "react";
import {
    FormControl,
    RadioGroup,
    FormControlLabel,
    Typography,
    Checkbox
} from "@mui/material";
import ErrorComponent from "../ErrorComponent";
import { useTranslate } from "react-admin";

const RadioCheckboxComponent = ({
    title,
    hide = false,
    name,
    className,
    radioValues,
    onChange,
    errors,
    register,
    defaultValue,
    readOnly,
    requiredField,
    controlClass,
    formClass,
    dataValue,
    dataName
}) => {
    const translate = useTranslate();

    return (
        <>
            <Typography variant="subtitle1">
                {title}
                {!hide && <span style={{ color: "#EF4349" }}>*</span>}
            </Typography>
            {errors && (
                <ErrorComponent errors={errors} />
            )}
            <FormControl className={controlClass}>
                <RadioGroup
                    defaultValue={defaultValue}
                    inputProps={{
                        readOnly: readOnly
                    }}
                    name="radio-buttons-group"
                    className={`radio_control ${className}`}
                    {...register(name, {
                        required: !readOnly && (requiredField || translate("ra.validation.chooseOne"))
                    })}
                >
                    {radioValues?.map((list, idx) =>
                        <FormControlLabel
                            key={idx}
                            inputProps={{
                                readOnly: readOnly
                            }}
                            value={list[dataName]}
                            label={list[dataName]?.split("-").join(", ")}
                            control={
                                <Checkbox
                                    inputProps={{
                                        readOnly: readOnly
                                    }}
                                    checked={dataValue.includes(list[dataName])}
                                />
                            }
                            {...register(name, {
                                onChange: onChange
                            })}
                            disabled={readOnly}
                            className={formClass}
                        />
                    )}
                </RadioGroup>
            </FormControl>
        </>
    );
};

export default RadioCheckboxComponent;
