import { apiServiceRequest } from "Utils/axiosInstance";
import { baseURL } from "Utils/baseUrl";
import { formatDocName } from "Utils/documentFormation";
import { getUserToken } from "Utils/tokenProvider";

export const brokerAmenities = async (
  setAmenities,
  setAmentitesLoading,
  notify,
  id
) => {
  setAmentitesLoading(true);
  const res = await apiServiceRequest({
    url: `${baseURL}GetBrokerAmenities?PropertyID=${id}`,
    headers: { Authorization: `Bearer ${await getUserToken(notify)}` },
    notify: notify,
  });

  if (res?.successStatus) {
    if (setAmenities) {
      res?.response?.length &&
        setAmenities(res?.response);
    }
  }
  setAmentitesLoading(false);
};

export const brokerDocuments = async (
  setDocuments,
  setDocumentsLoading,
  notify,
  id,
  unitNo
) => {
  setDocumentsLoading(true);
  const res = await apiServiceRequest({
    url: `${baseURL}GetBrokerDocuments?PropertyID=${id}`,
    headers: { Authorization: `Bearer ${await getUserToken(notify)}` },
    notify: notify,
  });

  if (res?.successStatus) {
    if (res?.response?.length) {
      let resData = res?.response.filter((data) => data?.language === "EN")
        .map((value) => {
          return {
            documentLink: value?.documentLinkTiny || value?.documentLink,
            documentName: formatDocName(value?.documentName),
            language: value?.language,
            propertyID: value?.propertyID,
            propertyName: value?.propertyName,
            unitNo: unitNo || "",
          };
        });
      if (setDocuments) setDocuments(resData || []);
      setDocumentsLoading(false);
      return resData;
    }
  }
  setDocumentsLoading(false);
};