import React, { useState } from "react";
import { useRedirect, useStore } from "react-admin";
import { useForm } from "react-hook-form";
import fileUploadService from "Services/fileUploadService";
import { brokerageStatus } from "Utils/dataCheck";
import { companyDocListForm, docForm } from "Utils/uploadSection";
import isInProcess from "Utils/ui-inprocess-status";
import UploadDoc from "./UploadDoc";
import { isinDraft } from "Utils/ui-lock-status";

const CompanyUpload = ({ notification }) => {
  // eslint-disable-next-line no-unused-vars, no-undef
  const [os, setOs] = useState(null);
  const [responseData, setResponseData] = useStore();
  const [addtionalDocument, setAdditonalDocument] = useState([]);
  const [loading, setLoading] = useState(false);
  const [additionalDocumentsFiles, setAdditionalDocumentsFiles] = useState([]);
  const [deleteId, setDeleteId] = useState([]);
  const [passport_array, setPassport_array] = useState();
  const [residence_array, setResidence_array] = useState();
  const [incorportion_array, setIncorportion_array] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [brokerageCertificate_array, setBrokerageCertificate_array] =
    useState();
  const [brokerId_array, setBrokerId_array] = useState();
  const [vat_array, setVat_array] = useState();
  const city = responseData?.city_registration?.toLowerCase();
  const bcn = responseData?.brokerage_certificate_number;
  const nationality = responseData?.nationality;
  const country = responseData?.country_registration;
  const user_id = responseData?.user_id;
  const user_type = responseData?.user_type;
  const user_email = responseData?.email;
  const auth_email = responseData?.email_address;
  const brokerId = responseData?.broker_id;

  const redirect = useRedirect();
  const {
    handleSubmit,
    watch,
    setValue,
    register,
    resetField,
    formState: { errors },
  } = useForm();

  const watchIncorporationDocument = watch("incorporationDocument", []);
  const watchPassportNationalIdDocument = watch(
    "passportNationalIdDocument",
    []
  );
  const watchBrokerageCertificateDocument = watch(
    "brokerageCertificateDocument",
    []
  );
  const watchVatCertificateDocument = watch("vatCertificateDocument", []);
  const watchBrokerIdDocument = watch("brokerIdDocument", []);
  const watchResidenceCivilCard = watch("residenceCivilCard", []);
  const watchAdditionalDocuments = watch("additionalDocuments", []);
  const watchAdditionalDocumentsName = watch("additionalDocumentName", []);

  const setDetails = (resData) => {
    resData?.oracle_status ? setOs(resData?.oracle_status) : setOs("-");
    companyDocListForm(
      resData,
      setAdditionalDocumentsFiles,
      setAdditonalDocument,
      setResponseData,
      setValue,
      setPassport_array,
      setIncorportion_array,
      setBrokerId_array,
      setBrokerageCertificate_array,
      setVat_array,
      setResidence_array
    );
  };

  const fileUploadData = async (data) => {
    const passportNationalIdDocument = data?.passportNationalIdDocument[0]
      ? docForm(data?.passportNationalIdDocument, "passport")
      : {};

    const brokerIdDocument =
      !brokerageStatus(bcn) && city === "dubai" && data?.brokerIdDocument[0]
        ? docForm(data?.brokerIdDocument, "brokerID")
        : {};

    const brokerageCertificateDocument =
      !brokerageStatus(bcn) && data?.brokerageCertificateDocument[0]
        ? docForm(data?.brokerageCertificateDocument, "brokerIDCertificate")
        : {};

    const incorporationDocument = data?.incorporationDocument[0]
      ? docForm(data?.incorporationDocument, "incorporation")
      : {};

    const vatCertificateDocument =
      !brokerageStatus(bcn) && data?.vatCertificateDocument[0]
        ? docForm(data?.vatCertificateDocument, "vatCertificate")
        : {};

    const residenceCardDocument =
      (nationality === "OM" || country === "OM") &&
        data?.residenceCardDocument[0]
        ? docForm(data?.residenceCardDocument, "residence")
        : {};

    let formData = new FormData();
    let fileDataList;
    fileDataList = [...additionalDocumentsFiles];

    if (additionalDocumentsFiles.length === addtionalDocument.length) {
      fileDataList = fileDataList?.filter(
        (list) => list.doc_type !== "additional"
      );
    } else {
      fileDataList = fileDataList?.filter(
        (list) => list.doc_type === "additional" && !("name" in list)
      );
    }

    if (nationality === "OM" || country === "OM") {
      fileDataList.push(residenceCardDocument);
    }

    if (brokerageStatus(bcn)) {
      fileDataList.push(passportNationalIdDocument, incorporationDocument);
    } else if (!brokerageStatus(bcn) && city === "dubai") {
      fileDataList.push(
        passportNationalIdDocument,
        vatCertificateDocument,
        incorporationDocument,
        brokerageCertificateDocument,
        brokerIdDocument
      );
    } else {
      fileDataList.push(
        passportNationalIdDocument,
        vatCertificateDocument,
        incorporationDocument,
        brokerageCertificateDocument
      );
    }

    if (passport_array === data.passportNationalIdDocument?.[0]) {
      fileDataList = fileDataList?.filter(
        (list) => list.doc_type !== "passport"
      );
    }

    passport_array &&
      passportNationalIdDocument &&
      (passportNationalIdDocument.id = passport_array.id);

    if (incorportion_array === data.incorporationDocument?.[0]) {
      fileDataList = fileDataList?.filter(
        (list) => list.doc_type !== "incorporation"
      );
    }

    incorportion_array &&
      incorporationDocument &&
      (incorporationDocument.id = incorportion_array.id);

    if (vat_array === data.vatCertificateDocument?.[0]) {
      fileDataList = fileDataList?.filter(
        (list) => list.doc_type !== "vatCertificate"
      );
    }

    vat_array &&
      vatCertificateDocument &&
      (vatCertificateDocument.id = vat_array.id);

    if (brokerageCertificate_array === data.brokerageCertificateDocument?.[0]) {
      fileDataList = fileDataList?.filter(
        (list) => list.doc_type !== "brokerIDCertificate"
      );
    }

    brokerageCertificate_array &&
      brokerageCertificateDocument &&
      (brokerageCertificateDocument.id = brokerageCertificate_array.id);

    if (brokerId_array === data.brokerIdDocument?.[0]) {
      fileDataList = fileDataList?.filter(
        (list) => list.doc_type !== "brokerID"
      );
    }

    brokerId_array &&
      brokerIdDocument &&
      (brokerIdDocument.id = brokerId_array.id);

    if (residence_array === data.residenceCardDocument?.[0]) {
      fileDataList = fileDataList?.filter(
        (list) => list.doc_type !== "residence"
      );
    }

    if (residence_array && residenceCardDocument) {
      residenceCardDocument.id = residence_array.id;
    }

    const file = fileDataList.map((value) => {
      formData.append("file", value.file);
      return value.file;
    });

    const additional = fileDataList?.map((doc) => {
      let data = {
        doc_name: doc?.doc_name,
        file_name: doc?.file_name,
        doc_type: doc?.doc_type
      };

      if (doc?.id) {
        data.id = doc?.id
      }

      return data;
    });

    formData.append(
      "user_id",
      user_id || JSON.parse(localStorage.getItem("user_id"))
    );

    formData?.append("user_type", 2);

    brokerId && localStorage.getItem("email_address") ? formData?.append("old_email_address", localStorage?.getItem("email_address"))
      : formData?.append("old_email_address", auth_email);


    formData?.append("email_address", auth_email);

    deleteId.length !== 0 &&
      formData.append("delete_file_ids", JSON.stringify([...deleteId]));

    formData.append("data", JSON.stringify(additional));

    fileUploadService(
      formData,
      setLoading,
      notification,
      redirect,
      additionalDocumentsFiles?.length,
      addtionalDocument?.length,
      os,
      "agency",
      user_email
    );
  };

  const callApi = (data) => {
    if (!data?.passportNationalIdDocument) {
      data.passportNationalIdDocument = watchPassportNationalIdDocument;
    }
    if (!data?.brokerIdDocument) {
      data.brokerIdDocument = watchBrokerIdDocument;
    }
    if (!data?.brokerageCertificateDocument) {
      data.brokerageCertificateDocument = watchBrokerageCertificateDocument;
    }
    if (!data?.incorporationDocument) {
      data.incorporationDocument = watchIncorporationDocument;
    }
    if (!data?.vatCertificateDocument) {
      data.vatCertificateDocument = watchVatCertificateDocument;
    }
    if (!data?.residenceCardDocument) {
      data.residenceCardDocument = watchResidenceCivilCard;
    }
    fileUploadData(data);
  };

  const onSubmit = async (data) => {
    if (!isInProcess(os) && !isinDraft(os)) {
      callApi(data);
    } else {
      if (openDialog || additionalDocumentsFiles?.length !== 20) {
        callApi(data);
      } else {
        if (additionalDocumentsFiles?.length === 20) {
          setOpenDialog(true);
        }
      }
    }
  };

  const handleBack = () => {
    redirect("/register-agency/bank");
  };

  const onError = (errors, e) => console.log(errors, e);

  return (
    <>
      <UploadDoc
        os={os}
        watchIncorporationDocument={watchIncorporationDocument}
        user_type={user_type}
        watchPassportNationalIdDocument={watchPassportNationalIdDocument}
        watchResidenceCivilCard={watchResidenceCivilCard}
        watchBrokerageCertificateDocument={watchBrokerageCertificateDocument}
        watchVatCertificateDocument={watchVatCertificateDocument}
        watchBrokerIdDocument={watchBrokerIdDocument}
        bcn={bcn}
        addtionalDocument={addtionalDocument}
        setDeleteId={setDeleteId}
        additionalDocumentsFiles={additionalDocumentsFiles}
        setAdditionalDocumentsFiles={setAdditionalDocumentsFiles}
        loading={loading}
        submitHandler={handleSubmit(onSubmit, onError)}
        handleBack={handleBack}
        city={city}
        omanCheck={nationality === "OM" || country === "OM"}
        watchAdditionalDocuments={watchAdditionalDocuments}
        watchAdditionalDocumentsName={watchAdditionalDocumentsName}
        setLoading={setLoading}
        setResponseData={setResponseData}
        setDetails={setDetails}
        register={register}
        resetField={resetField}
        setValue={setValue}
        errors={errors}
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        notification={notification}
      />
    </>
  );
};

export default CompanyUpload;