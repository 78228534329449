import React, { useEffect, useState } from "react";
import { useTranslate } from "react-admin";
import { Grid, Container, Typography, Link } from "@mui/material";
import Header from "Components/Header";
import BackButton from "Components/BackButton";
import ContactDetails from "Components/ContactDetails";
import ProfileName from "Components/ProfileName";
import styles from "./styles.module.scss";
import userDetails from "Services/userDetailsServices";
import Loader from "Components/Loader";
import bdmDetails from "Services/bdmDetailService";
import FAQItem from "Components/FAQ/FAQItem";
import faqList from "Services/faqServices";
const BDMDetails = ({ notification }) => {
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState();
  const [bdm, setBdm] = useState(null);
  const [bdmLoading, setBdmLoading] = useState(true);
  const [faqData, setFaqData] = useState([]);
  const translate = useTranslate();

  const setBdmDetails = (resData) => {
    // Setting up the bdm details
    bdmDetails(
      setBdm,
      setBdmLoading,
      notification,
      resData?.business_development_manager
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        document.body.classList.add("profile-page");
        await userDetails(setLoading, setUserData, setBdmDetails, notification);
        const faqdata = await faqList("helpAndSupport");
        setFaqData(faqdata);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  
  return (
    <>
      {(loading || bdmLoading) && <Loader />}
      <Header
        picture={userData?.profile}
        pictureLoading={loading}
        notification={notification}
        userData={userData}
      />
      {!loading && (
        <>
          <BackButton
            container_class={`container-title
            ${!bdm?.bdM_NAME && !bdm?.profilE_IMAGE ? "back-white-bg" : ""}`}
            title_class="btn-title"
            sx={{
              textTransform: "none",
            }}
          />
          <Container className="company-container mt-0 bdm-page-section">
            <Container className="company-details">
              <Grid
                className="form-panel profile-form-panel"
                sx={{ width: "100%" }}
              >
                {(bdm?.bdM_NAME || bdm?.profilE_IMAGE) && (
                  <ProfileName
                    name={bdm?.bdM_NAME}
                    title={bdm?.bdM_TITLE}
                    designation={bdm?.bdM_DESIGNATION}
                    image={bdm?.profilE_IMAGE}
                    email={bdm?.emaiL_ADDRESS}
                    phone={bdm?.bdM_PHONE_NUMBER}
                  />
                )}

                <Grid container className={styles.profile_bottom_panel}>
                  <Container className={styles.container_class}>
                    <Grid className={styles.agent_wrapper}>
                      <Typography
                        variant="div"
                        className={styles.agent_subtext}
                      >
                        {translate("ra.content.contactUs")}{" "}
                      </Typography>
                      <Typography
                        variant="div"
                        className={styles.agent_contact_text}
                      >
                        <Typography
                          variant="div"
                          className={styles.country_details}
                        >
                          <ContactDetails
                            country={translate("ra.content.supportTeam")}
                            email="agents@darglobal.co.uk"
                          />
                        </Typography>
                        <Typography
                          variant="div"
                          className={styles.country_details}
                        >
                          <ContactDetails
                            country={translate("ra.content.united")}
                            phone="+44 20 8156 5549"
                          />
                        </Typography>
                        <Typography
                          variant="div"
                          className={styles.country_details}
                        >
                          <ContactDetails
                            country={translate("ra.content.arab")}
                            phone="+971 4 247 8990"
                          />
                        </Typography>
                        <Typography
                          variant="div"
                          className={styles.country_details}
                        >
                          <ContactDetails
                            country={translate("ra.content.spain")}
                            phone="+34 951 12 12 25"
                          />
                        </Typography>
                      </Typography>
                    </Grid>
                    {faqData.length > 0 && (
                      <Grid className={styles.agent_wrapper}>
                        <Grid className={`${styles.FAQBanner}`}>Banner</Grid>
                        <Grid className={styles.FAQItem}>
                          {faqData.map((faqItem, index) => (
                            <FAQItem
                              key={index}
                              question={
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: faqItem.question,
                                  }}
                                />
                              }
                              answer={
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: faqItem.answer,
                                  }}
                                />
                              }
                            />
                          ))}
                        </Grid>
                      </Grid>
                    )}
                  </Container>
                </Grid>
              </Grid>
            </Container>
          </Container>
        </>
      )}
    </>
  );
};

export default BDMDetails;
