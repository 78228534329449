import { currencyStatus } from "Utils/currencyCheck";

export const unitApiData = (apiData, currency, userDetail, unit_type) => {
    const response = apiData?.map((item, idx) => {
        // filter price and size alone for passing it in wishlist
        const priceValues = objectKeyRename(item, "propertY_PRICE", true);
        const sizeValues = objectKeyRename(item, "saleablE_AREA", true);
  
        return {
          unit_id: item.officE_SECTION_ID,
          title: item.projecT_NAME,
          unit_no: item.officE_SECTION_CODE,
          property_type: item.uniT_TYPE,
          bedroom: item.apartmenT_TYPE,
          area: Math.round(item[`saleablE_AREA_${unit_type}`]) || "-",
          units: userDetail?.unit_type,
          currency: currencyStatus(currency) ? currency : item?.currency,
          price: currencyStatus(currency)
            ? Math.round(item[`propertY_PRICE_${currency}`])
            : Math.round(item[`propertY_PRICE_${item?.currency}`]),
          images: item.images,
          project_id: item.projecT_ID,
          location: item.location,
          city: item.city,
          status: item.uniT_STATUS,
          unitCurrency: item?.currency,
          ...priceValues,
          ...sizeValues
        };
    });

    return response;
}

export const objectKeyRename = (item, keyname, rename) => {
  let objectValue = Object.fromEntries(Object.entries(item).filter(([key]) => key.includes(keyname)));
    
  if(rename) {
    Object.keys(objectValue).forEach(function(key) {
      let newkey = key.replace(keyname, keyname?.toLowerCase());
      objectValue[newkey] = objectValue[key];
      delete objectValue[key];
    });
  }

  return objectValue;
}

export const wishlistFilter = (params, currency, unit_type) => {

    let url = "";
    let pageno = 1;
    const priceData = currencyStatus(currency) ? `propertyPrice${currency}` : `propertyPriceUSD`;
    const sortPrice = currencyStatus(currency) ? `property_price_${currency}` : `property_price_USD`;

    //passing array as array of string
    if(params?.country) {
      url = `&location=${JSON.stringify(params?.country?.split(","))}`;
    }
    if(params?.countrysearch) {
      url = `&location=${JSON.stringify(params?.countrysearch?.split(','))}`;
    }
    if(params?.projectid) {
      const project = `projectId=${JSON.stringify(params?.projectid?.split(','))}`
      url = url ? `${url}&${project}` : `&${project}`;
    }
    if(params?.type) {
      const type = `unitType=${JSON.stringify(params?.type?.split(','))}`
      url = url ? `${url}&${type}` : `&${type}`;
    }
    if(params?.bed) {
      const bed = `apartmentType=${JSON.stringify(params?.bed?.split(','))}`
      url = url ? `${url}&${bed}` : `&${bed}`;
    }
    if(params?.status) {
      const status = `unitStatus=${JSON.stringify(params?.status?.split(','))}`
      url = url ? `${url}&${status}` : `&${status}`;
    }
    if(params?.min_price >= 0 && params?.max_price) {
      const price = `${priceData}=[${params?.min_price},${params?.max_price}]`
      url = url ? `${url}&${price}` : `&${price}`;
    }
    if(params?.min_size >= 0 && params?.max_size) {
      const size = `saleableArea${unit_type}=[${params?.min_size},${params?.max_size}]`
      url = url ? `${url}&${size}` : `&${size}`;
    }
    if(params?.sort === "price-asc") {
      const sort = `sort[0][Field]=${sortPrice}&sort[0][Order]=asc`
      url = url ? `${url}&${sort}` : `&${sort}`;
    }
    if(params?.sort === "price-desc") {
      const sort = `sort[0][Field]=${sortPrice}&sort[0][Order]=desc`
      url = url ? `${url}&${sort}` : `&${sort}`;
    }
    if(params?.sort === "size-asc") {
      const sort = `sort[0][Field]=saleable_area_${unit_type}&sort[0][Order]=asc`
      url = url ? `${url}&${sort}` : `&${sort}`;
    }
    if(params?.sort === "size-desc") {
      const sort = `sort[0][Field]=saleable_area_${unit_type}&sort[0][Order]=desc`
      url = url ? `${url}&${sort}` : `&${sort}`;
    }
    
    if(params?.page) {
        pageno = params?.page;
    }

    return { url : url, page : pageno };

}