import React, { useState, useEffect } from "react";
import { useRedirect, useTranslate } from "react-admin";
import {
  Grid,
  Container,
  Button
} from "@mui/material";
import { useForm } from "react-hook-form";
import Header from "Components/Header";
import BackButton from "Components/BackButton";
import Title from "Components/Title/Title";
import InputField from "Components/InputField";
import Loader from "Components/Loader";
import { getMemberById, addUpdateMember } from "Services/teamService";
import { emailPattern } from "Utils/regex-patterns";
import { titleValues, roleValues, roleCheck } from "Utils/constantValues";
import PhoneInputComponent from "Components/Common/PhoneInput";
import styles from "./styles.module.scss";
import { useLocation, useNavigate } from "react-router-dom";
import userDetails from "Services/userDetailsServices";
import Typography from "@mui/material/Typography";
import gtagEventFire from "Utils/ga4EventTrigger";
import { DOBDate } from "Utils/maxMinDate";
import DatePickerComponent from "Components/Common/DatePicker";
import { getDateFormat } from "Utils/dateFormat";
import AutoCompleteComponent from "Components/Common/AutoComplete";
import nationality from "Services/nationalityService";
 
const AddAndEditTeam = ({ notification }) => {
  const [formData, setFormData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [phoneErrors, setPhoneErrors] = useState(false);
  const [userData, setUserData] = useState(null);
  const [nationalityState, setNationalityState] = useState(null);
  const [nationalityList, setNationalityList] = useState([]);
  const [nationalityLoading, setNationalityLoading] = useState(false);
  const { register, handleSubmit, formState: { errors }, watch, setError, clearErrors, control } = useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const redirect = useRedirect();
  const translate = useTranslate();
  const param = new URLSearchParams(location?.search);
  const id = parseInt(param?.get("id")) || "";
  const role = parseInt(param?.get("role")) || "";

  const listingDetails = async() => {
    const userDetail = await userDetails(setLoading, setUserData, "", notification);
    if((userDetail?.user_type === 2 && userDetail?.role !== "sales_person")) {
      if(id) getMemberById(setLoading, id, role, setFormData, notification);
    } else {
      navigate("/home");
    }
  }

  useEffect(() => {
    listingDetails();
  }, [id])

  const changeHandler = (e) => {
    setFormData({...formData, [e.target.name] : e.target.value});
  }

  const mobileValidation = (
    number,
    fieldName = "mobile",
    message = translate("ra.validation.validMobile"),
  ) => {
    if (!number || number?.length < 6) {
      setError(fieldName, { type: "required", message: message });
    } else {
      clearErrors(fieldName);
      setPhoneErrors(false);
    }
  };

  const changeMobileHandler = (value, data) => {
    const mobile_no = value.slice(data.dialCode.length);
    const dialCode = data.dialCode;
    mobileValidation(mobile_no);
    setFormData({...formData, mobile : mobile_no, country_code : dialCode})
  };

  useEffect(() => {
    if (phoneErrors) {
      mobileValidation(formData?.mobile);
    }
  }, [phoneErrors]);

  const onSubmit = () =>{
    if(id) {
      addUpdateMember(setLoading, `/update-agency-user/${formData?.user_id}`, "put", formData, notification, navigate);
    } else {
      gtagEventFire("new_team_member_added", {
        user_email: userData?.email,
        new_member_email: formData?.email
      });
      addUpdateMember(setLoading, "/add-agency-user", "post", formData, notification, navigate);
    }
  }

  useEffect(() => {
    nationality(setNationalityList, setNationalityLoading, notification);
    document.body.classList.add( 
      'profile-page'      
    );
    document.body.classList.add( 
      'change-pass-page'      
    );
      return () => {
      document.body.classList.remove( 
        'profile-page'     
      );
      document.body.classList.remove( 
        'change-pass-page'     
      );
    };
  }, []);

  useEffect(() => {
    if (formData?.nationality) {
      const nation = nationalityList?.find(
        (nation) => nation.code === formData?.nationality
      );
      setNationalityState(nation);
    }
  }, [formData?.nationality, nationalityList]);

  return (
    <>
      <Header picture={userData?.profile} pictureLoading={loading} notification={notification} userData={userData} />
      {loading && nationalityLoading && <Loader />}
      {!loading &&
        <>
          <BackButton />
          <Container className="company-container new-fix-container add-edit-team-panel mt-0">
              <Container className="company-details">
                <Grid className="form-panel profile-form-panel add-team-panel" sx={{ width: "100%" }}>     
                <Typography variant="h2">{translate("ra.fieldName.memberDetails")} </Typography>
                  {/* Name */}
                  <Grid className="show-label team-form-dropdown" container spacing={0}>
                    <Title 
                      title={translate("ra.fieldName.prefix")}
                      name="title" 
                      dropdownValue={titleValues} 
                      register={register} 
                      className="add-team-prefix"
                      errors={errors} 
                      value={formData?.title || ""}
                      labelhide={true}
                      onChange={changeHandler}
                      message={translate("ra.validation.validPrefix")}
                    />
                  </Grid>
                  <Grid className="hide-label new-input" container spacing={0}>
                    <InputField 
                      name={translate("ra.fieldName.firstName")}
                      register={register} 
                      errors={errors} 
                      value={formData?.first_name || ""} 
                      state="first_name" 
                      onChange={changeHandler}
                      placeholderHide={true}
                      message={translate("ra.validation.validFirstname")}
                    />
                  </Grid>
                  <Grid className="hide-label new-input" container spacing={0}>
                    <InputField 
                      name={translate("ra.fieldName.lastName")} 
                      register={register} 
                      errors={errors} 
                      value={formData?.last_name || ""} 
                      state="last_name" 
                      onChange={changeHandler}
                      placeholderHide={true}
                      message={translate("ra.validation.validLastname")}
                    />
                  </Grid>

                  {/* Designation */}
                  <Grid className="new-input" container spacing={0}>
                    <InputField 
                      name={translate("ra.fieldName.designation")} 
                      register={register} 
                      errors={errors} 
                      value={formData?.designation || ""} 
                      state="designation" 
                      onChange={changeHandler}
                      placeholderHide={true}
                      message={translate("ra.validation.validDesignation")}
                    />
                  </Grid>

                  {/* Role */}
                  {roleCheck?.includes(formData?.role) ?
                    <Grid container className="team-form-dropdown" spacing={0}>
                      <InputField 
                        name={translate("ra.fieldName.role")} 
                        register={register} 
                        value={formData?.role_type || ""} 
                        state="role" 
                        errors={errors}
                        readOnly={true}
                      />
                    </Grid>
                  :
                    <Grid container className="team-form-dropdown" spacing={0}>
                      <Title
                        title={translate("ra.fieldName.role")}
                        name="role"
                        dropdownValue={roleValues}
                        register={register}
                        errors={errors}
                        watch={watch}
                        value={formData?.role || ""}
                        className="select-box"
                        labelhide={true}
                        onChange={changeHandler}
                        message={translate("ra.validation.vaidRole")}
                      />
                    </Grid>
                  }

                  {/* Nationality */}
                  <Grid className="new-select-class" container spacing={0}>
                   <AutoCompleteComponent
                      title={translate("ra.fieldName.nationality")}
                      value={nationalityState}
                      onChange={(e, newInputValue) => {
                        setNationalityState(newInputValue);
                        setFormData({...formData, nationality: newInputValue?.code})
                        clearErrors("nationality");
                      }}
                      options={nationalityList}
                      getOptionLabel={(option) => option?.name || ""}
                      name="nationality"
                      placeholder=""
                      errors={errors?.nationality}
                      register={register}
                    />
                  </Grid>

                  {/* DOB */}
                  <Grid container spacing={0}>
                   <DatePickerComponent
                      placeholder=""
                      title={translate("ra.fieldName.dob")}
                      control={control}
                      name="DOB"
                      onChangeHandler={(date) => changeHandler({
                        target : {name : "DOB", value : getDateFormat(date)}
                      })}
                      value={formData?.DOB}
                      maxDate={DOBDate}
                      errors={errors?.DOB}
                   />
                  </Grid>
     
                  {/* Mobile */}
                  <Grid container spacing={0}>
                    <PhoneInputComponent
                      title={translate("ra.fieldName.mobileNo")}
                      className={`${styles.mobileField} ${errors?.mobile?.message ? styles.errorBorder : ""}`}
                      value={[formData?.country_code, formData?.mobile].join()}
                      onChange={changeMobileHandler}
                      errors={errors?.mobile?.message}
                      readOnly = {false}
                      errorClass={styles.mobileError}
                      dropdownClass="phone-input-dropdown"
                    />
                  </Grid>

                  {/*  Email  */}
                  <Grid className="new-input" container spacing={0}>
                    <InputField 
                      name={translate("ra.fieldName.email")}
                      register={register} 
                      errors={errors} 
                      value={formData?.email || ""} 
                      state="email" 
                      type="email" 
                      message={translate("ra.validation.validEmailAdd")}
                      pattern={{
                        value: emailPattern,
                        message: translate("ra.validation.validEmailAdd"),
                      }}
                      onChange={changeHandler}
                      placeholderHide={true}
                    />
                  </Grid>

                  {/* Button */}
                  <Grid container className="company-details-button align-right" spacing={0}>                
                    <Button 
                      variant="contained"  
                      className="new-color-button"
                      onClick={() => {
                        handleSubmit(onSubmit)();
                        setPhoneErrors(true);
                      }}
                    >
                    {!id ? translate("ra.button.create") : translate("ra.button.update")}
                    </Button>
                  </Grid>
                </Grid>
              </Container>
            </Container>
        </>
      }
    </>
  );
};

export default AddAndEditTeam;