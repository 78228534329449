import React from "react";
import { useRedirect, useRecordContext } from "react-admin";
import {
    Grid,
    Button,
} from "@mui/material";
import ClickAwayListener from "@mui/base/ClickAwayListener";
import { roleCheck } from "Utils/constantValues";

const EditField = ({ 
    onClickFn, 
    showMenu, 
    userId, 
    clickAway, 
    className, 
    list, 
    updateTeamStatus,
    translate
}) => {
    const redirect = useRedirect();
    const record = list || useRecordContext();
    if (!record) return null;

    const redirectPath = (record) => {
        let role = "";
        let id = record?.user_id;

        if(record?.role_type === roleCheck[0]) {
            role = 1;
        } else if(record?.role_type === roleCheck[1]) {
            role = 2;
            id = record?.parent_id;
        }
        redirect(`/edit-team?id=${id}${role ? `&role=${role}` : ""}`)
    }

    return (
        <Button
            className={`button-position-class ${className}`}
            variant="button"
            onClick={() => onClickFn(record?.user_id)}
        >
            <i className="iconwasalt icon-three-dots" />
            {showMenu && (userId === record?.user_id) &&
                <ClickAwayListener onClickAway={clickAway}>
                    <Grid className="team-kebab-icon">
                        <Button
                            variant="button"
                            onClick={() => redirectPath(record)}
                        >
                            <i className="iconwasalt icon-edit-outline" /> {translate("ra.button.edit")}
                        </Button>
                        {!roleCheck?.includes(record?.role_type) &&
                            <Button
                                variant="button"
                                className="button-color"
                                onClick={() => updateTeamStatus(record)}
                            >
                                <i className="iconwasalt icon-change-status" /> 
                                    {record?.status === "Active" ? translate("ra.button.deActivate") : translate("ra.button.activate")}
                            </Button>
                        }
                    </Grid>
                </ClickAwayListener>
            }
        </Button>
    )
};

export default EditField;