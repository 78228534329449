import { apiServiceRequest } from "Utils/axiosInstance";
import { getUserToken } from "Utils/tokenProvider";
import { objectKeyRename, unitApiData, wishlistFilter } from "./unitsDataFormation";

export const getWishListData = async (setLoading, setData, setCount, setWishlistProject, userDetail, page, notify, limit, unloadPage = false, data, wishlistProject, setUnitLoading, callingDocApi, params) => {
    !unloadPage ? setLoading(true) : setUnitLoading(true)

    const unit_type = userDetail?.unit_type?.toUpperCase() || "";
    const currency = userDetail?.currency?.toUpperCase() || "";
    let query = {};
    
    if (params) {
        query = wishlistFilter(params, currency, unit_type, "unitlist");
    }

    const res = await apiServiceRequest({
        url: `wishlist?limit=${limit}${page > 1 ? `&page=${page - 1}` : ""}${query?.url ? query?.url : ""}`,
        headers: { Authorization: `Bearer ${await getUserToken(notify)}` },
        notify: notify
    });

    if(res?.code === "success") {
        const resData = unitApiData(res?.data?.objList, currency, userDetail, unit_type);
        const getUnitId = res?.data?.objList?.map((item, idx) => {
            return item.officE_SECTION_ID;
        })

        // loading next page data into current page if wishlist is deleted without loading page
        if(unloadPage && resData?.length) {
            const dataFilter = resData?.filter(list => !data?.some(val => val?.unit_id === list?.unit_id))
            const wishlistData = [...data, ...dataFilter];
            const projectIdArray = [
                ...new Set(wishlistData?.map((list) => list?.project_id)),
            ];

            setData(wishlistData)
            setWishlistProject([...wishlistProject, dataFilter[0]?.unit_id])
            callingDocApi(wishlistData, projectIdArray)
        }

        if(!unloadPage) {
            if(setData) setData(resData || []);
            if(setWishlistProject) setWishlistProject(getUnitId || [])
            if(setCount) setCount(res?.data?.count || 0)

            const projectIdArray = [
                ...new Set(resData?.map((list) => list?.project_id)),
            ];

            callingDocApi(resData, projectIdArray)
        }
    }
    !unloadPage ? setLoading(false) : setUnitLoading(false)
};

export const getWishListId = async (setLoading, projectId, setWishlistProject, notify) => {
    if(setLoading) setLoading(true)
    const res = await apiServiceRequest({
        url: `wishlist/project-id/${projectId}`,
        headers: { Authorization: `Bearer ${await getUserToken(notify)}` },
        notify: notify
    });

    const resData = res?.data?.map((item, idx) => {
        return item.unit_id;
    }) || [];

    if(setWishlistProject) setWishlistProject(resData || [])
    if(setLoading) setLoading(false)
    return resData;
};

export const assignWishList = async (notify, id, list, data, setData, setLoading) => {
    if(setLoading) setLoading(true);
    // filter price and size alone for passing it in wishlist
    const priceValues = objectKeyRename(list, "property_price", false);
    const sizeValues = objectKeyRename(list, "saleable_area", false);

    let payload = {
        unit_id: id,
        project_id: list?.project_id,
        location: list?.location,
        unit_type: list?.property_type,
        apartment_type: list?.bedroom,
        unit_status: list?.status,
        currency: list?.unitCurrency,
        ...priceValues,
        ...sizeValues
    }
    const res = await apiServiceRequest({
        url: "/wishlist",
        method: "post",
        data: payload,
        headers: { Authorization: `Bearer ${await getUserToken(notify)}` },
        notify: notify
    });

    if(res?.code === "success") {
       data && data.push(id)
       setData && setData(data);
        notify("ra.notification.wishlisted", { type: "success" } )
    } else {
        notify("ra.notification.wishlistExit", { type: "error" } )
    }
    if(setLoading) setLoading(false)
};

export const deleteWishList = async (notify, id, removeWishList, index, setLoading) => {
    if(setLoading) setLoading(true)
    const res = await apiServiceRequest({
        url: `/wishlist/${id}`,
        method: "delete",
        headers: { Authorization: `Bearer ${await getUserToken(notify)}` },
        notify: notify
    });

    if(res?.code === "success") {
        removeWishList && removeWishList(id, index);
    }
    if(setLoading) setLoading(false)
};

export const wishlistCount = async (
    setLoading, 
    setData, 
    userDetail, 
    params,
    notify
    ) => {
    setLoading(true)

    const unit_type = userDetail?.unit_type?.toUpperCase() || "";
    const currency = userDetail?.currency?.toUpperCase() || "";
    let query = {};
    
    if (params) {
        query = wishlistFilter(params, currency, unit_type, "unitlist");
    }

    const res = await apiServiceRequest({
        url: `wishlist/count?${query?.url ? query?.url.slice(1, query?.url?.length) : ""}`,
        headers: { Authorization: `Bearer ${await getUserToken(notify)}` },
        notify: notify
    });

    if(res?.code === "success") {
        const resData = res?.data?.count;
        setData(resData || 0);
    }
    setLoading(false)
};
