import React from "react";
import { Typography, MenuItem, FormControl, Select } from "@mui/material";
import { useTranslate } from "react-admin";

const SelectComponent = ({
  title,
  name,
  id,
  titleClass,
  titleAlign,
  formsx,
  defaultValue,
  register,
  readOnly,
  dropdownValue,
  optionExtra = false
}) => {
  const translate = useTranslate();

  return (
    <>
      <Typography
        className={titleClass}
        variant="subtitle1"
        align={titleAlign}
      >
        {title}
      </Typography>
      <FormControl fullWidth sx={formsx}>
        <Select
          id={id}
          defaultValue={defaultValue}
          variant="outlined"
          inputProps={{
            readOnly: readOnly,
          }}
          {...register(name)}
          IconComponent={() => <i className="iconwasalt icon-arrow-down" />}
          className="dropdown-icon-wrapper"
        >
          {optionExtra && 
            <MenuItem 
              value="NA"
            >
              {translate("ra.fieldName.NA")}
            </MenuItem>
          }
          {dropdownValue?.map((list, index) => {
            return (
              <MenuItem 
                key={index} 
                value={list?.value}
              >
                {list?.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </>
  );
};

export default SelectComponent;
