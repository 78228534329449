import React, { Fragment } from "react";
import { Grid, Typography, Stack, Button, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import isRead from "Utils/ui-lock-status";
import isInProcess from "Utils/ui-inprocess-status";
import { useTranslate } from "react-admin";
import ErrorComponent from "../ErrorComponent";

const AdditionalDocumentSection = ({
  setDeleteId,
  additionalDocumentsFiles,
  setAdditionalDocumentsFiles,
  register,
  length,
  os,
  loading,
  errors,
  uploadedDocLength,
  forAttachProof = false
}) => {
  const translate = useTranslate();

  const onClickDelete = (index) => {
    setAdditionalDocumentsFiles((prev) =>
      prev.filter((d, i) => {
        if (index === i) {
          if (d.id) {
            setDeleteId((prev) => [...prev, d.id]);
          }
        }
        return i !== index;
      })
    );
  };

  const hidden = (document) => {
    if (isRead(os) === true) {
      if (isInProcess(os) === true) {
        if (document?.isdelete === false) {
          return true;
        } else {
          return false;
        }
      } else {
        return true; //logout
      }
    } else {
      return false; //logout
    }
  };

  return (
    <>
      <Grid item xs={12}>
        {(!isRead(os) || (length > 0)) && (
          !forAttachProof && (
            <Typography className="form_label" variant="subtitle1" mb={1}>
              {translate("ra.fieldName.additionalDoc")}
            </Typography>
          )
        )}
        {additionalDocumentsFiles?.map((document, index) => {
          return (
            <Fragment key={index}>
              <Stack className="document-add-section">
                {!forAttachProof && 
                  <Typography className="form_label" variant="subtitle1" mb={1}>
                    {document?.doc_name}
                  </Typography>
                }
                <Stack className="document-add-panel">
                  <Typography className="form_label" variant="subtitle1" mb={1}>
                    <a target="blank" href={document?.doc_url}>
                      {document?.file_name}
                    </a>
                  </Typography>
                  <i
                    className={`iconwasalt icon-delete ${hidden(document) && "display-none"}`}
                    onClick={() => {
                      onClickDelete(index);
                    }}
                  ></i>
                </Stack>
              </Stack>
            </Fragment>
          );
        })}
        {length < 20 && (
          <>
            {!forAttachProof && 
              <TextField
                fullWidth
                sx={isRead(os) && !isInProcess(os) && { display: "none" }}
                variant="outlined"
                placeholder={translate("ra.placeholder.docName")}
                {...register("additionalDocumentName")}
              >
              </TextField>
            }
            <Button
              variant="contained"
              component="label"
              className="additional-doc-button"
              fullWidth
              sx={isRead(os) && !isInProcess(os) && { display: "none" }}
            >
              <i className="iconwasalt icon-add-file" />
              {translate("ra.fieldName.uploadAddDoc")}
              <input
                type="file"
                hidden
                multiple
                {...register("additionalDocuments", {
                  required:
                    isInProcess(os) &&
                    !loading &&
                    additionalDocumentsFiles?.length === uploadedDocLength &&
                    translate("ra.notification.attachmentError"),
                })}
                accept="application/pdf, image/png, image/jpeg , application/png, application/jpeg, application/pdf"
              />
            </Button>
          </>
        )}
      </Grid>
      {errors?.message && <ErrorComponent errors={errors?.message} />}
    </>
  );
};

export default AdditionalDocumentSection;