import React, { useState, useEffect } from "react";
import { useTranslate } from "react-admin";
import {
  Grid,
  Container,
  Button,
  Typography
} from "@mui/material";
import { useForm } from "react-hook-form";
import Header from "Components/Header";
import BackButton from "Components/BackButton";
import InputField from "Components/InputField";
import Loader from "Components/Loader";
import { emailPattern } from "Utils/regex-patterns";
import PhoneInputComponent from "Components/Common/PhoneInput";
import { useLocation, useNavigate } from "react-router-dom";
import DialogBox from "Components/DialogBox";
import { roleData } from "Utils/constantValues";
import { addUpdateLead, deleteLead, getLeadById, getSalesperson } from "Services/leadService";
import AutoCompleteComponent from "Components/Common/AutoComplete";

const LeadForm = ({ notification, userLoading, userData, leadType }) => {
  const [formData, setFormData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [saleLoading, setSaleLoading] = useState(false);
  const [phoneErrors, setPhoneErrors] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [salesPersonList, setSalesPersonList] = useState([]);
  const [salesPersonValue, setSalesPersonValue] = useState(null);
  const { register, handleSubmit, formState: { errors }, setError, clearErrors } = useForm();
  const location = useLocation();
  const navigate = useNavigate();
  const translate = useTranslate();
  const param = new URLSearchParams(location?.search);
  const id = parseInt(param?.get("id")) || "";

  useEffect(() => {
    getSalesperson(setSaleLoading, setSalesPersonList, notification, "form");

    document.body.classList.add(
      'profile-page'
    );
    document.body.classList.add(
      'change-pass-page'
    );
    document.body.classList.add(
      'show-header'
    );
    return () => {
      document.body.classList.remove(
        'profile-page'
      );
      document.body.classList.remove(
        'change-pass-page'
      );
      document.body.classList.remove(
        'show-header'
      );
    };
  }, []);

  useEffect(() => {
    if (id) {
      getLeadById(setLoading, id, setFormData, notification);
    }
  }, [id]);

  useEffect(() => {
    if (formData?.salesperson_id) {
      const salesId = salesPersonList?.find(list => list?.user_id === formData?.salesperson_id);
      setSalesPersonValue(salesId);
    }
  }, [formData?.salesperson_id, salesPersonList]);

  useEffect(() => {
    if (phoneErrors) {
      mobileValidation(formData?.mobile_no);
    }
  }, [phoneErrors]);

  const changeHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const mobileValidation = (
    number,
    fieldName = "mobile_no",
    message = translate("ra.validation.valid_mobile_no"),
  ) => {
    if (!number || number?.length < 6) {
      setError(fieldName, { type: "required", message: message });
    } else {
      clearErrors(fieldName);
      setPhoneErrors(false);
    }
  };

  const changeMobileHandler = (value, data) => {
    const mobile_no = value.slice(data.dialCode.length);
    const dialCode = data.dialCode;
    mobileValidation(mobile_no);
    setFormData({ ...formData, mobile_no: mobile_no, mobile_no_country_code: dialCode });
  };

  const onSubmit = () => {
    addUpdateLead(setLoading, id, salesPersonValue, formData, notification, navigate, leadType);
  }

  const leadDelete = () => {
    deleteLead(setLoading, id, notification, setDeleteSuccess, setDeletePopup);
  }

  return (
    <>
      <Header path="My Contacts" picture={userData?.profile} pictureLoading={userLoading} notification={notification} userData={userData} />
      {(loading || saleLoading) && <Loader cls="contact-delete-loader" />}
      <BackButton container_class="back-button-padding" />
      <Container className="company-container new-fix-container lead-panel-section lead-white-section contact-height-wrapper mt-0">
        <Container className="company-details">
          <Grid className="form-panel profile-form-panel add-team-panel lead-form-panel" sx={{ width: "100%" }}>
            <Typography variant="h2" className={!leadType ? "header-margin-wrapper" : ""}>
              {translate("ra.fieldName.prospectForm")}
            </Typography>
            {leadType && <Typography>{translate("ra.fieldName.passportInfo")}</Typography>}
            {!leadType &&
              <>
                <Typography variant="h4">
                  {translate("ra.fieldName.companyDetails")}
                </Typography>
                <Grid className="hide-label new-input" container spacing={0}>
                  <InputField
                    name={translate("ra.fieldName.companyName")}
                    register={register}
                    errors={errors}
                    value={formData?.company_name || ""}
                    state="company_name"
                    onChange={changeHandler}
                    placeholderHide={true}
                    message={translate("ra.validation.valid_company_name")}
                  />
                </Grid>
                <Grid className="hide-label new-input" container spacing={0}>
                  <InputField
                    name={translate("ra.fieldName.tradeNo")}
                    register={register}
                    errors={errors}
                    value={formData?.trade_license_number || ""}
                    state="trade_license_number"
                    onChange={changeHandler}
                    placeholderHide={true}
                    message={translate("ra.validation.valid_trade_no")}
                  />
                </Grid>
              </>
            }

            <Typography variant="h4" className={`${!leadType ? "mobile-pt24" : ""}`}>
              {!leadType
                ? translate("ra.fieldName.authorisedDetails")
                : translate("ra.fieldName.PersonalDetails")
              }
            </Typography>
            <Grid className="hide-label new-input" container spacing={0}>
              <InputField
                name={translate("ra.fieldName.firstName")}
                register={register}
                errors={errors}
                value={formData?.first_name || ""}
                state="first_name"
                onChange={changeHandler}
                placeholderHide={true}
                message={translate("ra.validation.valid_first_name")}
              />
            </Grid>
            <Grid className="hide-label new-input" container spacing={0}>
              <InputField
                name={translate("ra.fieldName.lastName")}
                register={register}
                errors={errors}
                value={formData?.last_name || ""}
                state="last_name"
                onChange={changeHandler}
                placeholderHide={true}
                message={translate("ra.validation.valid_last_name")}
              />
            </Grid>

            <Typography variant="h4" className="mobile-pt24">
              {translate("ra.fieldName.contactDetails")}
            </Typography>
            <Grid container spacing={0}>
              <PhoneInputComponent
                title={translate("ra.fieldName.mobileNo")}
                value={[formData?.mobile_no_country_code, formData?.mobile_no].join()}
                onChange={changeMobileHandler}
                errors={errors?.mobile_no?.message}
                className={`${errors?.mobile_no?.message ? "contact-mobile-error" : ""}`}
                readOnly={false}
              />
            </Grid>
            <Grid className="new-input" container spacing={0}>
              <InputField
                name={translate("ra.fieldName.emailAddress")}
                register={register}
                errors={errors}
                value={formData?.email || ""}
                state="email"
                type="email"
                message={translate("ra.validation.valid_email_address")}
                pattern={{
                  value: emailPattern,
                  message: translate("ra.validation.validEmailAdd"),
                }}
                onChange={changeHandler}
                placeholderHide={true}
              />
            </Grid>

            {leadType &&
              <>
                <Typography variant="h4" className="mobile-pt24">
                  {translate("ra.fieldName.idDetails")}
                </Typography>
                <Grid className="hide-label new-input" container spacing={0}>
                  <InputField
                    name={translate("ra.fieldName.passport")}
                    register={register}
                    errors={errors}
                    value={formData?.passport_number || ""}
                    state="passport_number"
                    onChange={changeHandler}
                    placeholderHide={true}
                    hide={true}
                    required={false}
                  />
                </Grid>
                <Grid className="hide-label new-input" container spacing={0}>
                  <InputField
                    name={translate("ra.fieldName.idPlaceIssue")}
                    register={register}
                    errors={errors}
                    value={formData?.place_id_issue || ""}
                    state="place_id_issue"
                    onChange={changeHandler}
                    placeholderHide={true}
                    hide={true}
                    required={false}
                  />
                </Grid>
              </>
            }
            {userData?.user_type === 2 && roleData.includes(userData?.role) &&
              <>
                <Typography variant="h4" className="mobile-pt24">
                  {translate("ra.fieldName.salesDetails")}
                </Typography>
                <Grid className="new-select-class salesperson-form-search" container spacing={0}>
                  <AutoCompleteComponent
                    title={translate("ra.fieldName.salesName")}
                    value={salesPersonValue}
                    onChange={(e, newInputValue) => {
                      setSalesPersonValue(newInputValue);
                    }}
                    options={salesPersonList}
                    getOptionLabel={(option) => option?.name || ""}
                    id="salesperson-search"
                    name="salesperson_id"
                    placeholder={translate("ra.placeholder.salespersonSearch")}
                    errors={errors?.salesperson_id}
                    register={register}
                    hide={true}
                  />
                </Grid>
              </>
            }
            {/* Button */}
            <Grid container className={`company-details-button align-right ${!id ? "full-width-button" : ""}`} spacing={0}>
              {id &&
                <Button
                  variant="outlined"
                  onClick={() => setDeletePopup(true)}
                >
                  {translate("ra.button.delete")}
                </Button>
              }
              <Button
                variant="contained"
                className="new-color-button"
                onClick={() => {
                  handleSubmit(onSubmit)();
                  setPhoneErrors(true);
                }}
              >
                {!id ? translate("ra.button.addProspect") : translate("ra.button.save")}
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Container>
      {deletePopup && (
        <DialogBox
          openPopup={deletePopup}
          setOpenPopup={setDeletePopup}
          content={translate("ra.content.deleteProspect")}
          handleYes={leadDelete}
          path="lead delete"
          dialogClass="lead-form-dialog"
        />
      )}
      {deleteSuccess && (
        <DialogBox
          openPopup={deleteSuccess}
          setOpenPopup={setDeleteSuccess}
          closeBtnHandler={() => {
            setDeleteSuccess(false);
            navigate("/contacts", { replace: true });
          }}
          content={translate("ra.content.prospectDeletedSuccess")}
          closeBtn={true}
          dialogClass="lead-form-dialog"
        />
      )}
    </>
  );
};

export default LeadForm;