import { apiServiceRequest } from "Utils/axiosInstance";
import { baseURL } from "Utils/baseUrl";
import { getUserToken } from "Utils/tokenProvider";

// Project List Service
const projectList = async (
  setProjectInterestedList,
  setProjectInterestedLoading,
  notify,
  multiple
) => {
  setProjectInterestedLoading(true);
  const res = await apiServiceRequest({
    url: `${baseURL}GetBrokerInterestedCountry`,
    headers: { Authorization: `Bearer ${await getUserToken(notify)}` },
    notify: notify,
  });
  if (res?.successStatus) {
    multiple
      ? setProjectInterestedList(
          res?.response?.map((entry) => entry?.project_City_Country)
        )
      : setProjectInterestedList(res?.response);
  }
  setProjectInterestedLoading(false);
};

export default projectList;
