import React, { useEffect, useState } from "react";
import "./global.css";
import { Admin, defaultTheme, CustomRoutes } from "react-admin";
import { Route, Navigate } from "react-router-dom";
import Roles from "Pages/Roles";
import Contactus from "Pages/ContactUs";
import RegisterAgency from "Pages/RegisterAgency";
import RegisterBroker from "Pages/RegisterBroker";
import authProvider from "Utils/authProvider";
import Login from "Pages/Login";
import theme from "Theme";
import PersonalDetails from "Pages/RegisterBroker/PersonalDetails";
import PersonalAgency from "Pages/RegisterAgency/PersonalDetails";
import CompanyPersonnel from "Pages/RegisterAgency/CompanyPersonnel";
import InformationSubmitted from "Pages/InformationSubmitted";
import CompanyUpload from "Pages/RegisterAgency/CompanyUpload";
import Upload from "Pages/RegisterBroker/Upload";
import BankDetails from "Pages/RegisterBroker/BankDetails";
import ContactDetails from "Pages/RegisterBroker/ContactDetails";
import ResetPassword from "Pages/ResetPassword";
import ForgotPassword from "Pages/ForgotPassword";
import { MyLayout } from "Pages/MyLayout/MyLayout";
import PublicRoute from "PublicRoute";
import ProtectedRoute from "ProtectedRoute";
import Home from "Pages/NewPages/Home/Home";
import UnitListing from "Pages/NewPages/UnitListing/UnitListing";
import ManageDarglobal from "Pages/NewPages/ManageDarglobal/ManageDarglobal";
import Profile from "Pages/NewPages/Profile/Profile";
import PropertyDetails from "Pages/NewPages/PropertyDetails/PropertyDetails";
import UnitlistingDetails from "Pages/NewPages/UnitlistingDetails/UnitlistingDetails";
import BDMDetails from "Pages/NewPages/BDMDetails";
import AddAndEditTeam from "Pages/NewPages/AddAndEditTeam";
import userDetails from "Services/userDetailsServices";
import { i18nProvider } from "Utils/i18nProvider";
import Error from "Pages/Error";
import ChangePassword from "Pages/NewPages/ChangePassword";
import CustomNotify from "Components/Common/CustomNotify";
import Contacts from "Pages/MyContacts/Contacts";
import LeadForm from "Pages/MyContacts/LeadForm";
import CustomerDetails from "Pages/MyContacts/CustomerDetails";
import MyBookings from "Pages/MyBooking/Booking";
import BookingData from "Pages/MyBooking/BookingData";
import { ReviewUnits } from "Pages/BookinFlow/ReviewUnits";
import TabStructure from "Pages/BookinFlow/TabStructure";
import RaiseInvoice from "Pages/MyBooking/RaiseInvoice";
import InvoiceSuccess from "Pages/MyBooking/RaiseInvoice/InvoiceSuccess";
import { TokenPaymentDetails } from "Pages/BookinFlow/TokenPaymentDetails";
import { DownloadReservationForm } from "Pages/BookinFlow/DownloadReservationForm";
import { ReservationSuccessfull } from "Pages/BookinFlow/ReservationSuccessfull";
import { UploadSignedReservationForm } from "Pages/BookinFlow/UploadSignedReservationForm";
import ReservationFormSubmitted from "Pages/BookinFlow/ReservationFormSubmitted";
import ChooseContacts from "Pages/BookinFlow/ChooseContacts";
import { AssignOwnerShipPercentage } from "Pages/BookinFlow/AssignOwnership";
import ReservationFormSigned from "Pages/BookinFlow/ReservationFormSigned";
import FailedDialogBox from "Pages/BookinFlow/FailedDialogBox";
import ReviewTabPage from "Pages/BookinFlow/TabStructure/ReviewTabPage";
import DocumentError from "Pages/Error/DocumentError";

const myTheme = {
  ...defaultTheme,
  ...theme,
};

function App() {
  const [loading, setLoading] = useState(false);
  const [oracle_status, setOracle_status] = useState(null);
  const user_type = JSON.parse(localStorage.getItem("user_type")) || "";
  const token = localStorage.getItem("token");
  const [textMsg, setTextMsg] = useState(null);
  const [userData, setUserData] = useState(null);
  const [bookingFailedPopup, setBookingFailedPopup] = useState(false);
  const url = new URL(window.location);
  const parse = url?.searchParams;
  const requestId = parse.get("randomId");

  // setting custom notification
  const notification = (text, type) => {
    setTextMsg({ text: text, ...type })
  }

  const setOracleStatus = (resData) => {
    resData?.oracle_status
      ? setOracle_status(resData?.oracle_status)
      : setOracle_status("-");
  };

  useEffect(() => {
    if (token && token?.token !== null && !requestId) {
      userDetails(setLoading, setUserData, setOracleStatus, notification);
    }

    // logging out user and navigate to reset password when logged in
    if (requestId) {
      window.localStorage.clear();
      return <Navigate to="/reset-password" />
    }
  }, [token]);

  // To block api calling without the token
  // and redirect based on status when there is no endpoints in url
  const redirectPage = () => {
    if (token) {
      if (oracle_status?.toLowerCase() === "active") {
        return <Navigate to="/home" />;
      } else if (user_type === 1) {
        return <Navigate to="/register-myself/personal" />;
      } else if (user_type === 2) {
        return <Navigate to="/register-agency/personal" />;
      }
    } else {
      return <Navigate to="/login" />;
    }
  };

  //calling notification commonly for all pages
  const notifyCall = () => {
    if (textMsg?.text) {
      return (
        <CustomNotify
          textMsg={textMsg}
          setTextMsg={setTextMsg}
        />
      )
    }
  }

  //calling custom error page
  const errorHandler = () => {
    return (
      <ProtectedRoute oracle_status={oracle_status}>
        <Error />
      </ProtectedRoute>
    )
  }

  const bookingFailHandler = () => {
    return (
      <FailedDialogBox
        openPopup={bookingFailedPopup}
        setOpenPopup={setBookingFailedPopup}
      />
    )
  }

  return (
    <Admin
      layout={MyLayout}
      dashboard={redirectPage}
      authProvider={authProvider}
      loginPage={
        <PublicRoute oracle_status={oracle_status}>
          <Login setOracle_status={setOracle_status} notification={notification} />
        </PublicRoute>
      }
      theme={myTheme}
      i18nProvider={i18nProvider}
      notification={bookingFailedPopup ? bookingFailHandler : notifyCall}
      catchAll={errorHandler}
    >
      <CustomRoutes noLayout>
        {/* <Route
          path="/roles"
          element={
            <PublicRoute oracle_status={oracle_status}>
              <Roles notification={notification} />
            </PublicRoute>
          }
        /> */}
        <Route
          path="/contact-us"
          element={
            <PublicRoute oracle_status={oracle_status}>
              <Contactus notification={notification} />
            </PublicRoute>
          }
        />
        <Route
          path="/information-submitted"
          element={
            <PublicRoute oracle_status={oracle_status}>
              <InformationSubmitted notification={notification} />
            </PublicRoute>
          }
        />
        <Route
          path="/forgot-password"
          element={
            <PublicRoute oracle_status={oracle_status}>
              <ForgotPassword notification={notification} />
            </PublicRoute>
          }
        />
        <Route
          path="/reset-password"
          element={
            <PublicRoute oracle_status={oracle_status}>
              <ResetPassword notification={notification} />
            </PublicRoute>
          }
        />
        <Route
          path="/register-myself"
          element={
            <PublicRoute oracle_status={oracle_status}>
              <RegisterBroker notification={notification} />
            </PublicRoute>
          }
        />
        <Route
          path="/register-agency"
          element={
            <PublicRoute oracle_status={oracle_status}>
              <RegisterAgency notification={notification} />
            </PublicRoute>
          }
        />
        <Route
          path="/register-myself/personal"
          element={
            <ProtectedRoute oracle_status={oracle_status}>
              <PersonalDetails notification={notification} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/register-myself/contact"
          element={
            <ProtectedRoute oracle_status={oracle_status}>
              <ContactDetails notification={notification} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/register-myself/bank"
          element={
            <ProtectedRoute oracle_status={oracle_status}>
              <BankDetails notification={notification} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/register-myself/upload"
          element={
            <ProtectedRoute oracle_status={oracle_status}>
              <Upload notification={notification} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/register-agency/personal"
          element={
            <ProtectedRoute oracle_status={oracle_status}>
              <PersonalAgency notification={notification} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/register-agency/personnel"
          element={
            <ProtectedRoute oracle_status={oracle_status}>
              <CompanyPersonnel notification={notification} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/register-agency/bank"
          element={
            <ProtectedRoute oracle_status={oracle_status}>
              <BankDetails pagePath="company" notification={notification} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/register-agency/upload"
          element={
            <ProtectedRoute oracle_status={oracle_status}>
              <CompanyUpload notification={notification} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/home"
          element={
            <ProtectedRoute oracle_status={oracle_status}>
              <Home notification={notification} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/unit-listing"
          element={
            <ProtectedRoute oracle_status={oracle_status}>
              <UnitListing notification={notification} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/wishlist"
          element={
            <ProtectedRoute oracle_status={oracle_status}>
              <UnitListing path="wishlist" notification={notification} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <ProtectedRoute oracle_status={oracle_status}>
              <Profile notification={notification} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/manage-darglobal"
          element={
            <ProtectedRoute oracle_status={oracle_status}>
              <ManageDarglobal notification={notification} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/wishlist-property-details"
          element={
            <ProtectedRoute oracle_status={oracle_status}>
              <UnitlistingDetails path="wishlist" notification={notification} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/property-details"
          element={
            <ProtectedRoute oracle_status={oracle_status}>
              <PropertyDetails notification={notification} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/unitlisting-details"
          element={
            <ProtectedRoute oracle_status={oracle_status}>
              <UnitlistingDetails notification={notification} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/bdm-details"
          element={
            <ProtectedRoute oracle_status={oracle_status}>
              <BDMDetails notification={notification} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/my-team"
          element={
            <ProtectedRoute oracle_status={oracle_status}>
              <ManageDarglobal notification={notification} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/add-team"
          element={
            <ProtectedRoute oracle_status={oracle_status}>
              <AddAndEditTeam notification={notification} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/edit-team"
          element={
            <ProtectedRoute oracle_status={oracle_status}>
              <AddAndEditTeam notification={notification} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/change-password"
          element={
            <ProtectedRoute oracle_status={oracle_status}>
              <ChangePassword notification={notification} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/contacts"
          element={
            <ProtectedRoute oracle_status={oracle_status}>
              <Contacts
                notification={notification}
                userLoading={loading}
                userData={userData}
              />
            </ProtectedRoute>
          }
        />
        <Route
          path="/individual-prospect-form"
          element={
            <ProtectedRoute oracle_status={oracle_status}>
              <LeadForm
                notification={notification}
                userLoading={loading}
                userData={userData}
                leadType="Individual"
              />
            </ProtectedRoute>
          }
        />
        <Route
          path="/company-prospect-form"
          element={
            <ProtectedRoute oracle_status={oracle_status}>
              <LeadForm
                notification={notification}
                userLoading={loading}
                userData={userData}
              />
            </ProtectedRoute>
          }
        />
        <Route
          path="/contact-details"
          element={
            <ProtectedRoute oracle_status={oracle_status}>
              <CustomerDetails
                notification={notification}
                userLoading={loading}
                userData={userData}
              />
            </ProtectedRoute>
          }
        />
        <Route
          path="/booking-history"
          element={
            <ProtectedRoute oracle_status={oracle_status}>
              <CustomerDetails
                notification={notification}
                userLoading={loading}
                userData={userData}
              />
            </ProtectedRoute>
          }
        />
        <Route
          path="/review-units"
          element={
            <ProtectedRoute oracle_status={oracle_status}>
              <ReviewUnits notification={notification} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/my-bookings"
          element={
            <ProtectedRoute oracle_status={oracle_status}>
              <MyBookings
                notification={notification}
                userLoading={loading}
                userData={userData}
              />
            </ProtectedRoute>
          }
        />
        <Route
          path="/booking-details"
          element={
            <ProtectedRoute oracle_status={oracle_status}>
              <BookingData 
                notification={notification} 
                userLoading={loading}
                userData={userData}
                bookingFailedPopup={bookingFailedPopup}
                setBookingFailedPopup={setBookingFailedPopup}
              />
            </ProtectedRoute>
          }
        />
        <Route
          path="/booking-reservation-form"
          element={
            <ProtectedRoute oracle_status={oracle_status}>
              <TabStructure
                notification={notification}
                bookingFailedPopup={bookingFailedPopup}
                setBookingFailedPopup={setBookingFailedPopup}
                path="form-book"
              />
            </ProtectedRoute>
          }
        />
        <Route
          path="/raise-invoice"
          element={
            <ProtectedRoute oracle_status={oracle_status}>
              <RaiseInvoice 
                notification={notification} 
                userLoading={loading}
                userData={userData}
              />
            </ProtectedRoute>
          }
        />
        <Route
          path="/choose-contacts"
          element={
            <ProtectedRoute oracle_status={oracle_status}>
              <ChooseContacts
                notification={notification}
                userLoading={loading}
                userData={userData}
              />
            </ProtectedRoute>
          }
        />

        <Route
          path="/invoice-success"
          element={
            <ProtectedRoute oracle_status={oracle_status}>
              <InvoiceSuccess 
                notification={notification} 
                userLoading={loading}
                userData={userData}
              />
            </ProtectedRoute>
          }
        />
        <Route
          path="/assign-ownership-percentage"
          element={
            <ProtectedRoute oracle_status={oracle_status}>
              <AssignOwnerShipPercentage
                notification={notification}
                bookingFailedPopup={bookingFailedPopup}
                setBookingFailedPopup={setBookingFailedPopup}
              />
            </ProtectedRoute>
          }
        />

        <Route
          path="/review-customer-information"
          element={
            <ProtectedRoute oracle_status={oracle_status}>
              <ReviewTabPage 
                notification={notification} 
                path="booking"
                bookingFailedPopup={bookingFailedPopup}
                setBookingFailedPopup={setBookingFailedPopup} 
              />
            </ProtectedRoute>
          }
        />

        <Route
          path="/contact-info"
          element={
            <ProtectedRoute oracle_status="active">
              <Contactus notification={notification} path="contactInfo" />
            </ProtectedRoute>
          }
        />
        <Route
          path="/token-payment-details"
          element={
            <ProtectedRoute oracle_status="active">
              <TokenPaymentDetails
                notification={notification}
                bookingFailedPopup={bookingFailedPopup}
                setBookingFailedPopup={setBookingFailedPopup}
              />
            </ProtectedRoute>
          }
        />
        <Route
          path="/download-reservation-form"
          element={
            <ProtectedRoute oracle_status="active">
              <DownloadReservationForm
                notification={notification}
                bookingFailedPopup={bookingFailedPopup}
                setBookingFailedPopup={setBookingFailedPopup}
              />
            </ProtectedRoute>
          }
        />
        <Route
          path="/reservation-successfull"
          element={
            <ProtectedRoute oracle_status="active">
              <ReservationSuccessfull
                notification={notification}
                bookingFailedPopup={bookingFailedPopup}
                setBookingFailedPopup={setBookingFailedPopup}
              />
            </ProtectedRoute>
          }
        />
        <Route
          path="/upload-reservation"
          element={
            <ProtectedRoute oracle_status="active">
              <UploadSignedReservationForm
                notification={notification}
                bookingFailedPopup={bookingFailedPopup}
                setBookingFailedPopup={setBookingFailedPopup}
              />
            </ProtectedRoute>
          }
        />
        <Route
          path="/reservation-form-submitted"
          element={
            <ProtectedRoute oracle_status={oracle_status}>
              <ReservationFormSubmitted
                notification={notification}
                userLoading={loading}
                userData={userData}
              />
            </ProtectedRoute>
          }
        />
        <Route
          path="/reservation-form-signed"
          element={
            <ProtectedRoute oracle_status={oracle_status}>
              <ReservationFormSigned notification={notification} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/document-not-found"
          element={
            <ProtectedRoute oracle_status={oracle_status}>
              <DocumentError />
            </ProtectedRoute>
          }
        />
      </CustomRoutes>
    </Admin>
  );
}

export default App;
