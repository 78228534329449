import { apiServiceRequest } from "Utils/axiosInstance";
import { baseURL } from "Utils/baseUrl";
import { getUserToken } from "Utils/tokenProvider";

//Bdm Details Service
const bdmDetails = async (setBdm, setBdmLoading, notify, bdmId) => {
  setBdmLoading(true);
  const res = await apiServiceRequest({
    url: `${baseURL}GetBDMDetailsbyID?BDM_ID=${bdmId}`,
    headers: { Authorization: `Bearer ${await getUserToken(notify)}` },
    notify: notify,
  });
  if (res?.successStatus) {
    setBdm(res?.response);
  }
  setBdmLoading(false);
};

export default bdmDetails;
