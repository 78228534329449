import axios from "axios";

const baseURL = process.env.REACT_APP_BASE_URL || "";

const apiService = axios.create({
  baseURL,
  headers: {
    "Content-type": "application/json",
  },
});

export const apiServiceRequest = (props) => {
  return request(props, apiService);
};

export const request = async (
  { method = "get", data = {}, params = null, url, headers, notify },
  instance
) => {
  try {
    const queryString = params || "";
    const res = await instance({
      method,
      params: queryString,
      url: `${url}`,
      data,
      headers
    });

    // when api throws error
    if (res.status === 400) {
      if(notify) notify("ra.apiError.tryLater", { type: "error" });
      return false;
    }

    // when user is unauthorized
    if (res.status === 401) {
      localStorage.clear();
      if(notify) notify("ra.apiError.logout", { type: "error" });
      setTimeout(() => {
        window.location.replace("/");
      }, 2000)
      return false;
    }

    // when user is rejected from oracle
    if(res.status === 403) {
      localStorage.clear();
      if(notify) notify("ra.apiError.accountRejected", { type: "error" });
      setTimeout(() => {
        window.location.replace("/");
      }, 2000)
      return false;
    }

    const response = res?.data;
    return response;
  } catch (err) {
    // when user is unauthorized
    if (err?.response?.status === 401) {
      localStorage.clear();
      if(notify) notify("ra.apiError.logout", { type: "error" });
      setTimeout(() => {
        window.location.replace("/");
      }, 2000)
      return false;
    }

    // when user is rejected from oracle
    if(err?.response?.status === 403) {
      localStorage.clear();
      if(notify) notify(err?.response?.data?.message, { type: "error" });
      setTimeout(() => {
        window.location.replace("/");
      }, 2000)
      return false;
    }

    // when api throws error
    if (notify) notify("ra.apiError.tryLater", { type: "error" });
  }
};
