
import { apiServiceRequest } from "Utils/axiosInstance";
import { baseURL } from "Utils/baseUrl";
import { getUserToken } from "Utils/tokenProvider";

const unitAvailabilityCheck = async (setLoading, notify, unitIds, setCustomerSelectionPopup, setFailedUnitsPopup, setFailedUnits, details, setDetails, deleteCode, setDeleteCode) => {
    setLoading(true);
    const res = await apiServiceRequest({
        url: `${baseURL}UnitStatus?Office_Section_ID=${unitIds}`,
        headers: { Authorization: `Bearer ${await getUserToken(notify)}` },
        notify: notify,
    });
    if (res?.successStatus) {
        const failedUnits = res?.response?.filter((units) => units?.unit_Status !== "AVAILABLE");
        if (failedUnits?.length > 0) {
            setFailedUnitsPopup(true);
            let failed = failedUnits?.filter((failed)=>{
                return details?.filter(units=> {
                    return (units?.officE_SECTION_ID == failed?.office_Section_ID)
                })
            })
            let failedCode = failed?.map(unit => unit?.location_Code);
            const failedId = failed?.map(unit => unit?.office_Section_ID);
            setFailedUnits(failedCode);
            setDetails(details?.filter((unit) => !failedId?.includes(unit?.officE_SECTION_ID)));
            setDeleteCode([...deleteCode, ...failedCode]);
        } else {
            setCustomerSelectionPopup(true);
        }
    }
    setLoading(false);
};

export default unitAvailabilityCheck;
