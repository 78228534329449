import React, { useEffect, useState } from "react";
import { useTranslate, useRemoveFromStore } from "react-admin";
import { useLocation, useNavigate } from "react-router-dom";
import { useMediaQuery, Typography, Button } from "@mui/material";
import Header from "Components/Header";
import Container from "@mui/material/Container";
import Popup from "Components/Popup/Popup";
import BackButton from "Components/BackButton";
import Loader from "Components/Loader";
import WishlistdetailsImage from "Components/WishlistdetailsImage";
import PropertydetailsInfo from "Components/PropertydetailsInfo";
import PropertydetailsAmenities from "Components/PropertydetailsAmenities";
import PropertydetailsPaymentinfo from "Components/PropertydetailsPaymentinfo";
import PropertydetailsLocation from "Components/PropertydetailsLocation";
import PropertydetailsDownloadinfo from "Components/PropertydetailsDownloadinfo";
import {
  brokerAmenities,
  brokerDocuments,
} from "Services/amenitiesAndBrochureService";
import userDetails from "Services/userDetailsServices";
import { unitDetails } from "Services/unitService";
import {
  assignWishList,
  deleteWishList,
  getWishListId,
} from "Services/wishlistService";
import paymentPlanService from "Services/paymentPlansService";
import bdmDetails from "Services/bdmDetailService";
import brokerSaleOfferService from "Services/brokerSaleOffer";
import { currencyStatus } from "Utils/currencyCheck";
import { Grid } from "@material-ui/core";
import { ReactComponent as ShareIcon } from "images/share-icon2.svg";
import { ReactComponent as Heart } from "images/wishlist-icon2.svg";
import { unitDetailsShare } from "Utils/documentFormation";
import { brokerUnitLayout } from "Services/unitLayoutService";
import { unitApiData } from "Services/unitsDataFormation";
import { capitalFirstLetterOfWord } from "Utils/commonFunctions";
import { ReactComponent as HeartActive } from "images/wishlist-active-icon.svg";
import gtagEventFire from "Utils/ga4EventTrigger";
import Error from "Pages/Error";
import { removeLocalStorage } from "Utils/removeStorage";

const UnitListingDetails = ({ path, notification }) => {
  const [unitDetailsLoading, setUnitDetailsLoading] = useState(true);
  const [userDetailsLoading, setuserDetailsLoading] = useState(true);
  const [saleOfferLoading, setSaleOfferLoading] = useState(false);
  const [bdmLoading, setBdmLoading] = useState(false);
  const [bdm, setBdm] = useState();
  const [loading, setLoading] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [wishlistProject, setWishlistProject] = useState([]);
  const [amenities, setAmenities] = useState([]);
  const [paymentPlanLoading, setPaymentPlanLoading] = useState();
  const [paymentPlan, setPaymentPlan] = useState();
  const [unitPlanDocument, setUnitPlanDocument] = useState([]);
  const [unitPlanLoading, setUnitPlanLoading] = useState(false);
  const [formData, setFormData] = useState();
  const [documents, setDocuments] = useState([]);
  const [documentList, setDocumentList] = useState([]);
  const [amenitiesLoading, setAmenitiesLoading] = useState(false);
  const [documentsLoading, setDocumentsLoading] = useState(false);
  const [details, setDetails] = useState();
  const [saleOffer, setSaleOffer] = useState([]);
  const [documentArray, setDocumentArray] = useState([]);

  const location = useLocation();
  const translate = useTranslate();
  const navigate = useNavigate();
  const remove = useRemoveFromStore();
  const param = new URLSearchParams(location?.search);
  const unitId = param?.get("unitid") || "";

  const untiType = formData?.unit_type?.toUpperCase();
  const currency = formData?.currency;
  const priceValue = currencyStatus(currency)
    ? details?.[`propertY_PRICE_${currency}`]
    : details?.[`propertY_PRICE`];
  const sizeValue = details?.[`saleablE_AREA_${untiType}`];
  const currencyValue = currencyStatus(currency)
    ? currency
    : details?.currency;

  const callAmenitiesAndPaymentPlans = (id, resData) => {
    brokerAmenities(setAmenities, setAmenitiesLoading, notification, id);
    brokerDocuments(
      setDocuments,
      setDocumentsLoading,
      notification,
      id,
      resData?.officE_SECTION_CODE
    );
    brokerUnitLayout(
      setUnitPlanDocument,
      setUnitPlanLoading,
      notification,
      unitId,
      resData?.officE_SECTION_CODE,
      resData?.projecT_NAME,
      resData?.projecT_ID
    );
    paymentPlanService(setPaymentPlan, setPaymentPlanLoading, notification, id);
    getWishListId(setLoading, id, setWishlistProject, notification);
    brokerSaleOfferService(
      setSaleOffer,
      setSaleOfferLoading,
      notification,
      unitId,
      resData?.officE_SECTION_CODE,
      resData?.projecT_NAME,
      resData?.projecT_ID
    );
  };

  const handleShare = () => {
    setOpenPopup(true);
  };

  const handleBook = () => {
    removeLocalStorage();
    localStorage.removeItem("lockIds");
    remove("deleteId");
    remove("deleteCode");
    navigate("/review-units", { state: { id: unitId, no: [details?.officE_SECTION_CODE], page: path === "wishlist" ? "wishlist-details" : "unit-details", 
    params: { country: details?.location, projectid: details?.projecT_ID, id: unitId, no: [details?.officE_SECTION_CODE] }, project_id: details?.projecT_ID } });
  }

  const matches = useMediaQuery("(max-width:1023px)");

  const setBdmDetails = (resData) => {
    bdmDetails(
      setBdm,
      setBdmLoading,
      notification,
      resData?.business_development_manager
    );
  };


  const wishlistHandler = () => {
    const selectedWishlist = [...wishlistProject];
    const index = selectedWishlist.indexOf(unitId);
    if (wishlistProject?.includes(unitId)) {
      deleteWishList(notification, unitId, removeWishList, index)
    } else {
      gtagEventFire("move-to-wishlist", {
        property_name: details?.projecT_NAME,
        unit_id: details?.officE_SECTION_CODE,
        user_email: formData?.email
      });
      const listData = unitApiData([details], currency, formData, untiType);

      assignWishList(
        notification,
        unitId,
        ...listData,
        selectedWishlist,
        setWishlistProject
      );
    }

  };

  const removeWishList = (unitId, index) => {
    if (index >= 0) {
      const selectedWishlist = [...wishlistProject];
      selectedWishlist.splice(index, 1);
      setWishlistProject(selectedWishlist);
    }
    notification("ra.notification.unitRemove", {
      type: "success",
    });
  };

  useEffect(() => {
    localStorage?.removeItem("params");
    userDetails(setuserDetailsLoading, setFormData, setBdmDetails, notification);
    if (unitId) {
      unitDetails(
        setUnitDetailsLoading,
        setDetails,
        notification,
        unitId,
        callAmenitiesAndPaymentPlans
      );
    } else {
      setUnitDetailsLoading(false);
      setuserDetailsLoading(false);
      setSaleOfferLoading(false);
    }
  }, []);

  const docArr = () => {
    const doc = [
      ...unitPlanDocument,
      ...documents,
      ...saleOffer
    ];

    const shareUnitDoc = unitDetailsShare(details, formData, doc);
    setDocumentArray(shareUnitDoc);
    setDocumentList(doc);
  };

  useEffect(() => {
    if (documents || saleOffer || unitPlanDocument) {
      docArr();
    }
  }, [documents, saleOffer, unitPlanDocument]);

  let apartmentType =
    `${details?.apartmenT_TYPE.slice(1)}` == "BR"
      ? `${details?.apartmenT_TYPE.charAt(0)} bedroom`
      : `${details?.apartmenT_TYPE}`;
  let unitName = `${apartmentType} ${details?.uniT_TYPE} in ${details?.projecT_NAME}`;
  unitName = capitalFirstLetterOfWord(unitName);

  const clickHandler = (type) => {
    gtagEventFire(`unit_${type}_click`, {
      property_name: details?.projecT_NAME,
      unit_id: details?.officE_SECTION_CODE,
      user_email: formData?.email
    });
  }

  return (
    <>
      {(unitDetailsLoading ||
        userDetailsLoading ||
        amenitiesLoading ||
        paymentPlanLoading ||
        loading ||
        bdmLoading ||
        documentsLoading ||
        unitPlanLoading ||
        saleOfferLoading) && <Loader />}
      {!unitDetailsLoading &&
        !userDetailsLoading &&
        !amenitiesLoading &&
        !paymentPlanLoading &&
        !loading &&
        !bdmLoading &&
        !documentsLoading &&
        !unitPlanLoading &&
        !saleOfferLoading && (
          <>
            {!details ? (
              <Error />
            ) : (
              <>
                <Header
                  path={path}
                  picture={formData?.profile}
                  pictureLoading={userDetailsLoading}
                  notification={notification}
                  userData={formData}
                  pagePath="details_page"
                />
                <BackButton />
                <Container className="project-details mt-0">
                  <Grid
                    container
                    className="project-details-new unit-details-page mt-0"
                    spacing={3}
                  >
                    <Grid item xs={12} md={8}>
                      <WishlistdetailsImage
                        unitNumber={details?.officE_SECTION_CODE}
                        type={details?.uniT_TYPE}
                        imageType={matches ? "mobile_ImageURL" : "imageURL"}
                        handleShare={handleShare}
                        images={details?.images}
                        country={details?.location}
                        city={details?.city || ""}
                        unitName={unitName}
                        wishlist={wishlistProject.includes(unitId) && true}
                        wishlistHandler={wishlistHandler}
                        showArrows="true"
                        documents={documentArray}
                        currency={currencyValue}
                        price={priceValue}
                        area={sizeValue}
                        translate={translate}
                      />
                      <PropertydetailsInfo
                        unit_number={details?.officE_SECTION_CODE}
                        type={details?.uniT_TYPE}
                        bedroom={details?.apartmenT_TYPE}
                        total_area={sizeValue}
                        internal_area={details?.[`titlE_DEED_AREA_${untiType}`]}
                        status={details?.uniT_STATUS}
                        unit_type={formData?.unit_type}
                        currency={currencyValue}
                        price={priceValue}
                        balcony_area={details?.[`balconY_AREA_${untiType}`]}
                        completion_date={details?.completioN_DATE}
                        translate={translate}
                      />
                      {amenities?.length > 0 && (
                        <PropertydetailsAmenities
                          amenities={amenities}
                          translate={translate}
                        />
                      )}
                      {paymentPlan?.length > 0 && (
                        <PropertydetailsPaymentinfo
                          paymentPlan={paymentPlan}
                          unitPrice={priceValue}
                          currency={currencyValue}
                          translate={translate}
                        />
                      )}
                      {documentArray?.length > 0 && (
                        <PropertydetailsDownloadinfo
                          documents={documentList}
                          bdm={bdm}
                          unit_type={formData?.unit_type}
                          unit_id={formData?.officE_SECTION_ID}
                          currency={currencyValue}
                          startingArea={sizeValue}
                          startingPrice={priceValue}
                          location={details?.location}
                          city={details?.city}
                          unitNo={details?.officE_SECTION_CODE}
                          translate={translate}
                          formData={formData}
                        />
                      )}
                      {details?.location && (
                        <PropertydetailsLocation
                          location={details?.location}
                          city={details?.city}
                          latitude={details?.latitude}
                          longitude={details?.longitude}
                          name={details?.projecT_NAME}
                          translate={translate}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Grid className="property-fixed-info">
                        <Typography className="top-panel" component="div">
                          <Button
                            variant="outlined"
                            startIcon={
                              wishlistProject.includes(unitId) ? (
                                <HeartActive />
                              ) : (
                                <Heart />
                              )
                            }
                            onClick={() => wishlistHandler()}
                          >
                            {translate("ra.button.save")}
                          </Button>
                          <Button
                            variant="outlined"
                            onClick={() => setOpenPopup(true)}
                            startIcon={<ShareIcon />}
                          >
                            {translate("ra.button.share")}
                          </Button>
                        </Typography>
                        <Typography className="bottom-panel" component="div">
                          <Typography className="text-panel" component="div">
                            <Typography component="h3">
                              {currencyValue}
                              <span>
                                &nbsp;
                                {new Intl.NumberFormat("en-US").format(
                                  Math.round(priceValue))
                                }
                              </span>
                            </Typography>
                          </Typography>
                          <Typography
                            className="share-link-info"
                            component="div"
                          >
                            <ul>
                              <li className="display-none">
                                <i className="iconwasalt icon-comment"></i>
                              </li>
                              <li onClick={() => clickHandler("call")}>
                                <a
                                  href={`tel: ${bdm?.bdM_PHONE_NUMBER || "+971 4 247 8990"
                                    }`}
                                >
                                  <i className="iconwasalt icon-call-outline"></i>{" "}
                                  {translate("ra.fieldName.call")}
                                </a>
                              </li>
                              <li onClick={() => clickHandler("email")}>
                                <a
                                  href={`mailto: ${bdm?.emaiL_ADDRESS ||
                                    "agents@darglobal.co.uk"
                                    }`}
                                >
                                  <i className="iconwasalt icon-email"></i>{" "}
                                  {translate("ra.fieldName.email")}
                                </a>
                              </li>
                            </ul>
                          </Typography>
                          <Button variant="contained" onClick={() => handleBook()}>{translate("ra.fieldName.book")}</Button>
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid className="mobile-fixed-bottom-section">
                      <Typography className="share-link-info" component="div">
                        <ul>
                          <li>
                            <a
                              href={`tel: ${bdm?.bdM_PHONE_NUMBER || "+971 4 247 8990"
                                }`}
                            >
                              <i className="iconwasalt icon-call-outline"></i> Call
                            </a>
                          </li>
                          <li>
                            <a
                              href={`mailto: ${bdm?.emaiL_ADDRESS || "agents@darglobal.co.uk"
                                }`}
                            >
                              <i className="iconwasalt icon-email"></i> Email
                            </a>
                          </li>
                        </ul>
                      </Typography>
                      <Button className="mobile-fixed-button" variant="button" onClick={() => handleBook()}>
                        {translate("ra.fieldName.book")}
                      </Button>
                    </Grid>
                  </Grid>
                  {openPopup && (
                    <Popup
                      openPopup={openPopup}
                      setOpenPopup={setOpenPopup}
                      documentArray={documentArray}
                      type="multiple"
                      unitNo={details?.officE_SECTION_CODE}
                      email={formData?.email}
                      projectName={details?.projecT_NAME}
                    />
                  )}
                </Container>
              </>
            )}
          </>
        )}
    </>
  );
};

export default UnitListingDetails;
