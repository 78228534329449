import { apiServiceRequest } from "Utils/axiosInstance";
import { baseURL } from "Utils/baseUrl";
import { getQueryFromParams } from "Utils/queryValues";
import { getUserToken } from "Utils/tokenProvider";
import { unitApiData } from "./unitsDataFormation";
import { getWishListId } from "./wishlistService";

export const unitListDetails = async (
  setLoading,
  setData,
  setCount,
  userDetail,
  location,
  notify,
  setWishlistProject,
  callingDocApi,
  unit_id,
  setFullData,
  setFailedUnits,
  unit_no,
  setFailedUnitsPopup,
  pagePath,
  deleteCode,
  setDeleteCode
) => {
  setLoading(true);
  let query = {};

  const unit_type = userDetail?.unit_type?.toUpperCase() || "";
  const currency = userDetail?.currency?.toUpperCase() || "";
  const officE_SECTION_ID = unit_id;

  if (location) {
    query = getQueryFromParams(location, currency, unit_type, "unitlist", officE_SECTION_ID);
  }

  /* eslint-disable */
  const res = await apiServiceRequest({
    url: `${baseURL}GetBrokerUnits?jsonParam=${btoa(
      JSON.stringify({
        Limit: 12,
        Page: pagePath ? 1 : parseInt(query?.page) || 1,
        Conditions: query?.conditions || [],
        Sorts: query?.sort || [],
      })
    )}`,
    headers: { Authorization: `Bearer ${await getUserToken(notify)}` },
    notify: notify,
  });

  if (res?.successStatus) {
    setFullData && setFullData(res?.response?.objList);
    const resData = unitApiData(res?.response?.objList, currency, userDetail, unit_type);
    setData && setData(resData || []);
    setCount && setCount(res?.response?.totalNoOfRecords || 0);

    if(pagePath) {
      if (res?.response?.totalNoOfRecords === 0) {
        const non_delete_unit = unit_no?.filter(unit => !deleteCode?.includes(unit));
        setDeleteCode([...deleteCode, ...non_delete_unit]);

        if (non_delete_unit?.length > 0) {
          setFailedUnitsPopup && setFailedUnitsPopup(true);
          setFailedUnits && setFailedUnits([...non_delete_unit]);
        }
      } else {
        const units = res?.response?.objList?.map(unit => unit?.officE_SECTION_CODE);
        const non_delete_unit = unit_no?.filter(unit => !deleteCode?.includes(unit));
        const unit_array = non_delete_unit?.filter(unit => !units?.includes(unit));
        setDeleteCode([...deleteCode, ...unit_array]);

        if (unit_array?.length > 0) {
          setFailedUnitsPopup && setFailedUnitsPopup(true);
          setFailedUnits && setFailedUnits([...unit_array]);
        }
      }
    }

    // getting project id and calling wishlist api to get all unit id which is wishlisted
    if (resData?.length) {
      const projectIdArray = [];
      let responseArray = await Promise.all(
        projectIdArray.map(async (id, idx) => {
          let response = await getWishListId("", id, "", notify);
          return response || [];
        })
      );
      setWishlistProject && setWishlistProject(responseArray.flat() || []);
      callingDocApi && callingDocApi(resData, projectIdArray);
    }
  }
  setLoading(false);
};

export const unitDetails = async (
  setLoading,
  setData,
  notify,
  id,
  callAmenities
) => {
  setLoading(true);
  /* eslint-disable */
  const res = await apiServiceRequest({
    url: `${baseURL}GetBrokerUnits?jsonParam=${btoa(
      JSON.stringify({
        Conditions: [
          {
            Field: "officE_SECTION_ID",
            Value: `${id}`,
            Filter: "equal",
          },
        ],
      })
    )}`,
    headers: { Authorization: `Bearer ${await getUserToken(notify)}` },
    notify: notify,
  });

  if (res?.successStatus) {
    const resData = res?.response?.objList;

    if (resData?.length) {
      setData(resData[0]);
      if (callAmenities) {
        callAmenities(resData[0]?.projecT_ID, resData[0]);
      }

    }
  }
  setLoading(false);
};

export const unitListCount = async (
  setLoading,
  setData,
  userDetail,
  location,
  notify
) => {
  setLoading(true);
  let query = {};

  const unit_type = userDetail?.unit_type?.toUpperCase() || "";
  const currency = userDetail?.currency?.toUpperCase() || "";

  if (location) {
    query = getQueryFromParams(location, currency, unit_type, "unitlist");
  }

  /* eslint-disable */
  const res = await apiServiceRequest({
    url: `${baseURL}GetBrokerUnitsCount?jsonParam=${btoa(
      JSON.stringify({
        Conditions: query?.conditions || []
      })
    )}`,
    headers: { Authorization: `Bearer ${await getUserToken(notify)}` },
    notify: notify,
  });

  if (res?.successStatus) {
    const resData = res?.response?.totalNoOfRecords;
    setData(resData || 0);
  }
  setLoading(false);
};
