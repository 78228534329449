import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Styles from "./PropertydetailsPaymentinfo.scss";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { paymentPlanFunction } from "Utils/paymentPlanFunction";

const PropertydetailsPaymentinfo = ({ paymentPlan, currency, unitPrice, translate }) => {

  return (
    <>
      <Grid className="project-info-panel">
          <Typography variant="h2">{translate("ra.fieldName.paymentPlan")}</Typography>
          <TableContainer className="payment-table">
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>{translate("ra.fieldName.installment")}</TableCell>
                  <TableCell>%</TableCell>
                  <TableCell>{translate("ra.fieldName.price")}{currency && `(${currency})`}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {paymentPlan?.map((payment,index)=>{
                  return(
                    <TableRow key={index}>
                    <TableCell> {payment?.tasK_NUMBER}
                      <span>{payment?.duE_DATE?.split(" ")[0]}</span></TableCell>
                    <TableCell>{payment?.percentage || "-"}%</TableCell>
                    <TableCell>{paymentPlanFunction(unitPrice,payment?.percentage) || "-"}</TableCell>
                  </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
    </>
  );
};

export default PropertydetailsPaymentinfo;
