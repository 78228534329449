import { apiServiceRequest } from "Utils/axiosInstance";
import { baseURL } from "Utils/baseUrl";
import { getUserToken } from "Utils/tokenProvider";

//Bdm List Service
const bdmList = async (setBdmList, setBdmListLoading, notify) => {
  setBdmListLoading(true);
  const res = await apiServiceRequest({
    url: `${baseURL}GetBDMDetails`,
    headers: { Authorization: `Bearer ${await getUserToken(notify)}` },
    notify: notify,
  });

  if (res?.successStatus) {
    let resData = res?.response?.map(list => {
      return {
        name: list?.employee_Name,
        value: list?.person_ID
      }
    })
    setBdmList(resData || []);
  }
  setBdmListLoading(false);
};

export default bdmList;
