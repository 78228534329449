import React, { useState, useEffect, useCallback } from "react";
import {
    Grid,
    Button,
    Typography,
    Container,
    useMediaQuery
} from "@mui/material";
import {
    useTranslate
} from "react-admin";
import ClickAwayListener from "@mui/base/ClickAwayListener";
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import CommonFilter from "./CommonFilter";
import { useNavigate } from "react-router-dom";
import { createFilterQuery } from "Utils/queryValues";
import { debounceFunction } from "Utils/debounceFunction";

const Search = styled('div')(({ theme }) => ({}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({}));

const FilterSearch = ({ 
    filterList, 
    setFilterList, 
    searchValue, 
    setSearchValue, 
    pageName, 
    setShowSelectContact,
    salesPersonList,
    setContactLoading,
    userData,
    path,
    detailsApi,
    setPage,
    setHasMore,
    params,
    tabChange
}) => {
    const [showFilter, setShowFilter] = useState(false);
    const [openDrop, setOpenDrop] = useState(false);
    const matches = useMediaQuery("(max-width:800px)");
    const translate = useTranslate();
    const navigate = useNavigate();

    const searchHandler = (e) => {
        setSearchValue(e.target.value);
        setContactLoading(true);
        setFilterList([]);
        searchDebounce(e.target.value, tabChange);
    }

    const clearSearchFilter = () => {
        setSearchValue("");
        apiCallwithParams([], "", tabChange);
    }

    const searchDebounce = useCallback(
        debounceFunction(async function (value, tab) {
            apiCallwithParams([], value, tab);
        }, 600), []
    )

    const filterRemoveHandler = (idx) => {
        const data = [...filterList];
        data?.splice(idx, 1);
        setFilterList(data);
        apiCallwithParams(data, null, tabChange);
    }

    const handleClickAway = () => {
        if (!openDrop) {
            setShowFilter(false);
        }
    };

    // when filter is opened in mobile, background scrolling is blocked
    useEffect(() => {
        if (matches) {
            if (!showFilter) {
                document.body.style.overflow = "auto"
            } else {
                document.body.style.overflow = "hidden"
            }
        }
    }, [showFilter])

    // setting filter section sticky when scroll
    useEffect(() => {
        setSearchValue(params?.search || "");

        let searchId = document.getElementById("common-filter-id");
        let filterId = document.getElementById("filter-section-id");
        
        window.addEventListener("scroll", function () {
            if(!pageName) {
                if(matches) {
                    if (document.documentElement.scrollTop >= 50) {
                        searchId.classList.add("contact-position-sticky");
                    } else {
                        searchId.classList.remove("contact-position-sticky");
                    }
                }
                if(!matches) {
                    if (document.documentElement.scrollTop >= 1) {
                        filterId.classList.add("filter-contact-section-sticky");
                    } else {
                        filterId.classList.remove("filter-contact-section-sticky");
                    }
                }
            }
        });
    }, [])

    const apiCallwithParams = (filterData, searchField, tabName) => {
        const filterUrl = createFilterQuery(filterData, searchField, path, params);
        navigate({
        pathname: pageName ? `/${path}` : "/contacts",
        search: filterUrl?.url,
        hash: "#"
        }, {replace: true});
        detailsApi(setContactLoading, 0, filterUrl?.params, "", "", "", tabName);
        setPage(0);
        setHasMore(true);
    }

    return (
        <Container className="filter-panel">
            <Grid id="filter-section-id" className="form-filter-withbutton">
                <Grid id="common-filter-id" className="form-section">
                    <Typography className="filters search-section" component="div">
                        <Search className="search">
                            <i className="iconwasalt icon-search-new" />
                            <StyledInputBase
                                placeholder={pageName === "booking" ? translate("ra.fieldName.projectSearch") : translate("ra.fieldName.nameSearch")}
                                value={searchValue}
                                inputProps={{ 'aria-label': 'search' }}
                                onChange={searchHandler}
                                className={searchValue ? "search-value-input" : ""}
                            />
                            {searchValue && <i onClick={clearSearchFilter} className="iconwasalt icon-close close-search-icon" />}
                        </Search>
                    </Typography>
                    <Grid className="filters-section">
                        <Grid className="filters-total-section">
                            <Typography className="filters filters-button" component="div" onClick={() => setShowFilter(!showFilter)} >
                                <Button variant="text" ><i className="clickble-icon iconwasalt icon-filter-new" /> {translate("ra.fieldName.filter")}
                                    <i className={`clickble-icon iconwasalt icon-arrow-down v-arrow ${showFilter ? "rotate-filter-icon" : ""}`} />
                                </Button>
                                {filterList?.length > 0 && <span className="notification filter-length-icon">{filterList?.length}</span>}
                            </Typography>
                            {showFilter &&
                                <ClickAwayListener onClickAway={handleClickAway}>
                                    <div>
                                        <CommonFilter
                                            setFilterList={setFilterList}
                                            setShowFilter={setShowFilter}
                                            salesPersonList={salesPersonList}
                                            setSearchValue={setSearchValue}
                                            setOpenDrop={setOpenDrop}
                                            apiCallwithParams={apiCallwithParams}
                                            translate={translate}
                                            userData={userData}
                                            pageName={pageName}
                                            path={path}
                                            tabChange={tabChange}
                                        />
                                    </div>
                                </ClickAwayListener>
                            }
                        </Grid>
                    </Grid>
                </Grid>
                {path !== "choose-contacts" &&   
                    <Button className="plus-icon" variant="text" onClick={() => setShowSelectContact(true)} >
                        <i className="iconwasalt icon-plus" /> {translate("ra.button.add")}
                    </Button> 
                }
            </Grid>
            {filterList?.length > 0 &&
                <Grid className="filters-section selected-filter-panel aa filter-background applied-filters-new">
                    <>
                        <Typography variant="subtitle1">{translate("ra.fieldName.appliedFilter")}</Typography>
                        <Grid className="search-scroll-desktop overflow-scroll">
                            {filterList.map((list, idx) =>
                                <Grid className="capsule-list-section" key={idx} >
                                    <Typography className="filters filter-capsule" component="Grid">
                                        <Button variant="text" >
                                            {list?.filterName}: {list?.value}
                                            <i onClick={() => filterRemoveHandler(idx)}  className="iconwasalt icon-close-filled" />
                                        </Button>
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>
                    </>
                </Grid>
            }
        </Container>
    )
}

export default FilterSearch;