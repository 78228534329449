import { currencyStatus } from "./currencyCheck";
import { removeDuplicates } from "./removeDuplicates";

  // Forming document format to share in social platform
  export const documentFormation = (list, type) => {
     
    let paymentPlan = [];
    let unitPlan = [];
    let marketingAssets = [];

    const payment = list?.paymenT_PLAN_TINY || list?.paymenT_PLAN || "";
    const unit = list?.uniT_PLAN_TINY || list?.uniT_PLAN || "";
    const asset = list?.assetS_TINY || list?.assets || list?.marketinG_ASSETS_TINY || list?.marketinG_ASSETS || "";
    
    if(payment) {
      paymentPlan = docArray(list, payment, "Payment Plan");
    }

    if(unit) {
      unitPlan = docArray(list, unit, "Unit Layout");
    }

    if(asset) {
      marketingAssets = docArray(list, asset, "Marketing Assets");
    }

    if(type) {
      return {
        paymentPlan : paymentPlan,
        unitPlan : unitPlan,
        marketingAssets : marketingAssets
      }
    } else {
      return [...unitPlan, ...marketingAssets, ...paymentPlan]
    }
  };

  const docArray = (list, doc_link, doc_name) => {
    const doc = [{
      documentLink: doc_link || "",
      documentName: doc_name || "",
      propertyID: list?.project_id || list?.projecT_ID || list?.propertY_ID || "",
      propertyName: list?.title || list?.projecT_NAME || "",
      unitNo: list?.unit_no || list?.officE_SECTION_ID || ""
    }];

    return doc;
  };

  export const unitPageShareList = (filterProjectList, docList, docData) => {
    const shareDoc = [];
    filterProjectList?.map(data => {
      const docFilter = docList?.filter(value => value?.propertyID === data?.project_id);
      const unitDocFilter = docData.filter(list => list?.unitNo === data?.unit_no);

      shareDoc.push({
        projectId: data?.project_id,
        unitNo: data?.unit_no,
        price: data?.price,
        size: data?.area,
        currency: data?.currency,
        unit: data?.units,
        docArray: [...docFilter, ...unitDocFilter]
      })
    })

    const projectShareFilter = removeDuplicates(filterProjectList, "project_id")?.map(list => {
      return {
        title: list?.title,
        projectId: list?.project_id,
        location: list?.location,
        city: list?.city,
        images: list?.images[0]?.uL_ImageURL
      }
    })

    projectShareFilter.map(data => {
      const filterData = shareDoc.filter(value => value?.projectId === data?.projectId);
        if(filterData?.length > 0) {
          data.unitDoc = filterData;
        }
    })

    return projectShareFilter;
  }

  export const unitDetailsShare = (details, formData, doc) => {
    return [{
      title: details?.projecT_NAME,
      projectId: details?.projecT_ID,
      location: details?.location,
      city: details?.city,
      images: details?.images[0]?.uL_ImageURL,
      unitDoc : [{
        unitNo: details?.officE_SECTION_CODE,
        price: currencyStatus(formData?.currency)
          ? details?.[`propertY_PRICE_${formData?.currency}`]
          : details?.[`propertY_PRICE`],
        size: details?.[`saleablE_AREA_${formData?.unit_type?.toUpperCase()}`],
        currency: currencyStatus(formData?.currency)
        ? formData?.currency
        : details?.currency,
        unit: formData?.unit_type,
        docArray: doc
      }]
    }]
  }
  
  export const formatDocName = (docName) => {
    // changing all uppercase letters to first letter capital and other letter small
    let words = docName?.split("_");
    return words.map((word) => { 
      return word[0].toUpperCase() + word.substring(1)?.toLowerCase(); 
    }).join(" ");
  }