import { apiServiceRequest } from "Utils/axiosInstance";
import { roleCheck } from "Utils/constantValues";
import { getUserToken } from "Utils/tokenProvider";

export const getTeamList = async (setLoading, data, setData, setCount, page, notify) => {
    if(setLoading) setLoading(true)
    const res = await apiServiceRequest({
        url: `/get-agency-user-list?limit=10&page=${page ? page : 0}`,
        headers: { Authorization: `Bearer ${await getUserToken(notify)}` },
        notify : notify
    });

    if (res?.code === "success") {
        const resData = res?.data?.map((list, key) => {
            return {
                title : list?.team_title,
                first_name : list?.team_first_name,
                last_name : list?.team_last_name,
                designation : list?.team_designation || "Office Manager/Admin",
                role : list?.role === "root" 
                       ? "Office Manager/Admin" 
                       : list?.role === "authorized_signatory" 
                       ? "Authorized Person" 
                       : list?.role?.split("_")?.join(" "),
                role_type : list?.role,
                country_code : list?.team_country_code,
                mobile : list?.team_mobile,
                email : list?.email,
                status : ((parseInt(list?.team_is_active) === 1) || roleCheck?.includes(list?.role)) 
                         ? "Active" 
                         : "Inactive",
                is_active : list?.team_is_active,
                user_id : list?.user_id,
                team_id : list?.team_id,
                parent_id: list?.parent_id
            }
        })
        const additionalData = [...data, ...resData];
        setData(additionalData || []);
        setCount(res?.count || 0)
    }
    if(setLoading) setLoading(false)
};

export const getMemberById = async (setLoading, id, role, setData, notify) => {
    setLoading(true)
    const res = await apiServiceRequest({
        url: `/get-agency-user-details?id=${id}${role ? `&role_type=${role}` : ""}`,
        headers: { Authorization: `Bearer ${await getUserToken(notify)}` },
        notify: notify
    });

    if (res?.code === "success") {
        const resData = {
            title : res?.data?.team_title,
            first_name : res?.data?.team_first_name,
            last_name : res?.data?.team_last_name,
            designation : res?.data?.team_designation || "Office Manager/Admin",
            role : res?.data?.role?.toLowerCase(),
            role_type : res?.data?.role === "root" 
                    ? "Office Manager/Admin" 
                    : res?.data?.role === "authorized_signatory" 
                    ? "Authorized Person" 
                    : res?.data?.role?.split("_")?.join(" "),
            country_code : res?.data?.team_country_code,
            mobile : res?.data?.team_mobile,
            email : res?.data?.email,
            is_active : res?.data?.team_is_active === 0 ? "0" : "1",
            team_id : res?.data?.team_id,
            user_id : res?.data?.user_id,
            DOB: res?.data?.team_DOB,
            nationality: res?.data?.team_nationality
        }
        setData(resData)
    }
    setLoading(false)
};

export const addUpdateMember = async (setLoading, url, method, data, notify, navigate) => {
    setLoading(true);
    const msgShow = method === "put" ? "ra.notification.memberUpdate" : "ra.notification.memberCreate";

    // making copy of data to avoid error in role type while editing
    const payload = { ...data };

    // deleting unneccessary keys
    delete payload?.role_type;
    delete payload?.team_id;
    delete payload?.user_id;

    if(!payload?.is_active){
        payload.is_active = 1;
    }

    if(payload?.is_active){
        payload.is_active = parseInt(payload.is_active);
    }

    const res = await apiServiceRequest({
        url: url,
        method: method,
        data: payload,
        headers: { Authorization: `Bearer ${await getUserToken(notify)}` },
        notify: notify
    });

    if (res?.code === "success") {
        notify(msgShow, { type: "success" });
        if(navigate) {
            navigate(-1);
        }
    } else {
        if(res?.code === "failed" && res?.message) notify(res?.message?.split(".")?.[0], { type: "error" });
    }
    setLoading(false)
};

export const updateMemberStatus = async (setLoading, data, notify, message, listAPI) => {
    setLoading(true);
    const res = await apiServiceRequest({
        url: `agency-user-status/${data?.team_id}`,
        method: "put",
        data: {is_active: data?.is_active},
        headers: { Authorization: `Bearer ${await getUserToken(notify)}` },
        notify: notify
    });

    if (res?.code === "success") {
        if(listAPI) {listAPI(res?.data[0])}
        notify(message, { type: "success" });
    } else {
        if(res?.code === "failed" && res?.message) notify(res?.message?.split(".")?.[0], { type: "error" });
    }
    setLoading(false)
};
