import { apiServiceRequest } from "Utils/axiosInstance";
import { baseURL } from "Utils/baseUrl";
import { getUserToken } from "Utils/tokenProvider";

//Nationality Service
const nationality = async (
  setNationalityList,
  setNationalityLoading,
  notify
) => {
  setNationalityLoading(true);
  const res = await apiServiceRequest({
    url: `${baseURL}GetNationalities`,
    headers: { Authorization: `Bearer ${await getUserToken(notify)}` },
    notify: notify,
  });

  if (res?.successStatus) {
    setNationalityList(res?.response);
  }
  setNationalityLoading(false);
};

export default nationality;
