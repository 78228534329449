import React, { useEffect } from "react";
import { useRedirect, useTranslate } from "react-admin";
import {
  CardContent,
  Grid,
  Typography,
  Stack,
  Box,
  Container,
  Button,
} from "@mui/material";
import AdditionalDocumentSection from "Components/Common/DocumentSection/AdditionalDocumentSection";
import UploadDocumentSection from "Components/Common/DocumentSection/UploadDocumentSection";
import isRead from "Utils/ui-lock-status";
import isInProcess from "Utils/ui-inprocess-status";
import { handleLogout } from "Utils/logout";
import { brokerageStatus } from "Utils/dataCheck";
import Loader from "Components/Loader";
import userDetails from "Services/userDetailsServices";
import { additionalDocHandler } from "Utils/uploadSection";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import HeaderButton from "Components/HeaderButton";

const UploadDoc = ({
  os,
  watchIncorporationDocument,
  user_type,
  watchPassportNationalIdDocument,
  watchResidenceCivilCard,
  watchQatarIdCivilCard,
  watchBrokerageCertificateDocument,
  watchVatCertificateDocument,
  watchBrokerIdDocument,
  bcn,
  addtionalDocument,
  setDeleteId,
  additionalDocumentsFiles,
  setAdditionalDocumentsFiles,
  loading,
  submitHandler,
  city,
  omanCheck,
  qatarCheck,
  watchAdditionalDocuments,
  watchAdditionalDocumentsName,
  setLoading,
  setResponseData,
  setDetails,
  register,
  resetField,
  setValue,
  errors,
  openDialog,
  setOpenDialog,
  notification
}) => {
  const translate = useTranslate();
  const redirect = useRedirect();

  const hidden = () => {
    if (isRead(os) === true) {
      if (isInProcess(os) === true) {
        return true; //logout,
      } else {
        return false; //logout
      }
    } else {
      return true; //logout
    }
  };

  const closePopup = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    if (watchAdditionalDocuments[0]) {
      const doc_name = !Array.isArray(watchAdditionalDocumentsName)
        ? watchAdditionalDocumentsName
        : "";
      setAdditionalDocData(watchAdditionalDocuments[0], doc_name);
      setValue("additionalDocuments", []);
    }
  }, [watchAdditionalDocuments]);

  useEffect(() => {
    userDetails(setLoading, setResponseData, setDetails, notification);
  }, []);

  const setAdditionalDocData = async (file, fileName) => {
    additionalDocHandler(
      file,
      fileName,
      additionalDocumentsFiles,
      setAdditionalDocumentsFiles,
      notification
    );
    resetField("additionalDocumentName");
  };

  return (
    <>
      {loading && <Loader />}      
        <Grid className="company-container file-upload-page">
          <Container className="company-details">
          {!isInProcess(os) && (
                <HeaderButton classname={"only-back"} />
              )}
            <Grid className="form-panel upload-page" item xs={12}>              
              <Box width="100%">
                <CardContent className="ui_form">
                  <Typography variant="h3" className="mb0">
                    {translate("ra.pageTitle.uploadDocuments")}
                  </Typography>

                  {/* Incorporation */}
                  <Typography
                    variant="body2"
                    className="upload-size"
                    sx={{ m: 1 }}
                  >
                    {translate("ra.fieldName.uploadSize")}
                  </Typography>
                  {user_type === 2 && (
                    <Grid className="upload-section" container spacing={0}>
                      <UploadDocumentSection
                        watchDoc={watchIncorporationDocument}
                        fieldName="incorporationDocument"
                        register={register}
                        errorMsg={translate("ra.validation.validDoc")}
                        resetField={resetField}
                        title={translate("ra.fieldName.incorporation")}
                        os={os}
                        errors={errors?.incorporationDocument}
                        notification={notification}
                      />
                    </Grid>
                  )}

                  {/* Passport */}
                  <Grid className="upload-section" container spacing={0}>
                    <UploadDocumentSection
                      watchDoc={watchPassportNationalIdDocument}
                      fieldName="passportNationalIdDocument"
                      register={register}
                      errorMsg={translate("ra.validation.mandatoryDoc")}
                      resetField={resetField}
                      title={translate("ra.fieldName.uploadPassport")}
                      os={os}
                      errors={errors?.passportNationalIdDocument}
                      notification={notification}
                    />
                  </Grid>

                  {/* Residence Civil Card */}
                  {omanCheck && (
                    <Grid className="upload-section" container spacing={0}>
                      <UploadDocumentSection
                        watchDoc={watchResidenceCivilCard}
                        fieldName="residenceCivilCard"
                        register={register}
                        errorMsg={translate("ra.validation.mandatoryDoc")}
                        resetField={resetField}
                        title={translate("ra.fieldName.civilCard")}
                        os={os}
                        errors={errors?.residenceCivilCard}
                        notification={notification}
                      />
                    </Grid>
                  )}

                    {/* Qatar Id  Card */}
                  { user_type === 1 &&  qatarCheck && (
                    <Grid className="upload-section" container spacing={0}>
                      <UploadDocumentSection
                        watchDoc={ watchQatarIdCivilCard}
                        fieldName="qatarId"
                        register={register}
                        errorMsg={translate("ra.validation.mandatoryDoc")}
                        resetField={resetField}
                        title={translate("ra.fieldName.qatarId")}
                        os={os}
                        errors={errors?.qatarId}
                        notification={notification}
                      />
                    </Grid>
                  )}

                  {user_type === 2 && (
                    <>
                      {/* Brokerage Certificate Number */}
                      {!brokerageStatus(bcn) && (
                        <>
                          <Grid
                            className="upload-section"
                            container
                            spacing={0}
                          >
                            <UploadDocumentSection
                              watchDoc={watchBrokerageCertificateDocument}
                              fieldName="brokerageCertificateDocument"
                              register={register}
                              errorMsg={translate("ra.validation.validDoc")}
                              resetField={resetField}
                              title={translate("ra.fieldName.brokerage")}
                              os={os}
                              errors={errors?.brokerageCertificateDocument}
                              notification={notification}
                            />
                          </Grid>

                          {/* Vat certificate */}
                          <Grid
                            className="upload-section"
                            container
                            spacing={0}
                          >
                            <UploadDocumentSection
                              watchDoc={watchVatCertificateDocument}
                              fieldName="vatCertificateDocument"
                              register={register}
                              errorMsg={translate("ra.validation.validDoc")}
                              resetField={resetField}
                              title={translate("ra.fieldName.vat")}
                              os={os}
                              errors={errors?.vatCertificateDocument}
                              notification={notification}
                            />
                          </Grid>

                          {/* Broker id  */}
                          {city === "dubai" && (
                            <Grid
                              className="upload-section"
                              container
                              spacing={0}
                            >
                              <UploadDocumentSection
                                watchDoc={watchBrokerIdDocument}
                                fieldName="brokerIdDocument"
                                register={register}
                                errorMsg={translate("ra.validation.validDoc")}
                                resetField={resetField}
                                title={translate("ra.fieldName.idCard")}
                                os={os}
                                errors={errors?.brokerIdDocument}
                                notification={notification}
                              />
                            </Grid>
                          )}
                        </>
                      )}
                    </>
                  )}

                  {/* Additional Docs */}
                  <Grid className="upload-section" container spacing={0}>
                    <AdditionalDocumentSection
                      length={additionalDocumentsFiles?.length}
                      setDeleteId={setDeleteId}
                      additionalDocumentsFiles={additionalDocumentsFiles}
                      setAdditionalDocumentsFiles={setAdditionalDocumentsFiles}
                      register={register}
                      os={os}
                      loading={loading}
                      errors={errors?.additionalDocuments}
                      uploadedDocLength={addtionalDocument?.length}
                      notification={notification}
                    />
                  </Grid>

                  {/* Buttons */}
                  <Grid
                    container
                    className="company-details-button"
                    spacing={0}
                  >
                    <Button
                      variant="contained"
                      onClick={submitHandler}
                      type="submit"
                      sx={!hidden() && { display: "none" }}
                    >
                      {translate("ra.button.submit")}
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => handleLogout(notification, redirect)}
                      type="submit"
                      sx={hidden() && { display: "none" }}
                    >
                      {translate("ra.button.logout")}
                    </Button>                    
                  </Grid>
                </CardContent>
              </Box>
            </Grid>
          </Container>
        </Grid>

      {openDialog && (
        <Dialog
          open={openDialog}
          keepMounted
          onClose={closePopup}
          aria-describedby="alert-dialog-slide-description"
          className="new-popup-box"
        >
          <i
            className="iconwasalt icon-close cross-button"
            onClick={closePopup}
          ></i>
          <DialogContent>
            <i className="iconwasalt icon-question question-icon"></i>
            <DialogContentText id="alert-dialog-slide-description">
              {translate("ra.notification.attachmentExceed").split("agents")[0]}
              <a href={"mailto: agents@darglobal.co.uk"}>
                agents@darglobal.co.uk
              </a>
              {translate("ra.notification.attachmentExceed").split(".uk")[1]}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                submitHandler();
              }}
            >
              {translate("ra.button.logout")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default UploadDoc;