import React, { useState, useEffect } from "react";
import { useTranslate } from "react-admin";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  Grid,
  Container,
  Typography,
  MenuItem,
  TextField,
  Button,
  Link
} from "@mui/material";
import Header from "Components/Header";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import BackButton from "Components/BackButton";
import ProfilePic from "Components/ProfilePic";
import Loader from "Components/Loader";
import AutoCompleteComponent from "Components/Common/AutoComplete";
import MultipleAutoCompleteComponent from "Components/Common/MultipleAutoComplete";
import userDetails from "Services/userDetailsServices";
import projectList from "Services/projectListServices";
import updateUserDetails from "Services/updateUserDetailService";
import { unit_types } from "Utils/constantValues";
import { userDetailResponse } from "Utils/commonFunctions";
import { filterDetails } from "Services/projectService";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import DialogBox from "Components/DialogBox";
import gtagEventFire from "Utils/ga4EventTrigger";

const Profile = ({ notification }) => {
  const multiple = true;
  const authorised = "authorized_signatory";
  const admin = "admin";
  const salesPerson = "sales_person";
  const root = "root";
  const [formData, setFormData] = useState({});
  const [currencyList, setCurrencyList] = useState([]);
  const [projectInterestedList, setProjectInterestedList] = useState([]);
  const [currencyState, setCurrencyState] = useState(null);
  const [loading, setLoading] = useState(false);
  const [userDetailsLoading, setUserDetailsLoading] = useState(false);
  const [getLookupLoading, setGetLookupLoading] = useState();
  const [projectInterestedLoading, setProjectInterestedLoading] = useState(false);
  const [destinationValues, setDestinationValues] = useState([]);
  const [disable, setDisable] = useState(true);
  const [openingDropdown, setOpeningDropdown] = useState(false);
  const [picture, setPicture] = useState();
  const [details, setDetails] = useState();
  const [unit_type, setUnit_type] = useState();
  const [value, setValue] = useState("1");
  const [openPopup, setOpenPopup] = useState(false);

  const navigate = useNavigate();
  const translate = useTranslate();
  const {
    register,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const callUserDetails = (setUserLoading) => {
    setDisable(true); 
    userDetails(setUserLoading, setFormData, setUserDetails, notification);
  };

  //Function to set the values
  const setUserDetails = (resData) => {
    userDetailResponse(resData, setDetails);
    setUnit_type(resData?.unit_type);
    setPicture(resData?.profile);
    setDestinationValues(resData?.project_destinations_interested || []);
  };

  // Function to append the form_data
  const apiRequestData = (form_data, data) => {
    const { currency, unit_type } = data;
    currency && (formData?.currency !== currency) &&
      form_data.append("currency", currency);
    destinationValues?.length && (destinationValues !== formData?.project_destinations_interested) &&
      form_data.append("project_destinations_interested", destinationValues);
    unit_type && (formData?.unit_type !== unit_type) &&
      form_data?.append("unit_type", unit_type);
    gtagEventFire("profile_preference_change", {
      currency_change: formData?.currency !== currency ? currency : "",
      project_destination_interested: destinationValues !== formData?.project_destinations_interested ? destinationValues : "",
      unit_type_change: formData?.unit_type !== unit_type ? unit_type : "",
      user_email: formData?.email
     });
  };

  // Calling all the apis on component mount
  useEffect(() => {
    document.body.classList.add("profile-page");
    projectList(
      setProjectInterestedList,
      setProjectInterestedLoading,
      notification,
      multiple
    );
    callUserDetails(setUserDetailsLoading);
    filterDetails(setGetLookupLoading, "", notification, "", "", setCurrencyList);
    return () => {
      document.body.classList.remove("profile-page");
    };
  }, []);

  useEffect(() => {
    if (formData?.currency) {
      const currency = currencyList?.find(
        (currency) => currency?.code === formData?.currency
      );
      setCurrencyState(currency);
    } else {
      const defaultCurrency = currencyList?.find(
        (currency) => currency?.code === "DEFAULT"
      );
      setCurrencyState(defaultCurrency);
    }
  }, [formData?.currency, currencyList]);

  const projectDestinationHandler = (option) => {
    if (!formData?.project_destinations_interested?.includes(option)) {
      const destination = [...destinationValues];
      const index = destination.indexOf(option);
      if (index > -1) {
        destination.splice(index, 1);
      } else {
        destination.push(option);
      }
      setDestinationValues(destination);
      setDisable(false);
    }
  };

  const uploadPicture = (file) => {
    const form_data = new FormData();
    form_data.append("profile", file);
    ((!file && picture) || file) &&
      updateUserDetails(
        setLoading,
        form_data,
        notification,
        "",
        file ? "ra.notification.profileSuccess" : "ra.notification.profileRemove",
        callUserDetails
      );
  };

  const onSubmit = async (data) => {
    const form_data = new FormData();
    apiRequestData(form_data, data);
    updateUserDetails(
      setLoading,
      form_data,
      notification,
      "",
      "ra.notification.saveChanges",
      callUserDetails
    );
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    // scrolling to top when tab changes as per design team
    window.scrollTo(0, 0);
  };

  return (
    <>
      {(loading ||
        userDetailsLoading ||
        projectInterestedLoading ||
        getLookupLoading) && <Loader />
      }
      <Header picture={picture} pictureLoading={userDetailsLoading} notification={notification} userData={formData} email={formData?.email} />
      {!userDetailsLoading &&
        <>
          <BackButton
            close={() => {
              disable ? navigate(-1) : setOpenPopup(true);
            }}
          />
          <Container className="company-container profile-page-all mt-0 profile-height-wrapper">
            <Container className="company-details new-profile-preferance">
              <Grid
                className="form-panel profile-form-panel"
                sx={{ width: "100%" }}
              >
                <Grid container className="profile-top-panel">
                  <ProfilePic
                    picture={picture}
                    setPicture={setPicture}
                    uploadPicture={uploadPicture}
                    notification={notification}
                  />
                  <Typography className="profile-top-info" variant="div">
                    <Typography variant="h3">{details?.name || ""}</Typography>
                    <Typography variant="h4">
                      {formData?.role === admin || formData?.role === salesPerson
                        ? formData?.team_designation
                        : formData?.user_type === 2 && formData?.role === root
                        ? "Admin"
                        : formData?.designation}
                    </Typography>
                    {((formData?.role === root && formData?.user_type === 2) ||
                      formData?.role === authorised ||
                      formData?.role === admin) && (
                      <Link className="profile-link profile-button-icon" href="#/manage-darglobal">
                        {translate("ra.fieldName.manage")} {formData?.company_name}
                        <i className="iconwasalt icon-chevron-right"></i>
                      </Link>
                    )}
                  </Typography>
                </Grid>
                <Grid container className="profile-bottom-panel">
                  <Box sx={{ width: "100%", typography: "body1" }}>
                    <TabContext value={value}>
                      <Box sx={{ borderBottom: 1, borderColor: "#E9E9E9" }}>
                        <TabList
                          onChange={handleChange}
                          aria-label="lab API tabs example"
                        >
                          <Tab label={translate("ra.fieldName.PersonalDetails")} value="1" />
                          <Tab label={translate("ra.fieldName.preferences")} value="2" />
                        </TabList>
                      </Box>
                      <TabPanel className="personal-details" value="1">
                        {/* Full Name */}
                        <Grid container spacing={0}>
                          <Typography variant="subtitle1">{translate("ra.fieldName.full_name")}</Typography>
                          <Typography>{details?.name}</Typography>
                        </Grid>

                        {/* Role */}
                        <Grid container spacing={0}>
                          <Typography variant="subtitle1">{translate("ra.fieldName.role")}</Typography>
                          <Typography>{details?.role}</Typography>
                        </Grid>
                        <Typography className="contact-details-heading" variant="h4">{translate("ra.fieldName.contactDetails")}</Typography>
                        {/* Mobile */}
                        <Grid container spacing={0}>
                          <Typography variant="subtitle1">{translate("ra.fieldName.mobileNo")}</Typography>
                          <Typography>{details?.mobile}</Typography>
                        </Grid>

                        {/* Email */}
                        <Grid container spacing={0}>
                          <Typography variant="subtitle1">{translate("ra.fieldName.emailAddress")}</Typography>
                          <Typography>
                            {formData?.role === authorised
                              ? formData?.email_address
                              : formData?.email}
                          </Typography>
                        </Grid>
                        <Grid container className="button-panel change-password-link" spacing={0}>
                          <Link
                            className="link-with-arrow"
                            href="#/change-password"
                          ><span><i className="iconwasalt icon-padlock"></i>
                            {translate("ra.fieldName.changePassword")}</span>
                            <i className="iconwasalt icon-chevron-right"></i>
                          </Link>
                        </Grid>
                      </TabPanel>
                      <TabPanel className="flex-column preferances-panel" value="2">
                        {/* Currency */}
                        <Grid 
                          container 
                          className={`currency-field dropdown-box-shadow 
                            ${errors?.currency ? "profile-currency-margin" : ""}`
                          } 
                          spacing={0}
                        >
                          <AutoCompleteComponent
                            title={translate("ra.fieldName.currency")}
                            value={currencyState}
                            onChange={(e, newInputValue) => {
                              setCurrencyState(newInputValue);
                              clearErrors("currency");
                              setDisable(false);
                            }}
                            isEdit={true}
                            options={currencyList}
                            getOptionLabel={(option) => option?.code || ""}
                            name="currency"
                            placeholder={translate("ra.placeholder.currency")}
                            errors={errors?.currency}
                            register={register}
                          />
                        </Grid>

                        {/* Show sizes in */}
                        <Grid container className="show-size" spacing={0}>
                          <Typography variant="subtitle1">{translate("ra.fieldName.showSizes")}</Typography>
                          <TextField
                            select
                            value={unit_type || ""}
                            variant="outlined"
                            type="text"
                            name="unit_type"
                            {...register("unit_type")}
                            className="select-box dropdown-icon-wrapper"
                            onChange={() => setDisable(false)}
                            SelectProps={{
                              IconComponent : () => <i className="iconwasalt icon-arrow-down" />
                            }}
                          >
                            {unit_types.map((item, index) => {
                              return (
                                <MenuItem
                                  key={index}
                                  onClick={(e) => {
                                    setUnit_type(item?.value);
                                  }}
                                  value={item?.value}
                                  {...register("unit_type")}
                                >
                                  {item?.name}
                                </MenuItem>
                              );
                            })}
                          </TextField>
                        </Grid>

                        {/* Project Destination Interested */}
                        {formData?.role !== salesPerson && (
                          <Grid
                            className="project-destination dropdown-box-shadow"
                            container
                            spacing={0}
                          >
                            <MultipleAutoCompleteComponent
                              formData={formData}
                              id="checkboxes-tags-demo"
                              title={translate("ra.fieldName.projectInterest")}
                              name="project_destinations_interested"
                              options={projectInterestedList}
                              onClick={(option) => {
                                projectDestinationHandler(option);
                              }}
                              getOptionLabel={(option) => option}
                              icon={icon}
                              checkedIcon={checkedIcon}
                              hide={true}
                              register={register}
                              setOpeningDropdown={setOpeningDropdown}
                              openingDropdown={openingDropdown}
                              count={destinationValues?.length}
                              placeholder={translate("ra.placeholder.searchPdi")}
                              value={destinationValues}
                              defaultValue={
                                formData?.project_destinations_interested
                              }
                            />
                          </Grid>
                        )}

                        {/* Button */}
                        <Grid
                          container
                          className="button-panel align-right mobile-fixed-button"
                          spacing={0}
                        >
                          <Button
                            variant="contained"
                            className="new-color-button"
                            onClick={handleSubmit(onSubmit)}
                            disabled={disable}
                          >
                            {translate("ra.button.saveChange")}
                          </Button>
                        </Grid>
                      </TabPanel>
                    </TabContext>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Container>
        </>
      }
      {!disable && (
        <DialogBox
          openPopup={openPopup}
          path={"profile"}
          setOpenPopup={setOpenPopup}
          content={translate("ra.notification.changesLost")}
          handleYes={() => navigate(-1)}
        />
      )}
    </>
  );
};

export default Profile;
